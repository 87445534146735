import React, { useMemo, useRef } from "react";
import { FileType } from "domains/file-manager/interfaces";
import Button from "domains/ui/components/Button";

import {
  Card,
  chakra,
  Checkbox,
  Divider,
  Flex,
  Slide,
  Text,
  useToken,
} from "@chakra-ui/react";

import { FileManagerProps } from "../FileManager";

export interface SelectionBarProps {
  files: FileType[];
  selectedFiles: FileType[];
  onSelectAll: () => void;
  onDeselectAll: () => void;

  fileHandlers: FileManagerProps["fileHandlers"];
}

/**
 * Display action on selected files at the bottom of the file manager
 *
 * @link <url to figma if exist>
 * @link <url to storybook if exist>
 */
const SelectionBar = ({
  files,
  selectedFiles = [],

  onSelectAll,
  onDeselectAll,

  fileHandlers,
}: SelectionBarProps) => {
  // TODO this makes me want to throw up but it's the only way to make it work
  // We need to update the props components and not just rerender a new ones
  // Otherwise menu lists close every time the useMemo is triggered
  const renderedActionRef = useRef<JSX.Element[]>([]);
  useMemo(() => {
    const updatedActionComponents: JSX.Element[] = [];
    Object.values(fileHandlers).forEach((fh) =>
      fh?.actions?.forEach((action) => {
        if (action.Component && action.kind.includes("selectionBar")) {
          let actionToAdd;

          if (action.label === "Collections" || action.label === "Tags") {
            const existingAction = renderedActionRef.current.find(
              (actionComp) => actionComp.key === action.kind + action.label
            );
            if (existingAction) {
              actionToAdd = React.cloneElement(existingAction, {
                onAction: () => action.onAction?.(selectedFiles as any),
                files: selectedFiles,
              });
            }
          }

          if (!actionToAdd) {
            actionToAdd = (
              <action.Component
                key={action.kind + action.label}
                onAction={() => action.onAction?.(selectedFiles as any)}
                files={selectedFiles as any}
              />
            );
          }

          updatedActionComponents.push(actionToAdd);
        }
      })
    );
    renderedActionRef.current = updatedActionComponents;
  }, [fileHandlers, selectedFiles]);

  const isCollapsed = useMemo(() => {
    return selectedFiles.length === 0;
  }, [selectedFiles]);

  const selectionBarZIndex = useToken("zIndices", "popover");

  return (
    <Slide
      direction="bottom"
      in={!isCollapsed}
      style={{ zIndex: selectionBarZIndex }}
    >
      <Card
        // TODO: remove the Slide and use those props instead so the selection bar is only inside the FileManager instead of globally, but it's not working yet due to layout overflow issues.
        // overflow={"hidden"}
        // pos={"sticky"}
        // bottom={isCollapsed ? "-100" : 0}
        // left={0}
        // Allow the selection bar to overflow on the left and right to fill the parent container
        // w={`calc(100% + ${sidesOverflowsPx * 2}px)`}
        // mx={`-${sidesOverflowsPx}px`}
        // px={"35px"}
        py={5}
        // visibility={isCollapsed ? "hidden" : "visible"}
        // transition={extraTheme.transitions.fast}
        borderBottomColor={"transparent"}
        borderX={0}
        borderRadius={0}
        data-outside-click-excluded={true}
        data-testid="file-manager-selection-bar"
      >
        <Flex
          align={"center"}
          justify={"space-between"}
          w="100%"
          h="100%"
          px={6}
        >
          <Flex align={"center"} wrap="nowrap" gap={3}>
            <Checkbox
              colorScheme="primary"
              isChecked={true}
              size="xl"
              variant="lightCircular"
            />
            <Text
              textColor={"white.500"}
              data-testid="file-manager-number-images-selected"
              size={"body.bold.lg"}
            >
              <chakra.span color="textPrimary">
                {`${selectedFiles.length} selected `}
              </chakra.span>
            </Text>
            <Divider h={6} orientation="vertical" />
            {selectedFiles.length < files.length && (
              <Button
                variant={"secondary"}
                onClick={onSelectAll}
                data-testid="file-manager-select-all-button"
              >
                Select All
              </Button>
            )}

            {!!selectedFiles.length && (
              <Button
                variant="secondary"
                onClick={onDeselectAll}
                isDisabled={selectedFiles.length === 0}
                data-testid="file-manager-clear-button"
                mr={5}
              >
                Clear
              </Button>
            )}
          </Flex>

          <Flex align={"center"} justify={"center"} gap={2}>
            {renderedActionRef.current}
          </Flex>
        </Flex>
      </Card>
    </Slide>
  );
};

export default SelectionBar;
