import { useCallback, useState } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { ColorPalette } from "domains/ui/components/ColorPicker/defaultColorPalettes";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  PutImagesPixelateApiResponse,
  useLazyGetAssetsByAssetIdQuery,
  usePutImagesPixelateMutation,
} from "infra/api/generated/api";

export const PIXEL_GRID_SIZES = [16, 32, 64, 128, 256];
export type PixelGridSize = (typeof PIXEL_GRID_SIZES)[number];

export interface PixelateSettings {
  removeNoise: boolean;
  setRemoveNoise: (removeNoise: boolean) => void;
  removeBackground: boolean;
  setRemoveBackground: (removeBackground: boolean) => void;
  pixelGridSize: PixelGridSize;
  setPixelGridSize: (pixelGridSize: PixelGridSize) => void;
  shouldUseNewColorPalette: boolean;
  setShouldUseNewColorPalette: (shouldUseNewColorPalette: boolean) => void;
  newColorPalette: ColorPalette;
  setNewColorPalette: (newColorPalette: ColorPalette) => void;
  selectedColorPalette: ColorPalette | undefined;
  setSelectedColorPalette: (
    selectedColorPalette: ColorPalette | undefined
  ) => void;
  colorPaletteSize: number | undefined;
  setColorPaletteSize: (colorPaletteSize: number | undefined) => void;
}

export default function useAssetPixelate(): {
  pixelateSettings: PixelateSettings;
  handlePixelate: (props: {
    image: string;
    assetId: string | undefined;
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<PutImagesPixelateApiResponse["asset"] | undefined>;
  isPixelateLoading: boolean;
} {
  const [isPixelateLoading, setIsPixelateLoading] = useState<boolean>(false);
  const { selectedTeam } = useTeamContext();
  const [getAssetByAssetId] = useLazyGetAssetsByAssetIdQuery();
  const [triggerImagePixelate] = usePutImagesPixelateMutation();
  const { infoToast, successToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();

  const [removeNoise, setRemoveNoise] = useState<boolean>(false);
  const [removeBackground, setRemoveBackground] = useState<boolean>(false);
  const [pixelGridSize, setPixelGridSize] = useState<PixelGridSize>(64);
  const [shouldUseNewColorPalette, setShouldUseNewColorPalette] =
    useState<boolean>(false);
  const [newColorPalette, setNewColorPalette] = useState<ColorPalette>({
    id: Math.random().toString(),
    name: "Custom palette",
    colors: [],
  });
  const [selectedColorPalette, setSelectedColorPalette] = useState<
    ColorPalette | undefined
  >();
  const [colorPaletteSize, setColorPaletteSize] = useState<
    number | undefined
  >();

  const getColorPalette = useCallback(() => {
    if (shouldUseNewColorPalette) {
      return newColorPalette.colors.map((color) => {
        return [
          parseInt(color.slice(1, 3), 16),
          parseInt(color.slice(3, 5), 16),
          parseInt(color.slice(5, 7), 16),
        ];
      });
    }
    if (selectedColorPalette) {
      return selectedColorPalette.colors.map((color) => {
        return [
          parseInt(color.slice(1, 3), 16),
          parseInt(color.slice(3, 5), 16),
          parseInt(color.slice(5, 7), 16),
        ];
      });
    }
    return undefined;
  }, [shouldUseNewColorPalette, newColorPalette, selectedColorPalette]);

  const handlePixelate = useCallback(
    async ({
      image,
      assetId,
      trackingExtraParams,
    }: {
      image: string;
      assetId: string | undefined;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      setIsPixelateLoading(true);
      infoToast({
        title: "Pixelating image...",
      });

      try {
        const colorPalette = getColorPalette();

        let pixelatedAsset: PutImagesPixelateApiResponse | undefined =
          await triggerImagePixelate({
            teamId: selectedTeam.id,
            body: {
              image: assetId ? undefined : image,
              assetId: assetId,
              pixelGridSize,
              removeNoise,
              removeBackground,
              colorPalette,
              colorPaletteSize: !colorPalette ? colorPaletteSize : undefined,
              returnImage: false,
            },
          }).unwrap();

        while (pixelatedAsset && pixelatedAsset.asset.status === "pending") {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          try {
            pixelatedAsset = await getAssetByAssetId({
              teamId: selectedTeam.id,
              assetId: pixelatedAsset.asset.id,
            }).unwrap();
          } catch (_) {
            pixelatedAsset = undefined;
          }
        }

        if (!pixelatedAsset || pixelatedAsset.asset.status !== "success") {
          errorToast({
            title: "Error pixelating image",
          });
          return;
        }

        Track(AnalyticsEvents.ImageLibrary.PixelatedImage, {
          image: assetId ? undefined : image,
          assetId,
          pixelGridSize,
          removeNoise,
          removeBackground,
          colorPalette,
          ...trackingExtraParams,
        });

        successToast({
          title: "The image has been pixelated",
        });

        return pixelatedAsset.asset;
      } catch (error: unknown) {
        handleApiError(error, "Error pixelating image", {
          quota: () => {
            showLimitModal("planPixelates");
          },
        });
      } finally {
        setIsPixelateLoading(false);
      }
    },
    [
      errorToast,
      getAssetByAssetId,
      getColorPalette,
      handleApiError,
      infoToast,
      pixelGridSize,
      removeBackground,
      removeNoise,
      selectedTeam.id,
      showLimitModal,
      successToast,
      triggerImagePixelate,
      colorPaletteSize,
    ]
  );

  return {
    pixelateSettings: {
      removeNoise,
      setRemoveNoise,
      removeBackground,
      setRemoveBackground,
      pixelGridSize,
      setPixelGridSize,
      shouldUseNewColorPalette,
      setShouldUseNewColorPalette,
      newColorPalette,
      setNewColorPalette,
      selectedColorPalette,
      setSelectedColorPalette,
      colorPaletteSize,
      setColorPaletteSize,
    },
    handlePixelate,
    isPixelateLoading,
  };
}
