import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { extractFirstQueryParam } from "domains/commons/misc";
import SearchBar, {
  SearchQueryData,
} from "domains/search/components/SearchBar";

import { Flex } from "@chakra-ui/react";

export default function TopBarSearch() {
  const router = useRouter();
  const [query, setQuery] = useState("");
  const [assetId, setAssetId] = useState<string | undefined>();

  const onSearch = useCallback(
    (searchQueryData: SearchQueryData) => {
      if (!searchQueryData.query && !searchQueryData.assetId) {
        void router.push({
          pathname: "/",
          query: {
            tab: router.query.tab,
          },
        });
        return;
      }
      void router.push({
        pathname: "/search",
        query: {
          ...searchQueryData,
          tab: router.query.tab,
        },
      });
    },
    [router]
  );

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const urlQuery = extractFirstQueryParam<string>(router.query.query) ?? "";
    const urlAssetId =
      extractFirstQueryParam<string>(router.query.assetId) ?? "";
    if (urlQuery) {
      setQuery(urlQuery);
    }
    if (urlAssetId) {
      setAssetId(urlAssetId);
    }
  }, [router.isReady, router.query.assetId, router.query.query]);

  return (
    <Flex align="center" w={"100%"} maxW={"600px"}>
      <SearchBar
        query={query}
        setQuery={setQuery}
        assetId={assetId}
        setAssetId={setAssetId}
        onSearch={onSearch}
      />
    </Flex>
  );
}
