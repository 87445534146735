import { useState } from "react";
import {
  filterAssetTypeKeys,
  filterAssetTypes,
  FilterAssetTypeValue,
} from "domains/file-manager/constants/AssetFilter";

interface UseAssetFilterTypeArgs {
  initialType?: FilterAssetTypeValue;
}

export default function useAssetFilterType({
  initialType,
}: UseAssetFilterTypeArgs) {
  const [type, setType] = useState<FilterAssetTypeValue | undefined>(
    initialType
  );
  return {
    allAssetsTypeArgs: {
      type: type ? filterAssetTypes[type].value : undefined,
    },
    assetFileManagerTypeOptions: {
      filterAssetType: type,
      filterAssetTypeOptions: filterAssetTypeKeys,
      onFilterAssetTypeChange: setType,
    },
  };
}
