import {
  GridViewKey,
  gridViewKeys,
  gridViews,
} from "domains/file-manager/constants/GridView";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { useHover } from "domains/ui/hooks/useHover";

import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";

export interface GridViewDropdownProps {
  value: GridViewKey;
  onChange: (value: GridViewKey) => void;
}

const GridViewDropdown = ({ value, onChange }: GridViewDropdownProps) => {
  const [hoverRef, isHover] = useHover<HTMLButtonElement>();

  return (
    <Menu>
      <Tooltip
        isOpen={isHover}
        label="Change the display alignment of the images."
        placement="auto"
      >
        <MenuButton
          ref={hoverRef}
          p={2}
          borderRadius={"md"}
          _hover={{ bgColor: "whiteAlpha.300 " }}
          data-testid="asset-gallery-change-view-button"
        >
          <Icon id={gridViews[value].icon.active} />
        </MenuButton>
      </Tooltip>

      <MenuList>
        <Text w="100%" mt={3} mb={1} ml={2} color="textTertiary" size="body.sm">
          Images
        </Text>
        {gridViewKeys.map((key) => {
          const isSelected = value === key;
          const dynamicTestId =
            "asset-gallery-change-view-" +
            gridViews[key].label.toLowerCase().replace(/ /g, "-");
          return (
            <MenuItem key={key}>
              <Button
                w="150px"
                variant="menuItem"
                bg={isSelected ? "whiteAlpha.300" : "transparent"}
                color={isSelected ? "textPrimary" : "textSecondary"}
                onClick={() => onChange(key)}
                data-testid={dynamicTestId}
                leftIcon={
                  <Icon
                    h="16px"
                    id={
                      isSelected
                        ? gridViews[key].icon.active
                        : gridViews[key].icon.default
                    }
                  />
                }
              >
                <Box flex="1" textAlign="left">
                  {gridViews[key].label}
                </Box>
                {isSelected && <Icon id="Ui/Check" />}
              </Button>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default GridViewDropdown;
