import English from "domains/messages/compiled-locales/en/messages.json";
import { IntlProvider as IntlProviderFromLib } from "react-intl";

export const IntlProvider = ({ children }: { children: React.ReactNode }) => {
  // TODO: when enabling multiple languages, uncomment this
  //   const { locale } = useRouter();
  //   const [shortLocale] = locale ? locale.split("-") : ["en"];
  //   const messages = useMemo(() => {
  //     switch (shortLocale) {
  //       case "fr":
  //         return French;
  //       case "en":
  //         return English;
  //       default:
  //         return English;
  //     }
  //   }, [shortLocale]);

  const shortLocale = "en";
  const messages = English;
  return (
    <IntlProviderFromLib
      timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
      locale={shortLocale}
      messages={messages}
      onError={() => null}
      //   defaultRichTextElements={
      //     // {
      //       // how to have rich text in the translations
      //       // strong: (chunks) => <strong>{chunks}</strong>,
      //     // }
      //   }
    >
      {children}
    </IntlProviderFromLib>
  );
};
