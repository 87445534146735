import React, { useMemo, useRef, useState } from "react";
import CreateCollectionModal from "domains/collections/components/CreateCollectionModal";
import Search from "domains/file-manager/components/Search";
import Button from "domains/ui/components/Button";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";
import { GetCollectionsApiResponse } from "infra/api/generated/api";

import { PlacementWithLogical } from "@chakra-ui/popper/dist/popper.placement";
import {
  Center,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

export interface CollectionMenuListProps {
  collections: GetCollectionsApiResponse["collections"];
  isLoadingCollections: boolean;
  toggleFromCollection: (collectionId: string) => void;
  isInCollection: (collectionId: string) => boolean;
  placement: PlacementWithLogical;
  isDisabled?: boolean;
}

const CollectionMenuList = ({
  collections,
  isLoadingCollections,
  toggleFromCollection,
  isInCollection,
  placement,
  isDisabled,
}: CollectionMenuListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");

  const filteredCollections = useMemo(() => {
    return collections.filter((collection) =>
      collection.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [collections, search]);

  return (
    <Menu
      onClose={() => {
        setIsModalOpen(false);
      }}
      placement={placement}
    >
      <MenuButton
        as={Button}
        isDisabled={isDisabled}
        leftIcon={<Icon id={"Ui/Plus"} color={"textPrimary"} w={"10px"} />}
        size="sm"
        variant="primary"
      >
        Collections
      </MenuButton>
      <MenuList ref={containerRef} w="320px" p={0}>
        <VStack align={"start"} p={4} spacing={4}>
          <Text size={"body.bold.lg"}>Add to collections</Text>
          <Search onSearch={setSearch} />
        </VStack>
        <VStack
          align={"start"}
          overflow={"auto"}
          w={"100%"}
          maxH={"300px"}
          pb={2}
          px={2}
          spacing={0.5}
        >
          {isLoadingCollections ? (
            <Center w={"100%"}>
              <Spinner />
            </Center>
          ) : (
            filteredCollections.map((collection) => (
              <HStack
                key={`collection-${collection.id}`}
                align={"center"}
                w={"100%"}
                p={2}
                borderRadius={"lg"}
                _hover={{ backgroundColor: "whiteAlpha.300" }}
                cursor={"pointer"}
                onClick={() => {
                  toggleFromCollection(collection.id);
                }}
                spacing={3}
              >
                <Checkbox
                  pointerEvents="none"
                  colorScheme="primary"
                  isChecked={isInCollection(collection.id)}
                />
                <Text
                  color={"textSecondary"}
                  isTruncated={true}
                  size={"body.md"}
                >
                  {collection.name}
                </Text>
              </HStack>
            ))
          )}
        </VStack>
        <Divider />
        <HStack
          align={"center"}
          justify={"start"}
          m={2}
          p={1}
          borderRadius={"xl"}
          _hover={{ backgroundColor: "whiteAlpha.300" }}
          cursor={"pointer"}
          onClick={() => {
            setIsModalOpen(true);
          }}
          spacing={3}
        >
          <Button
            p={0}
            borderRadius="lg"
            leftIcon={<Icon id="Ui/PlusSm" height="16px" />}
            size="md"
            variant="secondary"
          />
          <Text size={"body.bold.md"}>Create collection</Text>
        </HStack>
        <CreateCollectionModal
          containerRef={containerRef}
          setIsOpen={setIsModalOpen}
          isOpen={isModalOpen}
        />
      </MenuList>
    </Menu>
  );
};

export default CollectionMenuList;
