export function getFileSizeFromBase64(image: string) {
  const base64WithoutHeader = image.replace(/^data:[^;]+;base64,/, "");
  const binaryData = atob(base64WithoutHeader);
  return binaryData.length;
}

export function getBase64Size(image: string) {
  const buffer = Buffer.from(image.substring(image.indexOf(",") + 1));
  return buffer.length;
}
