import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

export const Modal = defineMultiStyleConfig({
  baseStyle: {
    dialog: {
      maxW: "600px",
    },
    dialogContainer: {
      zIndex: "skipLink",
    },
  },

  variants: {
    inputs: {
      dialog: {
        bg: "secondary.900",
      },
      footer: {
        p: 2,
        gap: 3,
      },
    },
    modern: definePartsStyle({
      dialogContainer: {
        alignItems: "center",
        bgColor: "blackAlpha.600",
        overflow: "hidden",
      },
      dialog: {
        alignItems: "center",
        bg: "secondary.900",
        borderWidth: "1px",
        borderColor: "border.500",
        borderRadius: "20",
        padding: "12",
        w: "80vw",
        maxH: "90vh",
        overflow: "auto",
      },
      header: {
        mb: 3,
        fontSize: 28,
        fontWeight: 600,
        p: 0,
        color: "textPrimary",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        flexDir: "column",
      },
      body: {
        p: 0,
        fontSize: 16,
        fontWeight: 400,
        "& > *": {
          color: "textSecondary",
        },
        textAlign: "center",
      },
      footer: {
        mt: 12,
        flexDir: "column",
        p: 0,
        w: "100%",
        gap: 3,
        button: {
          w: "100%",
        },
      },
    }),
  },

  sizes: {
    "5xl": definePartsStyle({
      dialog: defineStyle({
        maxW: "800px",
      }),
    }),
  },
});
