import type { ComponentStyleConfig } from "@chakra-ui/react";

export const Heading: ComponentStyleConfig = {
  sizes: {
    lg: {
      fontSize: "28px",
    },
    md: {
      fontSize: "22px",
    },
    sm: {
      fontSize: "18px", // TODO: Verify that nothing is broke or ugly
    },
    xs: {
      fontSize: "16px", // TODO: Verify that nothing is broke or ugly
    },
  },
};
