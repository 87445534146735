import { assetsTags } from "domains/assets/api/endpoints";
import { generatorsTags } from "domains/generators/api/endpoints";
import { searchTags } from "domains/search/api/endpoints";
import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export const updateQueryDataByUpdatingTags = (
  teamId: string,
  type: "asset" | "generator",
  id: string,
  tagsToAdd: string[],
  tagsToDelete: string[],
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  const undoList: (() => void)[] = [];
  const undo = () => {
    undoList.forEach((undo) => undo());
  };

  const endpointName =
    type === "asset" ? "getAssetsByAssetId" : "getModelsByModelId";
  const args =
    type === "asset" ? { teamId, assetId: id } : { teamId, modelId: id };
  let update = dispatch(
    apiSlice.util.updateQueryData(endpointName, args, (draft) => {
      if (!draft) {
        return;
      }

      if (type === "asset" && "asset" in draft) {
        draft.asset.tags = getNewTags(
          draft.asset.tags,
          tagsToAdd,
          tagsToDelete
        );
      } else if (type === "generator" && "model" in draft) {
        draft.model.tags = getNewTags(
          draft.model.tags,
          tagsToAdd,
          tagsToDelete
        );
      }
    })
  );
  undoList.push(update.undo);

  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    searchTags.search,
    ...(type === "asset" ? [assetsTags.asset] : [generatorsTags.generator]),
  ]) as SelectedInvalidatedByTag[]) {
    if (endpointName === "getAssets") {
      update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (!draft) {
            return;
          }

          const index = draft.assets.findIndex((item) => item.id === id);
          if (index === -1) {
            return;
          }

          draft.assets[index].tags = getNewTags(
            draft.assets[index].tags,
            tagsToAdd,
            tagsToDelete
          );
        })
      );
      undoList.push(update.undo);
    }

    if (endpointName === "getModels") {
      update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (!draft) {
            return;
          }

          const index = draft.models.findIndex((item) => item.id === id);
          if (index === -1) {
            return;
          }

          draft.models[index].tags = getNewTags(
            draft.models[index].tags,
            tagsToAdd,
            tagsToDelete
          );
        })
      );
      undoList.push(update.undo);
    }

    if (endpointName === "postSearch") {
      update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (!draft) {
            return;
          }

          const index = draft.results.findIndex((item) => item.id === id);
          if (index === -1) {
            return;
          }

          draft.results[index].tags = getNewTags(
            draft.results[index].tags,
            tagsToAdd,
            tagsToDelete
          );
        })
      );
      undoList.push(update.undo);
    }
  }

  return { undo };
};

const getNewTags = (
  currentTags: string[],
  toAdd: string[],
  toDelete: string[]
) => {
  const newTags = currentTags.filter((tag) => !toDelete.includes(tag));
  newTags.push(...toAdd);
  newTags.sort();
  return newTags;
};
