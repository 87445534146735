import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import { mapAssetsToImagesFiles } from "domains/file-manager/interfaces";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useUser } from "domains/user/hooks/useUser";
import {
  GetAssetsApiArg,
  GetAssetsByAssetIdApiResponse,
  useGetAssetsQuery,
} from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export type UseAllAssetsArgs = {
  modelId?: string;
  collectionId?: string;
  // TODO update this to api type when it will be changed to a literal
  type?:
    | "background-removal"
    | "inference"
    | "pixelization"
    | "uploaded"
    | "upscale"
    | "detection";
  inferenceId?: string;
  parent?: GetAssetsByAssetIdApiResponse["asset"];
  sortBy?: GetAssetsApiArg["sortBy"];
  sortDirection?: GetAssetsApiArg["sortDirection"];
};

export default function useAllAssets({
  modelId,
  collectionId,
  type,
  inferenceId,
  parent,
  sortBy,
  sortDirection,
}: UseAllAssetsArgs) {
  const canIncludeType = !modelId && !collectionId && !inferenceId && !parent;
  const { selectedTeam } = useTeamContext();
  const [paginationToken, setPaginationToken] = useState<string | undefined>();
  const { nsfwFilteredTypes } = useUser();

  const debouncedRequestArgs = useDebounce<GetAssetsApiArg | typeof skipToken>(
    {
      teamId: selectedTeam.id,
      paginationToken,
      pageSize: "100",
      modelId,
      collectionId,
      inferenceId,
      type: canIncludeType ? type : undefined,
      rootAssetId: parent?.metadata.rootParentId ?? parent?.id,
      sortBy,
      sortDirection,
    },
    100
  );

  useEffect(() => {
    setPaginationToken(undefined);
  }, [collectionId, modelId, inferenceId, parent, type, sortBy, sortDirection]);

  const { data, isLoading, isFetching } =
    useGetAssetsQuery(debouncedRequestArgs);

  const loadMore = useCallback(() => {
    if (data?.nextPaginationToken) {
      setPaginationToken(data?.nextPaginationToken);
    }
  }, [data?.nextPaginationToken]);

  const files = useMemo(() => {
    if (isFetching && paginationToken === undefined) {
      return [];
    }
    let assets = data?.assets ?? [];
    if (type && !canIncludeType) {
      assets = assets.filter((asset) => {
        if (type === "inference") {
          return asset.metadata.type.startsWith("inference");
        }
        return asset.metadata.type === type;
      });
    }
    if (parent) {
      const children = assets.filter(
        (item) => item.metadata.parentId === parent.id
      );
      const childrenThatAreDetection = children.filter(
        (item) => item.metadata.type === "detection"
      );
      // add children of detection assets
      childrenThatAreDetection.forEach((item) => {
        const detectionChildren = assets.filter(
          (asset) =>
            asset.metadata.parentId === item.id &&
            asset.metadata.type === "inference-controlnet"
        );
        children.push(...detectionChildren);
      });
      assets = children;
    }

    return mapAssetsToImagesFiles(
      assets.filter((item) => item.metadata.type !== "canvas"),
      nsfwFilteredTypes
    );
  }, [
    data?.assets,
    type,
    nsfwFilteredTypes,
    canIncludeType,
    parent,
    isFetching,
    paginationToken,
  ]);

  return {
    files,
    loadMore,
    hasMore: !!data?.nextPaginationToken,
    isLoading: isLoading || (isFetching && paginationToken === undefined),
  };
}
