import React, { createContext, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";

interface ChatProviderContext {
  setIsChatVisible: (isChatVisible: boolean) => void;
  isChatVisible: boolean;
}

export const ChatContext = createContext<ChatProviderContext>({
  setIsChatVisible: () => {},
  isChatVisible: false,
});

export function ChatProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const router = useRouter();
  const [isChatVisible, setIsChatVisible] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  const isOnLogin = router.pathname === "/login";

  const handleChatClose = () => {
    setIsChatVisible(false);
  };

  useEffect(() => {
    if (!isReady) return;

    if (isChatVisible) {
      if (!isOnLogin) window.HubSpotConversations.widget.open();
      const hider = document.querySelector(".hubspot-chat-hider");
      const head = document.head || document.getElementsByTagName("head")[0];
      if (hider) head.removeChild(hider);
    } else {
      const css =
        "#hubspot-messages-iframe-container { visibility: hidden !important; }";
      const head = document.head || document.getElementsByTagName("head")[0];
      const style = document.createElement("style");
      style.classList.add("hubspot-chat-hider");
      head.appendChild(style);
      style.type = "text/css";
      style.appendChild(document.createTextNode(css));
      window.HubSpotConversations.widget.close();
    }
  }, [isReady, isChatVisible, isOnLogin]);

  useEffect(() => {
    if (window.HubSpotConversations) {
      setIsReady(true);
    } else {
      window.hsConversationsOnReady = [() => setIsReady(true)];
    }
  }, []);

  useEffect(() => {
    if (isOnLogin) setIsChatVisible(true);
    else setIsChatVisible(false);
  }, [isOnLogin]);

  useEffect(() => {
    if (isOnLogin || !isChatVisible) return;

    const htmlElement = document.querySelector("html");
    if (!htmlElement) return;

    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach(function (mutation) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class" &&
          !htmlElement.classList.contains("hs-messages-widget-open")
        ) {
          handleChatClose();
        }
      });
    });
    observer.observe(htmlElement, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, [isOnLogin, isChatVisible]);

  return (
    <ChatContext.Provider
      value={{
        setIsChatVisible,
        isChatVisible,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export function useChatContext() {
  return useContext<ChatProviderContext>(ChatContext);
}
