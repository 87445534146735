import { globalSelectedTeam } from "domains/teams/contexts/TeamProvider";

const needTeamId = (url: string, options: RequestInit | undefined) => {
  if (url.includes("https://cdn.")) {
    return false;
  }

  return (
    (url.includes("/images/remove-background") && options?.method === "PUT") ||
    url.includes("/inferences") ||
    url.includes("/models") ||
    (url.includes("/models") && options?.method === "POST") ||
    (url.includes("/models/") && options?.method === "DELETE") ||
    (url.includes("/models/") && url.includes("/categories")) ||
    (url.includes("/models/") && url.includes("/classes")) ||
    (url.includes("/models/") &&
      url.includes("/copy") &&
      options?.method === "POST") ||
    (url.includes("/models/") &&
      url.includes("/images") &&
      options?.method === "DELETE") ||
    (url.includes("/models/") &&
      url.includes("/inferences") &&
      options?.method === "POST") ||
    (url.includes("/models/") &&
      url.includes("/inferences") &&
      options?.method === "DELETE") ||
    (url.includes("/models/") &&
      url.includes("/inferences/all") &&
      options?.method === "DELETE") ||
    (url.includes("/models/") &&
      url.includes("/inferences/") &&
      options?.method === "DELETE") ||
    (url.includes("/models/") &&
      url.includes("/inferences/") &&
      url.includes("/images/") &&
      options?.method === "DELETE") ||
    (url.includes("/models/") &&
      url.includes("/train") &&
      options?.method === "PUT") ||
    (url.includes("/models/") &&
      url.includes("/training-images") &&
      options?.method === "POST") ||
    (url.includes("/models/") &&
      url.includes("/training-images/") &&
      options?.method === "DELETE")
  );
};
// Add team id if url need it.
export const enhanceRequestWithTeamId = (
  url: string,
  options: RequestInit | undefined
) => {
  if (needTeamId(url, options) && globalSelectedTeam) {
    const paramsFromUrl = new URLSearchParams(url.split("?")[1]);
    paramsFromUrl.set("teamId", globalSelectedTeam.id);
    url = url.split("?")[0] + "?" + paramsFromUrl.toString();
  }

  return { url, options };
};
