import { Dispatch, SetStateAction, useCallback } from "react";
import Button from "domains/ui/components/Button";
import PhoneInput from "domains/ui/components/PhoneInput";
import { OnboardingForm } from "domains/user/interfaces/OnboardingForm";
import _ from "lodash";

import {
  Box,
  Checkbox,
  Collapse,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";

type SectionContactProps = {
  form: OnboardingForm;
  setForm: Dispatch<SetStateAction<OnboardingForm>>;
};

const CONTACT_IDS = ["email", "phone"] as const;

export type ContactId = (typeof CONTACT_IDS)[number];

type Contact = {
  label: string;
};

const CONTACT_HASH: { [key in ContactId]: Contact } = {
  email: {
    label: "Email",
  },
  phone: {
    label: "Phone",
  },
};

const CONTACTS = CONTACT_IDS.map((id) => ({ ...CONTACT_HASH[id], id }));

export default function SectionContact({ form, setForm }: SectionContactProps) {
  const handleOptionClick = useCallback(
    (contactId: ContactId) => {
      if (form.contact.includes(contactId)) {
        setForm({ ...form, contact: _.without(form.contact, contactId) });
      } else {
        setForm({ ...form, contact: [...form.contact, contactId] });
      }
    },
    [setForm, form]
  );

  return (
    <VStack align="stretch" w="100%" spacing={4}>
      <Heading pb={1} color="textPrimary" size="sm">
        {"How would you like us to reach out?"}
      </Heading>

      <VStack align="stretch" spacing={1}>
        {CONTACTS.map((contact) => {
          const isChecked = form.contact.includes(contact.id);
          return (
            <Button
              key={contact.id}
              as="div"
              cursor="pointer"
              variant="inputSelect"
              size="lg"
              fontSize={["14px", "16px"]}
              data-testid={contact.id}
              leftIcon={<Checkbox as="div" isChecked={isChecked} />}
              onClick={() => handleOptionClick(contact.id)}
              colorScheme={isChecked ? "secondary" : undefined}
            >
              <Box as="span" overflow="hidden" textOverflow="ellipsis">
                {contact.label}
              </Box>
            </Button>
          );
        })}
      </VStack>

      <Collapse in={form.contact.includes("phone")}>
        <VStack align="start" pb="1px" spacing={2}>
          <Text size="body.md">Phone Number</Text>
          <PhoneInput
            onChange={(phoneNumber, countryCode) =>
              setForm((form) => ({ ...form, phoneNumber, countryCode }))
            }
            inputProps={{
              color: "textPrimary",
              bgColor: "background.500",
            }}
            menuProps={{
              color: "textPrimary",
              bgColor: "background.500",
            }}
          />
        </VStack>
      </Collapse>
    </VStack>
  );
}
