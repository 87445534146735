import { createContext, useContext } from "react";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";

interface SidebarProviderProps {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const SidebarContext = createContext<SidebarProviderProps>({
  isOpen: false,
  open: () => {},
  close: () => {},
});

export function SidebarProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = usePersistedState<boolean>(
    PersistedStateKey.SIDEBAR_OPEN,
    { defaultValue: false }
  );

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export function useSidebarContext() {
  return useContext<SidebarProviderProps>(SidebarContext);
}
