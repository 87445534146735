export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Move an item in an array to a new position
 * before: [1, 2, 3, 4, 5]
 * move(0, 2) -> after: [2, 3, 1, 4, 5]
 * */
export function move<T>(array: T[], moveIndex: number, toIndex: number) {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, toIndex),
      item,
      ...array.slice(toIndex, length),
    ];
  }
  return array;
}

// Convert a number representing cents (100 => 1,00) into a readable string with currency ([150, USD] => $1,50)
export function priceToString(amount: number, currency: "USD" = "USD") {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
    .format(amount / 100)
    .replace(/\.00$/, "");
}

export function extractFirstQueryParam<ReturnType = string>(
  queryParam: string[] | string | undefined,
  options?: {
    validValues: string[];
  }
): ReturnType | undefined {
  const value = queryParam instanceof Array ? queryParam[0] : queryParam;

  if (value && options?.validValues && !options?.validValues.includes(value)) {
    return undefined;
  }

  if (value) {
    return value as ReturnType;
  }

  return undefined;
}
