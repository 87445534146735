import { useEffect, useState } from "react";

export function useScrollbarSize() {
  const [scrollbarSize, setScrollbarSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const scrollDiv = document.createElement("div");
    scrollDiv.style.width = "100px";
    scrollDiv.style.height = "100px";
    scrollDiv.style.overflow = "scroll";
    scrollDiv.style.position = "absolute";
    scrollDiv.style.top = "-9999px";
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    const scrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;
    document.body.removeChild(scrollDiv);
    setScrollbarSize({ width: scrollbarWidth, height: scrollbarHeight });
  }, []);

  return scrollbarSize;
}
