import React from "react";
import Icon from "domains/ui/components/Icon";

import { Flex, Text } from "@chakra-ui/react";

export interface FileTreeProps {
  structure: LinkTreeStructure[];
}

export interface LinkTreeStructure {
  id: string;
  name: string;
  type: string;
  children?: LinkTreeStructure[];
}

const TreeItem = ({
  node,
  level,
  index,
}: {
  node: LinkTreeStructure;
  level: number;
  index: number;
}) => {
  const hasChildren = node.children && node.children.length > 0;
  const indentation = `${level * 20}px`;

  return (
    <>
      <Flex
        w="full"
        pl={indentation}
        textAlign="left"
        borderRadius="md"
        bgColor={
          (node.type === "file" ? index + 1 : level) % 2
            ? "secondary.900"
            : undefined
        }
      >
        {node.type === "folder" && (
          <Flex align="center" gap={2}>
            {hasChildren && <Icon id="Ui/ChevronDown" />}
            <Icon id="Ui/Folder" />
            <Text>{node.name}</Text>
          </Flex>
        )}
        {node.type === "file" && (
          <Flex align="center" gap={2}>
            <Text>{node.name}</Text>
          </Flex>
        )}
      </Flex>
      {hasChildren &&
        node.children!.map((childNode, index) => (
          <TreeItem
            key={childNode.id}
            node={childNode}
            level={level + 1}
            index={index}
          />
        ))}
    </>
  );
};

const FileTree = ({ structure }: FileTreeProps) => {
  return (
    <>
      {structure.map((node, index) => (
        <TreeItem key={node.id} node={node} level={0} index={index} />
      ))}
    </>
  );
};

export default FileTree;
