import { useEffect, useState } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useGetCollectionsQuery } from "infra/api/generated/api";

export default function useAllCollections() {
  const { selectedTeam } = useTeamContext();
  const [paginationToken, setPaginationToken] = useState<string | undefined>();

  const { data, isLoading } = useGetCollectionsQuery({
    teamId: selectedTeam.id,
    paginationToken: paginationToken,
    pageSize: "50",
  });

  useEffect(() => {
    if (data?.nextPaginationToken) {
      setPaginationToken(data.nextPaginationToken);
    }
  }, [data?.nextPaginationToken]);

  return {
    collections: data?.collections ?? [],
    isLoading,
  };
}
