import { assetsTags } from "domains/assets/api/endpoints";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";
import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export const updateAssetsQueryDataByUpdatingAsset = (
  asset: GetAssetsByAssetIdApiResponse["asset"],
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    assetsTags.asset,
  ]) as SelectedInvalidatedByTag[]) {
    if (endpointName === "getAssets") {
      dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (draft) {
            const assetIndex = draft.assets.findIndex(
              (item) => item.id === asset.id
            );
            if (assetIndex !== -1) {
              draft.assets[assetIndex] = asset;
            }
          }
        })
      );
    }
  }
};
