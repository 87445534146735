import { StringArrayToType } from "domains/commons/ts-utils";

export const NSFW_TYPES = [
  "alcohol",
  "drugs",
  "explicit_nudity",
  "gambling",
  "hate_symbols",
  "rude_gestures",
  "suggestive",
  "tobacco",
  "violence",
  "visually_disturbing",
] as const;

export type NsfwType = StringArrayToType<typeof NSFW_TYPES>;
