import React, { useCallback, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { getImageFileName } from "domains/assets/utils/getImageFileName";
import AssetCollection from "domains/collections/components/AssetCollection";
import { isOwner } from "domains/generators/utils";
import loadBase64ImageFromUrl from "domains/image/methods/loadBase64ImageFromUrl";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import ButtonWithModal from "domains/ui/components/ButtonWithModal";
import Icon from "domains/ui/components/Icon";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
  useDeleteAssetMutation,
  usePutModelsByModelIdMutation,
} from "infra/api/generated/api";

import { Flex } from "@chakra-ui/react";

export const HEADER_HEIGHT = 56;

export interface AssetZoomHeaderProps {
  close: () => void;
  model: GetModelsByModelIdApiResponse["model"] | undefined;
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}
const AssetZoomHeader = ({
  close,
  model,
  inference,
  asset,
}: AssetZoomHeaderProps) => {
  const router = useRouter();
  const { successToast, errorToast } = useScenarioToast();
  const { selectedTeam } = useTeamContext();
  const isOnGeneratorPage = router.pathname.includes("/generators");
  const handleApiError = useHandleApiError();

  const [updateModelTrigger, { isLoading: isUpdateModelLoading }] =
    usePutModelsByModelIdMutation();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = useCallback(async () => {
    if (asset) {
      setIsDownloading(true);
      const base64 = await loadBase64ImageFromUrl(asset.url);
      if (base64) {
        const link = document.createElement("a");
        link.href = base64;
        link.download = getImageFileName({
          model,
          inference,
          asset,
        });
        link.click();
      } else {
        errorToast({
          title: "Error downloading image",
        });
      }
      setIsDownloading(false);
    }
  }, [asset, inference, model, errorToast]);

  const [deleteImageTrigger, { isLoading: deleteImageIsLoading }] =
    useDeleteAssetMutation();
  const handleDelete = useCallback(async () => {
    if (asset) {
      try {
        await deleteImageTrigger({
          teamId: selectedTeam.id,
          body: {
            assetIds: [asset.id],
          },
        });
        successToast({
          title: "Image deleted",
        });
        close();
      } catch (error) {
        handleApiError(error, "Error deleting image");
      }
    }
  }, [
    asset,
    deleteImageTrigger,
    selectedTeam.id,
    successToast,
    close,
    handleApiError,
  ]);

  const handleSetAsThumbnail = useCallback(async () => {
    if (asset && model) {
      try {
        await updateModelTrigger({
          modelId: model.id,
          body: {
            thumbnail: asset.url.split("?")[0].split("/").pop(),
          },
          teamId: selectedTeam.id,
        }).unwrap();
        successToast({
          title: "Your generator thumbnail has been updated",
        });
      } catch (error) {
        handleApiError(
          error,
          "There was an error updating the generator's thumbnail"
        );
      }
    }
  }, [
    asset,
    handleApiError,
    model,
    selectedTeam.id,
    successToast,
    updateModelTrigger,
  ]);

  return (
    <Flex
      pos="relative"
      zIndex="calc(var(--chakra-zIndices-sticky) + 3)"
      align="center"
      justify="space-between"
      h={`${HEADER_HEIGHT}px`}
      pr={4}
      pl={2}
      borderBottomWidth={1}
      bgColor="secondary.900"
    >
      <Button
        onClick={close}
        variant="ghost"
        size="sm"
        colorScheme={"secondary"}
        p={1.5}
      >
        <Icon id={"Ui/ChevronLeft"} color={"white"} />
      </Button>
      <Flex gap={4}>
        <Flex gap={2}>
          <ButtonWithModal
            variant="secondary"
            size="sm"
            p={0}
            isDisabled={!asset}
            isLoading={deleteImageIsLoading}
            modalBody={"Are you sure you want to delete this image?"}
            onConfirm={handleDelete}
            colorScheme={"danger"}
            isModalConfirmButtonLoading={deleteImageIsLoading}
          >
            <Icon id={"Ui/Trash"} h={"20px"} />
          </ButtonWithModal>
          <Button
            tooltip="Download"
            variant="secondary"
            size="sm"
            p={0}
            isDisabled={!asset}
            isLoading={isDownloading}
            onClick={handleDownload}
          >
            <Icon id={"Ui/Download"} h={"20px"} />
          </Button>
        </Flex>
        <Flex gap={2}>
          {isOnGeneratorPage && isOwner(model, selectedTeam) && (
            <Button
              isLoading={isUpdateModelLoading}
              onClick={handleSetAsThumbnail}
              variant="secondary"
              size="sm"
            >
              Set as thumbnail
            </Button>
          )}
          <Link
            href={{
              pathname: "/canvas/new",
              query: {
                importAssetId: asset?.id,
              },
            }}
            style={{ pointerEvents: asset ? "auto" : "none" }}
          >
            <Button
              leftIcon={<Icon id="Ui/Link" />}
              variant="secondary"
              size="sm"
              isDisabled={!asset}
            >
              Edit in Canvas
            </Button>
          </Link>
          <Link
            href={{
              pathname: "/images/new",
              query: {
                generatorId: inference?.modelId,
                importAssetId: `${inference?.modelId}|${inference?.id}`,
              },
            }}
            style={{ pointerEvents: inference ? "auto" : "none" }}
          >
            <Button variant="secondary" size="sm" isDisabled={!inference}>
              Restart Generation
            </Button>
          </Link>
          <AssetCollection
            assets={asset ? [asset] : []}
            isDisabled={!asset}
            menuPlacement="bottom-end"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AssetZoomHeader;
