import { Dispatch, SetStateAction, useCallback, useState } from "react";
import Button from "domains/ui/components/Button";
import { OnboardingForm } from "domains/user/interfaces/OnboardingForm";

import { Box, Checkbox, Heading, Input, VStack } from "@chakra-ui/react";

const ROLE_IDS = [
  "developer",
  "designer",
  "artist",
  "assetCreator",
  "marketingManager",
  "studioExecutive",
  "independentCreator",
  "other",
] as const;

export type RoleId = (typeof ROLE_IDS)[number];

type Role = {
  label: string;
  custom?: boolean;
};

const ROLE_HASH: { [key in RoleId]: Role } = {
  developer: {
    label: "Game Developer / Technical Developer",
  },
  designer: {
    label: "Designer / Concept Artist",
  },
  artist: {
    label: "Graphic Artist / 3D Modeler",
  },
  assetCreator: {
    label: "Content Creator / Asset Creator",
  },
  marketingManager: {
    label: "Marketing Manager",
  },
  studioExecutive: {
    label: "Studio Executive",
  },
  independentCreator: {
    label: "Independent Creator / Hobbyist",
  },
  other: {
    label: "Other",
    custom: true,
  },
};

const ROLES = ROLE_IDS.map((id) => ({ ...ROLE_HASH[id], id }));

type SectionRoleProps = {
  form: OnboardingForm;
  setForm: Dispatch<SetStateAction<OnboardingForm>>;
};

export default function SectionRole({ form, setForm }: SectionRoleProps) {
  const [option, setOption] = useState<RoleId | undefined>(undefined);
  const [customInput, setCustomInput] = useState<string>("");

  const handleOptionClick = useCallback(
    (roleId: RoleId) => {
      const role = ROLE_HASH[roleId];
      setOption(roleId);
      if (role.custom && customInput) setForm({ ...form, role: customInput });
      else setForm({ ...form, role: roleId });
    },
    [customInput, setOption, setForm, form]
  );

  const handleCustomInputChange = useCallback(
    (value: string) => {
      setCustomInput(value);
      setForm({ ...form, role: value });
    },
    [setCustomInput, setForm, form]
  );

  return (
    <VStack align="stretch" w="100%" spacing={4}>
      <Heading pb={1} color="textPrimary" size="sm">
        {"Which role best describes you?"}
      </Heading>

      <VStack align="stretch" spacing={1}>
        {ROLES.map((role) => {
          const isChecked = option === role.id;
          const isCustomMode = role.custom && isChecked;
          return (
            <Button
              key={role.id}
              as="div"
              cursor="pointer"
              variant="inputSelect"
              size="lg"
              fontSize={["14px", "16px"]}
              data-testid={role.id}
              leftIcon={
                <Checkbox as="div" isChecked={isChecked} variant="circular" />
              }
              onClick={() => handleOptionClick(role.id)}
              colorScheme={isChecked ? "secondary" : undefined}
            >
              {isCustomMode ? (
                <Input
                  color="textPrimary"
                  autoFocus
                  bgColor="background.500"
                  onChange={(e) => handleCustomInputChange(e.target.value)}
                  placeholder="Other role"
                  size="sm"
                  value={customInput}
                />
              ) : (
                <Box as="span" overflow="hidden" textOverflow="ellipsis">
                  {role.label}
                </Box>
              )}
            </Button>
          );
        })}
      </VStack>
    </VStack>
  );
}
