import { useCallback, useEffect, useMemo, useState } from "react";
import { UseAllAssetsArgs } from "domains/assets/hooks/useAllAssets";
import {
  mapSearchResultsToGeneratorFiles,
  mapSearchResultsToImagesFiles,
} from "domains/file-manager/interfaces";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useUser } from "domains/user/hooks/useUser";
import { usePostSearchQuery } from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/dist/query";

interface UseSearchArgs {
  query?: string;
  assetId?: string;
}

interface UseAssetsSearchArgs extends UseSearchArgs {
  type?: UseAllAssetsArgs["type"];
}

interface UseModelsSearchArgs extends UseSearchArgs {}

export function useAssetsSearch({ query, assetId, type }: UseAssetsSearchArgs) {
  const { nsfwFilteredTypes } = useUser();

  const queryWithType = useMemo(() => {
    return (query ? `${query} AND ` : "") + `type:${type ? type : "*"}`;
  }, [query, type]);

  const { results, isLoading, hasMore, loadMore } = useSearch({
    query: queryWithType,
    assetId,
  });

  const files = useMemo(() => {
    return mapSearchResultsToImagesFiles(results, nsfwFilteredTypes);
  }, [results, nsfwFilteredTypes]);

  return {
    files,
    isLoading,
    hasMore,
    loadMore,
  };
}

export function useModelsSearch({ query, assetId }: UseModelsSearchArgs) {
  const queryWithType = useMemo(() => {
    return (query ? query : "") + ` NOT type:*`;
  }, [query]);

  const { results, isLoading, hasMore, loadMore } = useSearch({
    query: queryWithType,
    assetId,
  });

  const files = useMemo(() => {
    return mapSearchResultsToGeneratorFiles(results);
  }, [results]);

  return {
    files,
    isLoading,
    hasMore,
    loadMore,
  };
}

function useSearch({ query, assetId }: UseSearchArgs) {
  const pageSize = 50;
  const { selectedTeam } = useTeamContext();

  const [pageNumber, setPageNumber] = useState<{
    pageNumber: number;
    query: string | undefined;
    assetId: string | undefined;
  }>({
    pageNumber: 0,
    query: query,
    assetId: assetId,
  });

  useEffect(() => {
    setPageNumber({
      pageNumber: 0,
      query: query,
      assetId: assetId,
    });
  }, [query, assetId]);

  const { data, isLoading, isFetching } = usePostSearchQuery(
    (query || assetId) &&
      pageNumber.query === query &&
      pageNumber.assetId === assetId
      ? {
          teamId: selectedTeam.id,
          pageSize: pageSize.toString(),
          pageNumber: pageNumber.pageNumber.toString(),
          body: {
            query: query ? query : undefined,
            assetId: assetId ? assetId : undefined,
          },
        }
      : skipToken
  );

  const incomingLength = data?.incomingLength ?? 0;
  const nextPage = data?.nextPage ?? 0;

  const hasMore = incomingLength === pageSize;

  const loadMore = useCallback(() => {
    if (!hasMore || pageNumber.pageNumber === nextPage) {
      return;
    }
    setPageNumber((prevPageNumber) => ({
      ...prevPageNumber,
      pageNumber: nextPage,
    }));
  }, [hasMore, nextPage, pageNumber.pageNumber]);

  return {
    results: data?.results ?? [],
    isLoading: isLoading || (isFetching && pageNumber.pageNumber === 0),
    hasMore,
    loadMore,
  };
}
