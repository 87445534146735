import { enhanceRequestWithTeamId } from "domains/teams/api/enhanceRequestWithTeamId";

type Inflight = {
  id: string;
  promise: Promise<any>;
};
const inflight: Record<string, Inflight> = {};

const DELAY = 1000;
/** Wrapper around fetch that keep all inflight requests in an object, also add a delay for debounce, clear cache once delay is expired */

export async function fetchInflight(
  url: string,
  options?: RequestInit,
  noCache?: boolean
) {
  // Add team id parameter if needed
  const { url: enhancedUrl, options: enhancedOptions } =
    enhanceRequestWithTeamId(url, options);

  const id = enhancedUrl + JSON.stringify(enhancedOptions);

  if (inflight[id] && !noCache) {
    return inflight[id].promise;
  }

  const promise = new Promise((resolve, reject) => {
    fetch(enhancedUrl, enhancedOptions)
      .then(async (response) => {
        if (
          response.headers.get("content-type")?.includes("application/json")
        ) {
          // Prevent the response to error from being read multiple times by overwriting the json() method
          const jsonResult = await response.json();
          const json = () => jsonResult;
          response.json = json;
        } else {
          // Prevent the response to error from being read multiple times by overwriting the json() method
          const blobResult = await response.blob();
          const blob = async () => blobResult;
          response.blob = blob;
        }

        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        // delete the inflight request after delay ONCE the promise is resolved
        setTimeout(() => {
          delete inflight[id];
        }, DELAY);
      });
  });

  inflight[id] = { id, promise };

  return promise;
}
