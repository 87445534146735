import {
  filterAssetTypes,
  FilterAssetTypeValue,
} from "domains/file-manager/constants/AssetFilter";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";

export interface FilterAssetTypeDropdownProps<
  T extends FilterAssetTypeValue[]
> {
  options: T;
  value?: T[number];
  onChange: (value: T[number] | undefined) => void;
}

export default function FilterAssetTypeDropdown<
  T extends FilterAssetTypeValue[]
>({ options, value, onChange }: FilterAssetTypeDropdownProps<T>) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        p={3}
        _hover={{
          bg: "whiteAlpha.300",
        }}
        colorScheme={"white"}
        data-testid="file-manager-asset-change-type-button"
        marginInlineEnd="-1px"
        rightIcon={
          value === undefined ? (
            <Icon id="Ui/ChevronDownSm" />
          ) : (
            <Button
              aria-label="remove type filter"
              leftIcon={<Icon id="Ui/CrossSm" />}
              onClick={(e) => {
                e.stopPropagation();
                onChange(undefined);
              }}
              variant={"ghost"}
              colorScheme={"white"}
              _hover={{
                bg: "whiteAlpha.300",
              }}
              p={2}
              minW="unset"
              h="unset"
            />
          )
        }
        size="sm"
        variant={"ghost"}
      >
        {value ? filterAssetTypes[value].label : "Type"}
      </MenuButton>

      <MenuList>
        <Text
          w="100%"
          mt={3}
          mb={1}
          ml={2}
          color="textTertiary"
          textAlign={"left"}
          size="body.sm"
        >
          Filter by type
        </Text>
        {options.map((key) => {
          if (!key) return;
          const isSelected = value === key;
          const dynamicTestId = `file-manager-asset-change-type-${key}`;
          return (
            <MenuItem key={key}>
              <Button
                w="200px"
                variant="menuItem"
                bg={isSelected ? "whiteAlpha.300" : "transparent"}
                color={isSelected ? "textPrimary" : "textSecondary"}
                onClick={() => onChange(key)}
                data-testid={dynamicTestId}
              >
                <Box flex="1" textAlign="left">
                  {filterAssetTypes[key].label}
                </Box>
                {isSelected && <Icon id="Ui/Check" />}
              </Button>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
