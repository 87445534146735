import { apiSlice, Endpoints } from "infra/store/apiSlice";

export const subscriptionsTags = {
  subscription: "subscription",
  limits: "limits",
} as const;

export const subscriptionsEndpoints: Endpoints = {
  getTeamsSubscriptionsByTeamId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      const { data } = await queryFulfilled;
      if (data) {
        const plan = data?.subscription?.plan;
        if (plan) {
          dispatch(
            apiSlice.util.updateQueryData("getTeams", undefined, (draft) => {
              if (!draft) {
                return;
              }

              const team = draft.teams.find((team) => team.id === arg.teamId);
              if (team) {
                team.plan = plan;
              }
            })
          );
        }
      }
    },
    providesTags: [subscriptionsTags.subscription],
  },
  getTeamsLimitsByTeamId: {
    providesTags: [subscriptionsTags.subscription, subscriptionsTags.limits],
  },
};
