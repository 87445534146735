import {
  ButtonsField,
  SwitchField,
} from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel/AssetZoomEditPanelCommons";
import { UpscaleSettings } from "domains/assets/hooks/useAssetUpscale";

import { VStack } from "@chakra-ui/react";

const SCALING_FACTORS = [2, 4];

interface UpscaleParamsProps extends UpscaleSettings {
  withMagicUpscale?: boolean;
  withFaceRestoration?: boolean;
  withPhotorealist?: boolean;
}

export default function UpscaleParams({
  scalingFactor,
  setScalingFactor,
  forceFaceRestoration,
  setForceFaceRestoration,
  photorealist,
  setPhotorealist,
  magic,
  setMagic,
  withMagicUpscale = true,
  withFaceRestoration = true,
  withPhotorealist = true,
}: UpscaleParamsProps) {
  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <ButtonsField
        title={"Increase Size"}
        options={SCALING_FACTORS}
        selectedOption={scalingFactor}
        setSelectedOption={setScalingFactor}
        textToAppend={"x"}
      />
      {withFaceRestoration && (
        <SwitchField
          title={"Face Restoration"}
          isChecked={forceFaceRestoration}
          setIsChecked={setForceFaceRestoration}
        />
      )}
      {withPhotorealist && (
        <SwitchField
          title={"Photo Optimized"}
          isChecked={photorealist}
          setIsChecked={setPhotorealist}
        />
      )}
      {withMagicUpscale && (
        <SwitchField
          title={"Enhanced Upscale"}
          isChecked={magic}
          setIsChecked={setMagic}
        />
      )}
    </VStack>
  );
}
