import { MAX_BASE64_UPLOAD_SIZE } from "domains/assets/constants/upload";
import { getBase64Size } from "domains/assets/utils/getFileSize";
import { default as picaStatic } from "pica";

export async function resizeImageForUpload(
  image: string
): Promise<{ data: string; wasResized: boolean } | undefined> {
  return await new Promise((resolve) => {
    const img = document.createElement("img");
    img.onload = () => {
      const ratio = MAX_BASE64_UPLOAD_SIZE / getBase64Size(image);
      if (ratio >= 1) {
        resolve({
          data: image,
          wasResized: false,
        });
        return;
      }

      const newWidth = Math.round(img.width * ratio);
      const newHeight = Math.round(img.height * ratio);

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;

      const pica = picaStatic();

      void pica
        .resize(img, canvas, {
          filter: "lanczos3",
        })
        .then((result) => pica.toBlob(result, "image/jpeg", 0.8))
        .then((result) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (!reader.result || typeof reader.result !== "string") {
              resolve(undefined);
              return;
            }
            resolve({
              data: reader.result,
              wasResized: true,
            });
          };
          reader.readAsDataURL(result);
        });
    };
    img.onerror = () => {
      resolve(undefined);
    };
    img.src = image;
  });
}
