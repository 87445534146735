import { Endpoints } from "infra/store/apiSlice";

export const teamsTags = {
  team: "team",
} as const;

export const teamsFixedKeys = {
  deleteTeamMember: "deleteTeamMember",
  postTeamMember: "postTeamMember",
};

export const teamsEndpoints: Endpoints = {
  getTeams: { providesTags: [teamsTags.team] },
  postTeams: { invalidatesTags: [teamsTags.team] },

  putTeamsByTeamId: { invalidatesTags: [teamsTags.team] },
  getTeamsByTeamId: { providesTags: [teamsTags.team] },
  deleteTeamsByTeamId: { invalidatesTags: [teamsTags.team] },

  postTeamsMembersByTeamId: { invalidatesTags: [teamsTags.team] },
  putTeamsMembersByTeamIdAndMemberId: { invalidatesTags: [teamsTags.team] },
  deleteTeamsMembersByTeamIdAndMemberId: { invalidatesTags: [teamsTags.team] },

  postTeamsApiKeysByTeamId: { invalidatesTags: [teamsTags.team] },
  putTeamsApiKeyByTeamIdAndKeyId: { invalidatesTags: [teamsTags.team] },
  deleteTeamsApiKeyByTeamIdAndKeyId: { invalidatesTags: [teamsTags.team] },

  deleteTeamsInvitationsByTeamId: { invalidatesTags: [teamsTags.team] },
};
