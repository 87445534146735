import React, { HTMLInputTypeAttribute } from "react";

import { Box, BoxProps, Flex, Input, InputProps } from "@chakra-ui/react";

export interface ScenarioInputProps {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  leftComponent?: React.ReactNode;
  onEnter?: () => void;
  rightComponent?: React.ReactNode;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onWheel?: React.WheelEventHandler<HTMLDivElement>;
  bgColor?: BoxProps["bgColor"];
  borderColor?: BoxProps["borderColor"];
  dataTestId?: string;
  inputProps?: Partial<InputProps>;
  onBlur?: () => void;
  autoFocus?: boolean;
  cursor?: BoxProps["cursor"];
}

const ScenarioInput = React.forwardRef<HTMLInputElement, ScenarioInputProps>(
  function ScenarioInput(props, ref) {
    return (
      <Flex
        align={"center"}
        w={"100%"}
        h={10}
        px={3}
        borderWidth={1}
        borderColor={props.borderColor ?? "border.500"}
        borderRadius={8}
        cursor={props.cursor}
        bgColor={props.bgColor ?? "whiteAlpha.50"}
        onClick={props.onClick}
      >
        {props.leftComponent && <Box pr={2.5}>{props.leftComponent}</Box>}

        <Input
          ref={ref}
          w={"100%"}
          h={"100%"}
          p={0}
          color={"textPrimary"}
          fontSize={14}
          fontWeight={400}
          border={"none"}
          _focusVisible={{ outline: "none" }}
          _placeholder={{ color: "textSecondary" }}
          cursor={props.cursor}
          bgColor={"transparent"}
          data-testid={props.dataTestId}
          disabled={props.disabled}
          onBlur={props.onBlur}
          onChange={(event) => props.setValue(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter" && props.onEnter) {
              props.onEnter();
            }
          }}
          onWheel={props.onWheel}
          placeholder={props.placeholder}
          type={props.type || "text"}
          value={props.value}
          {...props.inputProps}
          autoFocus={props.autoFocus}
        />
        {props.rightComponent && <Box pl={2.5}>{props.rightComponent}</Box>}
      </Flex>
    );
  }
);

export default ScenarioInput;
