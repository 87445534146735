import React, { createContext, useCallback, useEffect, useState } from "react";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import PlanQuotaLimitModal, {
  CustomLabelProps,
} from "domains/teams/components/PlanQuotaLimitModal";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { PlanQuotaLimit, TrialEnd } from "domains/teams/interfaces/Plan";
import {
  useLazyGetTeamsLimitsByTeamIdQuery,
  useLazyGetTeamsSubscriptionsByTeamIdQuery,
} from "infra/api/generated/api";
import moment from "moment";

type CheckUserLimitsDomain = "generators" | "images" | "collections" | "canvas";

export interface PlanProviderContext {
  maxInferenceBatchSize: number;
  checkUserLimits: (domain: CheckUserLimitsDomain) => void;
  showLimitModal: (
    limitId: PlanQuotaLimit,
    customLabelProps?: CustomLabelProps
  ) => void;
  hasTrial?: boolean;
}

export const PlanContext = createContext<PlanProviderContext>({
  maxInferenceBatchSize: 4,
  checkUserLimits: () => {},
  showLimitModal: () => {},
  hasTrial: undefined,
});

export function PlanProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const { selectedTeam } = useTeamContext();
  const [getTeamsLimitsTrigger] = useLazyGetTeamsLimitsByTeamIdQuery();
  const [getSubscriptionTrigger] = useLazyGetTeamsSubscriptionsByTeamIdQuery();
  const [maxInferenceBatchSize, setMaxInferenceBatchSize] = useState(4);
  const [trialEnd, setTrialEnd] = usePersistedState<TrialEnd | undefined>(
    PersistedStateKey.TRIAL_END,
    { defaultValue: undefined }
  );

  const [planQuotaLimitModalId, setPlanQuotaLimitModalId] =
    useState<PlanQuotaLimit>();
  const [
    planQuotaLimitModalCustomLabelProps,
    setPlanQuotaLimitModalCustomLabelProps,
  ] = useState<CustomLabelProps>();

  const teamTrialEnd =
    trialEnd &&
    trialEnd[selectedTeam.id] &&
    moment.utc(trialEnd[selectedTeam.id].expirationDate).isAfter(moment.utc())
      ? trialEnd[selectedTeam.id]
      : undefined;
  const hasTrial = false;

  // ----------------------------------

  const showLimitModal = useCallback(
    (limitId: PlanQuotaLimit, customLabelProps?: CustomLabelProps) => {
      setPlanQuotaLimitModalId(limitId);
      setPlanQuotaLimitModalCustomLabelProps(customLabelProps);
    },
    [setPlanQuotaLimitModalId, setPlanQuotaLimitModalCustomLabelProps]
  );

  const checkUserLimits = useCallback(
    async (domain: CheckUserLimitsDomain) => {
      const { data: limitData } = await getTeamsLimitsTrigger({
        teamId: selectedTeam.id,
      });

      if (!limitData) {
        return;
      }

      setMaxInferenceBatchSize(limitData.limits.inferenceBatchSize || 4);

      if (
        domain === "canvas" &&
        limitData.limits.canvasAssets !== undefined &&
        limitData.limits.canvasAssets <= limitData.values.canvasAssets &&
        limitData.limits.canvasAssets !== -1
      ) {
        return showLimitModal("planCanvasProjects");
      }

      if (
        domain === "generators" &&
        limitData.limits.retainedModels !== undefined &&
        limitData.limits.retainedModels <= limitData.values.retainedModels &&
        limitData.limits.retainedModels !== -1
      ) {
        return showLimitModal("planActiveGenerators");
      }

      if (
        domain === "images" &&
        limitData.limits.inferenceImages !== undefined &&
        limitData.limits.inferenceImages <= limitData.values.inferenceImages &&
        limitData.limits.inferenceImages !== -1
      ) {
        return showLimitModal("planImageGenerations");
      }

      if (
        domain === "collections" &&
        limitData.limits.collections !== undefined &&
        limitData.limits.collections <= limitData.values.collections &&
        limitData.limits.collections !== -1
      ) {
        return showLimitModal("planCollections");
      }
    },
    [getTeamsLimitsTrigger, showLimitModal, selectedTeam.id]
  );

  // ----------------------------------

  const handlePlanQuotaLimitModalClose = useCallback(() => {
    setPlanQuotaLimitModalId(undefined);
    setPlanQuotaLimitModalCustomLabelProps(undefined);
  }, [setPlanQuotaLimitModalId, setPlanQuotaLimitModalCustomLabelProps]);

  // ----------------------------------

  useEffect(() => {
    if (teamTrialEnd !== undefined || !selectedTeam.id) {
      return;
    }
    void (async () => {
      const { data: subscriptionData } = await getSubscriptionTrigger({
        teamId: selectedTeam.id,
        paymentProvider: "stripe",
      });

      setTrialEnd((obj) => ({
        ...(obj ?? {}),
        [selectedTeam.id]: {
          date: subscriptionData?.freeTrial || null,
          expirationDate: moment.utc().add(1, "day").toISOString(),
        },
      }));
    })();
  }, [getSubscriptionTrigger, setTrialEnd, teamTrialEnd, selectedTeam.id]);

  // ----------------------------------

  return (
    <PlanContext.Provider
      value={{
        maxInferenceBatchSize,
        checkUserLimits,
        showLimitModal,
        hasTrial,
      }}
    >
      <PlanQuotaLimitModal
        hasTrial={hasTrial}
        isOpen={!!planQuotaLimitModalId}
        id={planQuotaLimitModalId}
        customLabelProps={planQuotaLimitModalCustomLabelProps}
        onClose={handlePlanQuotaLimitModalClose}
      />

      {children}
    </PlanContext.Provider>
  );
}
