import { pinInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

import { sizes } from "./text";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(pinInputAnatomy.keys);
const baseStyle = definePartsStyle((props) => {
  return {
    field: {},
    borderColor: props.isInvalid ? "danger.500" : "border.500",
    borderWidth: 1,
    borderRadius: "lg",
    backgroundColor: "transparent",
    height: "64px",
    width: "100%",
    fontWeight: sizes["title.lg"].fontWeight,
    fontSize: sizes["title.lg"].fontSize,
    paddingTop: "2px",
  };
});
export const PinInput = defineMultiStyleConfig({ baseStyle });
