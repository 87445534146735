import { track } from "@amplitude/analytics-browser";

export default function Track(key: string, properties?: Record<string, any>) {
  try {
    track(key, properties || {});
  } catch (err) {
    console.error(
      "Error while tracking",
      err,
      "key",
      key,
      "properties",
      properties || ""
    );
  }
}
