import { Box, BoxProps, Grid, Text } from "@chakra-ui/react";

interface DividerProps extends BoxProps {
  colorScheme?: "primary" | "secondary";
  label?: string;
  dividerProps?: BoxProps;
}

export default function Divider({
  colorScheme,
  label,
  dividerProps,
  ...props
}: DividerProps) {
  if (label) {
    return (
      <Grid
        alignItems="center"
        gap={2}
        templateColumns="1fr 0fr 1fr"
        {...props}
      >
        <DividerLine colorScheme={colorScheme} />

        <Text
          color="textTertiary"
          textTransform="uppercase"
          size="body.bold.sm"
        >
          {label}
        </Text>

        <DividerLine colorScheme={colorScheme} props={dividerProps} />
      </Grid>
    );
  } else {
    return (
      <Box {...props}>
        <DividerLine colorScheme={colorScheme} props={dividerProps} />
      </Box>
    );
  }
}

interface DividerLineProps {
  colorScheme?: "primary" | "secondary";
  props?: BoxProps;
}

function DividerLine({ colorScheme, props }: DividerLineProps) {
  return (
    <Box
      h="1px"
      bgColor={
        colorScheme === "secondary" ? "borderSecondary.500" : "border.500"
      }
      {...props}
    />
  );
}
