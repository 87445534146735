import { useCallback, useState } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  PutImageUpscaleApiResponse,
  useLazyGetAssetsByAssetIdQuery,
  usePutImageUpscaleMutation,
} from "infra/api/generated/api";

export interface UpscaleSettings {
  scalingFactor: number;
  setScalingFactor: (scalingFactor: number) => void;
  forceFaceRestoration: boolean;
  setForceFaceRestoration: (forceFaceRestoration: boolean) => void;
  photorealist: boolean;
  setPhotorealist: (photorealist: boolean) => void;
  magic: boolean;
  setMagic: (magic: boolean) => void;
}

export default function useAssetUpscale(): {
  upscaleSettings: UpscaleSettings;
  handleUpscale: (props: {
    image: string;
    assetId: string | undefined;
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<PutImageUpscaleApiResponse["asset"] | undefined>;
  isUpscaleLoading: boolean;
} {
  const [isUpscaleLoading, setIsUpscaleLoading] = useState<boolean>(false);
  const [scalingFactor, setScalingFactor] = useState<number>(2);
  const [forceFaceRestoration, setForceFaceRestoration] =
    useState<boolean>(false);
  const [photorealist, setPhotorealist] = useState<boolean>(false);
  const [magic, setMagic] = useState<boolean>(false);
  const { selectedTeam } = useTeamContext();
  const [getAssetByAssetId] = useLazyGetAssetsByAssetIdQuery();
  const [triggerImageUpscale] = usePutImageUpscaleMutation();
  const { infoToast, successToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();

  const handleUpscale = useCallback(
    async ({
      image,
      assetId,
      trackingExtraParams,
    }: {
      image: string;
      assetId: string | undefined;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      setIsUpscaleLoading(true);
      infoToast({
        title: "Upscaling image...",
      });

      try {
        let upscaledAsset: PutImageUpscaleApiResponse | undefined =
          await triggerImageUpscale({
            teamId: selectedTeam.id,
            body: {
              image: assetId ? undefined : image,
              assetId: assetId,
              forceFaceRestoration: forceFaceRestoration,
              photorealist: photorealist,
              scalingFactor: scalingFactor,
              magic: magic,
              returnImage: false,
            },
          }).unwrap();

        while (upscaledAsset && upscaledAsset.asset.status === "pending") {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          try {
            upscaledAsset = await getAssetByAssetId({
              teamId: selectedTeam.id,
              assetId: upscaledAsset.asset.id,
            }).unwrap();
          } catch (_) {
            upscaledAsset = undefined;
          }
        }

        if (!upscaledAsset || upscaledAsset.asset.status !== "success") {
          errorToast({
            title: "Error upscaling image",
          });
          return;
        }

        Track(AnalyticsEvents.ImageLibrary.UpscaledImage, {
          image: assetId ? undefined : image,
          assetId,
          forceFaceRestoration: forceFaceRestoration,
          photorealist: photorealist,
          scalingFactor: scalingFactor,
          magic: magic,
          ...trackingExtraParams,
        });

        successToast({
          title: "The image has been upscaled",
        });

        return upscaledAsset.asset;
      } catch (error: unknown) {
        handleApiError(error, "Error upscaling image", {
          quota: () => {
            showLimitModal("planUpscales");
          },
        });
      } finally {
        setIsUpscaleLoading(false);
      }
    },
    [
      infoToast,
      selectedTeam.id,
      forceFaceRestoration,
      photorealist,
      scalingFactor,
      magic,
      getAssetByAssetId,
      successToast,
      handleApiError,
      showLimitModal,
      errorToast,
      triggerImageUpscale,
    ]
  );

  return {
    upscaleSettings: {
      scalingFactor,
      setScalingFactor,
      forceFaceRestoration,
      setForceFaceRestoration,
      photorealist,
      setPhotorealist,
      magic,
      setMagic,
    },
    handleUpscale,
    isUpscaleLoading,
  };
}
