import { FilterAssetTypeValue } from "domains/file-manager/constants/AssetFilter";
import { GridSortValue } from "domains/file-manager/constants/GridSort";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import { TOP_BAR_HEIGHT } from "domains/navigation/components/TopBar";

import { Flex, FlexProps, HStack, Text } from "@chakra-ui/react";

import FilterAssetTypeDropdown from "./FilterAssetTypeDropdown";
import GridColumnNumberInput from "./GridColumnNumberInput";
import GridSortDropdown from "./GridSortDropdown";
import GridViewDropdown from "./GridViewDropdown";

export interface FileManagerTopBarProps {
  styleProps?: FlexProps;

  numberOfColumns: number;
  onNumberOfColumnsChange: (numberOfColumns: number) => void;
  canChangeNumberOfColumns?: boolean;

  gridView: GridViewKey;
  onGridViewChange: (gridView: GridViewKey) => void;
  canChangeView?: boolean;

  sort?: GridSortValue;
  onSortChange?: (value: GridSortValue) => void;
  sortOptions?: GridSortValue[];

  filterAssetType?: FilterAssetTypeValue;
  onFilterAssetTypeChange?: (value: FilterAssetTypeValue | undefined) => void;
  filterAssetTypeOptions?: FilterAssetTypeValue[];

  title?: string;

  leftContent?: React.ReactNode;
}

const FileManagerTopBar = ({
  styleProps,

  numberOfColumns,
  onNumberOfColumnsChange,
  canChangeNumberOfColumns,

  gridView,
  onGridViewChange,
  canChangeView,

  sort,
  onSortChange,
  sortOptions,

  filterAssetType,
  onFilterAssetTypeChange,
  filterAssetTypeOptions,

  title,

  leftContent,
}: FileManagerTopBarProps) => {
  return (
    <Flex
      pos="sticky"
      zIndex="sticky"
      top={`${TOP_BAR_HEIGHT}px`}
      align="center"
      justify="space-between"
      flexGrow={1}
      bg="background.500"
      {...styleProps}
    >
      <Flex>
        {title ? <Text size="body.bold.lg">{title}</Text> : leftContent}
      </Flex>

      <HStack flex={1} spacing={2}>
        {filterAssetTypeOptions &&
          filterAssetTypeOptions.length > 0 &&
          onFilterAssetTypeChange && (
            <FilterAssetTypeDropdown
              value={filterAssetType}
              options={filterAssetTypeOptions}
              onChange={onFilterAssetTypeChange}
            />
          )}
      </HStack>

      <HStack my={2} spacing={1}>
        {canChangeNumberOfColumns && (
          <GridColumnNumberInput
            value={numberOfColumns}
            onChange={onNumberOfColumnsChange}
          />
        )}
        {sortOptions && sortOptions.length > 0 && onSortChange && (
          <GridSortDropdown
            value={sort ?? sortOptions[0]}
            options={sortOptions}
            onChange={onSortChange}
          />
        )}
        {canChangeView && (
          <GridViewDropdown value={gridView} onChange={onGridViewChange} />
        )}
      </HStack>
    </Flex>
  );
};

export default FileManagerTopBar;
