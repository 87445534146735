import type { ComponentStyleConfig } from "@chakra-ui/react";

export const Textarea: ComponentStyleConfig = {
  variants: {
    outline: {
      color: "textPrimary",
      borderWidth: 1,
      borderColor: "border.500",
      borderRadius: "lg",
      resize: "none",
      bgColor: "background.500",
      fontSize: "14",
      fontWeight: "400",
      px: 3,
      _placeholder: {
        color: "textTertiary",
      },
      _focus: {
        borderColor: "border.500",
        boxShadow: "none",
      },
      _hover: {
        borderColor: "border.500",
      },
    },
  },
};
