import { KeyboardEvent, useCallback, useRef, useState } from "react";
import { useHover } from "domains/ui/hooks/useHover";

import {
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
} from "@chakra-ui/react";

export default function SliderWithInputNumber({
  title,
  value,
  setValue,
  min,
  max,
  step,
  steps,
  inputProps,
  placeholder,
}: {
  title: string;
  value: number | undefined;
  setValue: (value: number | undefined) => void;
  min: number;
  max: number;
  step?: number;
  steps?: number[];
  inputProps?: NumberInputProps;
  placeholder?: string;
}) {
  const [groupRef, isHoverGroup] = useHover<HTMLDivElement>();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const thumbRef = useRef<HTMLDivElement>(null);

  const getClosestStep = useCallback(
    (value: number) => {
      if (!steps) {
        return value;
      }
      return steps.reduce((prev, curr) =>
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
      );
    },
    [steps]
  );

  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (!steps) {
        return;
      }
      if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
        const closestStep = getClosestStep(value ?? min);
        const stepIndex = steps.indexOf(closestStep);
        const newStep =
          steps[e.key === "ArrowLeft" ? stepIndex - 1 : stepIndex + 1];
        if (newStep !== undefined) {
          setValue(newStep);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [steps, value, min, setValue, getClosestStep]
  );

  return (
    <VStack ref={groupRef} w={"100%"} data-group spacing={1}>
      <HStack align={"center"} justify={"space-between"} w="100%" h={7}>
        <Text size={"body.md"}>{title}</Text>
        {!isHoverGroup && !isInputFocused && !value && placeholder ? (
          <Text color="textSecondary" size="body.md">
            {placeholder}
          </Text>
        ) : (
          <NumberInput
            max={max}
            min={min}
            onChange={(_, newValue) => {
              setValue(isNaN(newValue) ? undefined : newValue);
            }}
            size="xs"
            value={value ?? ""}
            variant={"ghost"}
            {...inputProps}
          >
            <NumberInputField
              onBlur={() => setIsInputFocused(false)}
              onFocus={() => setIsInputFocused(true)}
            />
          </NumberInput>
        )}
      </HStack>
      <Slider
        focusThumbOnChange={false}
        max={256}
        min={0}
        onChange={(newValue) => {
          if (steps) {
            const closestStep = getClosestStep(newValue);
            setValue(closestStep);
          } else {
            setValue(newValue);
          }
        }}
        onChangeStart={() => {
          thumbRef.current?.focus();
        }}
        step={step}
        value={value ?? min}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb
          ref={thumbRef}
          {...(steps
            ? {
                onKeyDown: handleOnKeyDown,
              }
            : {})}
        />
      </Slider>
    </VStack>
  );
}
