import { useEffect, useRef, useState } from "react";
import BulletIndicator from "domains/ui/components/BulletIndicator";
import Button from "domains/ui/components/Button";
import Icon, { IconId } from "domains/ui/components/Icon";
import { MenuItem, MenuItemProps } from "domains/ui/components/Menu";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";

const LINKS: {
  id: string;
  iconId: IconId;
  title: string;
  text: string;
  indicator?: boolean;
  externalLink?: MenuItemProps["externalLink"];
  internalLink?: MenuItemProps["internalLink"];
  onClick?: "openChangelog" | MenuItemProps["onClick"];
}[] = [
  {
    id: "whatsNew",
    iconId: "Domains/Links/WhatsNew",
    title: "What's new ?",
    text: "Discover our recent additions and updates.",
    onClick: "openChangelog",
  },
  {
    id: "knowledgeCenter",
    iconId: "Domains/Links/KnowledgeCenter",
    title: "Knowledge Center",
    text: "Explore our comprehensive Knowledge Center.",
    externalLink: "https://help.scenario.com/",
  },
  {
    id: "discord",
    iconId: "Domains/Links/Discord",
    title: "Community Hub",
    text: "Join our Discord for networking and support.",
    externalLink: "https://discord.com/invite/scenario",
  },
  {
    id: "feedback",
    iconId: "Domains/Links/Feedback",
    title: "Feedback & Suggestions",
    text: "Request or upvote upcoming features.",
    externalLink: "https://feedback.scenario.com/",
  },
  {
    id: "referral",
    iconId: "Domains/Links/Referral",
    title: "Refer a friend",
    text: "Earn 20% on paid referrals.",
    externalLink: "https://affiliates.scenario.com",
  },
  {
    id: "unity",
    iconId: "Domains/Links/Unity",
    title: "Unity plugin",
    text: "Use Scenario directly within Unity.",
    externalLink: process.env.NEXT_PUBLIC_UNITY_PLUGIN_URL!,
    onClick: () => {
      Track(AnalyticsEvents.Navigation.ClickedUnity);
    },
  },
];

export default function LinkButtonDropdown() {
  const changelogWidgetRef = useRef<typeof window.HNWidget>();
  const [isChangelogNew, setIsChangelogNew] = useState(false);

  const hasMainIndicator = isChangelogNew;

  const handleChangelogClick = () => {
    if (!changelogWidgetRef.current) return;
    changelogWidgetRef.current.open();
  };

  const handleChangelogIndication = (isNew: boolean) => {
    setIsChangelogNew(!!isNew);
  };

  useEffect(() => {
    if (!window.HNWidget) {
      return;
    }
    changelogWidgetRef.current = window.HNWidget.init({
      title: "Changelog Widget",
      token: "Au908fjDfq3zguXvC4tqkw",
      widget: "changelog",
      enableIndicator: true,
    });
    Object.setPrototypeOf(changelogWidgetRef.current, {
      ...Object.getPrototypeOf(changelogWidgetRef.current),
      handleIndication: handleChangelogIndication,
    });
    changelogWidgetRef.current.getUnreadIndicator();
  }, []);

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        pos="relative"
        w="36px"
        h="36px"
        p={0}
        _hover={{
          bgColor: "backgroundQuaternary.500",
        }}
        bgColor="backgroundTertiary.500"
        variant="unstyled"
      >
        <Icon id="Layout/Link" mt="-2px" />
        {hasMainIndicator && <BulletIndicator bgColor="black.500" />}
      </MenuButton>

      <MenuList w="380px" px={2}>
        {LINKS.map((link) => {
          const onLinkClick = (() => {
            if (link.onClick === "openChangelog") {
              return handleChangelogClick;
            } else if (typeof link.onClick === "function") {
              return link.onClick;
            }
          })();
          const hasIndicator = (() => {
            if (link.id === "whatsNew") return isChangelogNew;
            else return false;
          })();

          return (
            <MenuItem
              key={link.id}
              p={2}
              internalLink={link.internalLink}
              externalLink={link.externalLink}
              onClick={onLinkClick}
              borderRadius="md"
              _hover={{ bgColor: "backgroundTertiary.800" }}
              data-group
            >
              <HStack align="center" spacing={3}>
                <Box pos="relative" w="40px" h="40px">
                  <HStack
                    justify="center"
                    w="100%"
                    h="100%"
                    bg="whiteAlpha.100"
                    borderRadius="md"
                  >
                    <Icon id={link.iconId} />
                  </HStack>
                  {hasIndicator && (
                    <BulletIndicator
                      bgColor="background.500"
                      _groupHover={{ bgColor: `backgroundTertiary.800` }}
                    />
                  )}
                </Box>
                <VStack align="flex-start" flex={1} spacing={0}>
                  <Text size="body.bold.md">{link.title}</Text>
                  <Text color="textSecondary" size="body.sm">
                    {link.text}
                  </Text>
                </VStack>
              </HStack>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
