import React, { useCallback } from "react";
import ImageUpload from "domains/image/components/ImageUpload";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import loadBase64ImageFromUrl from "domains/image/methods/loadBase64ImageFromUrl";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import ImageUploading, { ImageListType } from "react-images-uploading";

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

interface SearchBarFileUploadProps {
  onImageUpload: (imageData: string) => void;
  isUploadLoading: boolean;
}

export default function SearchBarFileUpload({
  onImageUpload,
  isUploadLoading,
}: SearchBarFileUploadProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { errorToast } = useScenarioToast();

  const uploadImage = useCallback(
    async (imageData: string) => {
      onImageUpload(imageData);
      onClose();
    },
    [onClose, onImageUpload]
  );

  const { isDraggingHover, dragFunctions, onDrop } = useImageUploadDragDrop({
    onImageDrop: async (imageUrl) => {
      const base64 = await loadBase64ImageFromUrl(imageUrl);
      if (base64) {
        void uploadImage(base64);
      } else {
        errorToast({
          title: "Error importing image",
        });
      }
    },
  });

  return (
    <>
      {isUploadLoading ? (
        <Flex align={"center"} justify={"center"} w={"28px"}>
          <Spinner w={"12px"} h={"12px"} />
        </Flex>
      ) : (
        <Tooltip
          label="Upload or drag & drop an image to find similar results.
        "
        >
          <Button
            px={0}
            h={"28px"}
            w={"28px"}
            minW={"28px"}
            variant="ghost"
            onClick={onOpen}
            colorScheme={"white"}
          >
            <Icon color="textSecondary" id="Nav/Image/Outline" h={"12px"} />
          </Button>
        </Tooltip>
      )}
      <Modal
        finalFocusRef={document.body as any}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent
          w={"600px"}
          maxW={"600px"}
          p={4}
          borderWidth={1}
          borderRadius={"lg"}
          bgColor={"backgroundTertiary.500"}
        >
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <VStack spacing={2}>
                <Text size={"title.sm"}>Visual search</Text>
                <Text color={"textSecondary"} size={"body.md"}>
                  Search with a picture instead of text
                </Text>
              </VStack>
              {isUploadLoading ? (
                <Spinner w={"50px"} h={"50px"} />
              ) : (
                <ImageUploading
                  value={[]}
                  onChange={async (imageList: ImageListType) => {
                    if (imageList.length === 0) {
                      return;
                    }

                    const dataUrl = imageList[0].dataURL;
                    if (dataUrl === undefined) {
                      return;
                    }

                    void uploadImage(dataUrl);
                  }}
                  allowNonImageType={false}
                  acceptType={["jpeg", "jpg", "png", "webp"]}
                  maxNumber={1}
                  multiple={false}
                >
                  {({
                    onImageUpload,
                    dragProps: { onDrop: dragPropsOnDrop },
                  }) => {
                    return (
                      <ImageUpload
                        onImageUpload={onImageUpload}
                        onDrop={(event) => onDrop(event, dragPropsOnDrop)}
                        dragFunctions={dragFunctions}
                        isDraggingHover={isDraggingHover}
                      />
                    );
                  }}
                </ImageUploading>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
