import React from "react";

/* eslint-disable no-restricted-imports */
import {
  Tab as ChakraTab,
  TabIndicator as ChakraTabIndicator,
  TabIndicatorProps,
  TabList,
  TabListProps,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabPanelsProps,
  TabProps as ChakraTabProps,
  Tabs,
  TabsProps,
  Tooltip,
} from "@chakra-ui/react";
/* eslint-enable no-restricted-imports */

export type { TabsProps };
export { Tabs };

export type { TabListProps };
export { TabList };

export interface TabProps extends ChakraTabProps {
  tooltip?: string;
}

export const Tab = React.forwardRef<HTMLButtonElement, TabProps>(
  (props, ref) => (
    <Tooltip isDisabled={!props.tooltip} label={props.tooltip}>
      <ChakraTab ref={ref} {...props} />
    </Tooltip>
  )
);

export type { TabPanelsProps };
export { TabPanels };

export type { TabPanelProps };
export { TabPanel };

export type { TabIndicatorProps };
export const TabIndicator = React.forwardRef<HTMLDivElement, TabIndicatorProps>(
  (props, ref) => (
    <ChakraTabIndicator
      ref={ref}
      h="2px"
      mt="-3px"
      bg="primary.500"
      borderRadius="3px 3px 0 0"
      {...props}
    />
  )
);
