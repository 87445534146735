import { Box, BoxProps } from "@chakra-ui/react";

interface BulletIndicatorProps {
  color?: BoxProps["bgColor"];
  bgColor?: BoxProps["borderColor"];
  _groupHover?: {
    bgColor?: BoxProps["borderColor"];
  };
}

export default function BulletIndicator({
  color,
  bgColor,
  _groupHover,
}: BulletIndicatorProps) {
  return (
    <Box
      pos="absolute"
      top="-5px"
      right="-5px"
      w="16px"
      h="16px"
      borderWidth="3px"
      borderColor={bgColor || "background.500"}
      borderRadius="50%"
      _groupHover={
        _groupHover && { borderColor: _groupHover.bgColor || undefined }
      }
      bgColor={color || "red.500"}
    />
  );
}
