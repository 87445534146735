import React, { RefObject, useMemo, useRef, useState } from "react";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import {
  FileCanvasType,
  FileGeneratorType,
  FileHandler,
  FileImageType,
  FileType,
} from "domains/file-manager/interfaces";
import { useWindowSize } from "domains/ui/hooks/useWindowSize";
import {
  useContainerPosition,
  useInfiniteLoader,
  useMasonry,
  usePositioner,
  useResizeObserver,
} from "masonic";
import { useScroller } from "mini-virtual-list";

import { Box, HStack, Spinner, Text } from "@chakra-ui/react";
import { useDebounce } from "@react-hook/debounce";
import useSize from "@react-hook/size";

import { FileManagerProps, HandleSelect } from "../FileManager";
import DefaultFilePreview from "../FilePreview";

interface FileViewProps {
  files: FileType[];
  gridView: GridViewKey;
  numberOfColumns: number;
  selected: Set<string>;
  onSelect: HandleSelect;
  canSelect?: boolean;
  fileHandlers: FileManagerProps["fileHandlers"];
  hasMore?: boolean;
  onLoadMore: () => void;
  showFileNames?: "always" | "hover" | "never";
  scrollRef?: RefObject<HTMLDivElement>;
  loadingText?: string;
  revealed: string[];
  onReveal: (id: string) => void;
}

const FileView = ({
  files,
  gridView,
  numberOfColumns,
  selected,
  fileHandlers,
  hasMore,
  onLoadMore,
  onSelect,
  canSelect,
  showFileNames = "hover",
  scrollRef,
  loadingText = "Loading more...",
  revealed,
  onReveal,
}: FileViewProps) => {
  const maybeLoadMore = useInfiniteLoader(async () => onLoadMore(), {
    isItemLoaded: (index, items) => !!items[index],
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [containerWidth] = useSize(containerRef);
  const debouncedContainerWidth = useDebounce(containerWidth, 500);
  const { width: itemWidth } = useContainerPosition(containerRef, [
    windowWidth,
    windowHeight,
    debouncedContainerWidth,
  ]);
  const { scrollTop, isScrolling } = useScroller(scrollRef ?? window);

  // check if files[0] has changed
  const fileZero = files[0];
  const [firstFile, setFirstFile] = useState(fileZero);
  const willChangeOnlyIfFirstFileChangedDimensions = useMemo(() => {
    if (
      fileZero.width !== firstFile.width ||
      fileZero.height !== firstFile.height
    ) {
      return {};
    }
    setFirstFile(fileZero);
    // NOTE: ignoring firstFile to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // firstFile
    fileZero,
  ]);

  const [fileLength, setFileLength] = useState(files.length);
  /** Used to reset positions of already existing files, when we delete files, but not when new files are loaded */
  const willChangeOnlyIfLessFilesThanBefore = useMemo(() => {
    if (files.length < fileLength) {
      return {};
    }
    setFileLength(files.length);
    // NOTE: ignoring fileLength to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // fileLength
    files.length,
  ]);

  const positioner = usePositioner(
    {
      width: itemWidth - 30,
      columnGutter: 8,
      columnCount: numberOfColumns,
      rowGutter: 8,
    },
    [
      willChangeOnlyIfLessFilesThanBefore,
      numberOfColumns,
      gridView,
      willChangeOnlyIfFirstFileChangedDimensions,
    ]
  );

  const resizeObserver = useResizeObserver(positioner);

  // Set the selected and revealed property on the files to be rendered
  const masonryItems = useMemo(
    () =>
      files.map(
        (file): MasonryCardItem => ({
          file: file as FileImageType | FileCanvasType | FileGeneratorType,
          selected: selected.has(file.id),
          revealed: revealed.includes(file.id),
          cardWidth: positioner.columnWidth,
          gridView,
          onSelect,
          canSelect,
          // FIXME: fix type
          fileHandler: fileHandlers[file.type],
          selectModeEnabled: !!selected.size,
          showFileNames,
          onReveal,
        })
      ),
    [
      files,
      selected,
      revealed,
      gridView,
      positioner.columnWidth,
      fileHandlers,
      showFileNames,
      canSelect,
      onSelect,
      onReveal,
    ]
  );

  return (
    <Box
      ref={containerRef}
      sx={{ ".masonic": { outline: "none" } }}
      w="full"
      h="full"
      pb={selected.size > 0 ? "90px" : "0px"}
      data-testid="asset-gallery-grid"
      // Remove the ugly outline when the user clicks on the masonic container
    >
      {useMasonry({
        scrollTop,
        isScrolling,
        positioner,
        resizeObserver,
        items: masonryItems,
        onRender: maybeLoadMore,
        className: "masonic",
        containerRef,
        itemKey: (data) => data.file.id,
        overscanBy: 2,
        height: windowHeight || 0,
        render: MasonryCard,
      })}

      {hasMore && (
        <HStack
          key="loader"
          alignSelf={"center"}
          w="100%"
          maxW="max-content"
          my={6}
          marginX="auto"
        >
          <Text fontSize={"1.5em"}>{loadingText}</Text>
          <Spinner />
        </HStack>
      )}
    </Box>
  );
};

export default FileView;

type MasonryCardItem = {
  file: FileImageType | FileCanvasType | FileGeneratorType;
  selected: boolean;
  revealed: boolean;
  cardWidth: number;
  gridView: GridViewKey;
  onSelect: FileViewProps["onSelect"];
  canSelect?: boolean;
  fileHandler?:
    | FileHandler<FileImageType>
    | FileHandler<FileCanvasType>
    | FileHandler<FileGeneratorType>;
  selectModeEnabled: boolean;
  showFileNames: FileViewProps["showFileNames"];
  onReveal: FileViewProps["onReveal"];
};

function MasonryCard({
  data: {
    file,
    selected,
    revealed,
    cardWidth,
    gridView,
    onSelect,
    canSelect,
    fileHandler,
    selectModeEnabled,
    showFileNames,
    onReveal,
  },
  index,
}: {
  index: number;
  data: MasonryCardItem;
  width: number;
}) {
  /** The MasonryCard component need to make space for the file name if it is shown under the file */
  const offsetForFileName = showFileNames === "always" ? 40 : 0;
  const FilePreview = (fileHandler?.FilePreview ||
    DefaultFilePreview) as typeof DefaultFilePreview;
  const cardHeight =
    (file.height || cardWidth) * (cardWidth / (file.width || cardWidth));

  return (
    <Box
      key={file.id}
      pos="relative"
      h={
        gridView === "masonry"
          ? `${cardHeight + offsetForFileName}px`
          : `${cardWidth + offsetForFileName}px`
      }
      cursor="pointer"
      data-id={file.id}
      data-key={index}
      data-testid="asset-gallery-grid-cell"
    >
      <FilePreview
        file={file}
        gridView={gridView}
        onSelect={onSelect}
        canSelect={canSelect}
        isSelected={selected}
        // FIXME: fix type
        fileHandler={fileHandler as FileHandler<FileType>}
        selectModeEnabled={selectModeEnabled}
        showFileNames={showFileNames}
        isRevealed={revealed}
        onReveal={onReveal}
        cardWidth={cardWidth}
      />
    </Box>
  );
}
