import type { ComponentStyleConfig } from "@chakra-ui/react";

export const Link: ComponentStyleConfig = {
  variants: {
    underline: {
      textDecoration: "underline",
      _hover: {
        opacity: 0.7,
      },
    },
    unstyled: {
      textDecoration: "none",
      _hover: {
        textDecoration: "none",
      },
    },
  },
};
