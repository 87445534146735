import Icon, { IconId } from "domains/ui/components/Icon";

import { CloseButton, Flex, ResponsiveValue, Text } from "@chakra-ui/react";

export type ScenarioToastStatus =
  | "success"
  | "error"
  | "warning"
  | "info"
  | "loading";

const ToastIconComponent = ({ status }: { status: ScenarioToastStatus }) => {
  let id: IconId;
  let color: ResponsiveValue<string>;

  switch (status) {
    case "success":
      id = "Toast/Success";
      color = "success.500";
      break;
    case "error":
      id = "Toast/Error";
      color = "danger.500";
      break;
    case "warning":
      id = "Toast/Warning";
      color = "warning.500";
      break;
    case "info":
    default:
      id = "Toast/Info";
      color = "primary.500";
      break;
  }

  return <Icon w={6} h={6} id={id} color={color} />;
};

export interface ScenarioToastProps {
  title: string;
  description?: string;
  status: ScenarioToastStatus;
  onClose: () => void;
}

export default function ScenarioToast({
  title,
  description,
  status,
  onClose,
}: ScenarioToastProps) {
  return (
    <Flex
      align={"center"}
      gap={3}
      p={4}
      bg="backgroundQuaternary.500"
      borderWidth={1}
      borderColor={"borderSecondary.500"}
      borderRadius="md"
      shadow="md"
      cursor="pointer"
      onClick={onClose}
    >
      <ToastIconComponent status={status} />
      <Flex align="start" direction={"column"} overflow="hidden">
        <Text
          overflow="hidden"
          w={"100%"}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          data-testid="toast-title-text"
          size={"body.lg"}
        >
          {title}
        </Text>
        {description && (
          <Text
            overflow="hidden"
            w={"100%"}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            data-testid="toast-description-text"
            size={"body.md"}
          >
            {description}
          </Text>
        )}
      </Flex>
      <CloseButton
        ml="auto"
        data-testid="toast-close-button"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      />
    </Flex>
  );
}
