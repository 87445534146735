import ScenarioToast, {
  ScenarioToastStatus,
} from "domains/notification/components/ScenarioToast";

import { useToast, UseToastOptions } from "@chakra-ui/react";

const toastErrorOptions: UseToastOptions = {
  position: "top",
  status: "error",
  duration: 5000,
  isClosable: true,
};

const toastSuccessOptions: UseToastOptions = {
  position: "top",
  status: "success",
  duration: 5000,
  isClosable: true,
};

const toastInfoOptions: UseToastOptions = {
  status: "info",
  duration: 5000,
  position: "top",
  isClosable: true,
};

export function useScenarioToast() {
  const toast = useToast();

  const renderToast = (options: UseToastOptions) => {
    toast({
      ...options,
      containerStyle: {
        maxWidth: "unset",
      },
      render: ({ onClose }) => (
        <ScenarioToast
          title={options.title as string}
          description={`${options.description || ""}`}
          status={options.status as ScenarioToastStatus}
          onClose={onClose}
        />
      ),
    });
  };

  return {
    successToast: (options: UseToastOptions) => {
      renderToast({
        ...toastSuccessOptions,
        ...options,
      });
    },
    errorToast: (options: UseToastOptions) => {
      renderToast({
        ...toastErrorOptions,
        ...options,
      });
    },
    infoToast: (options: UseToastOptions) => {
      renderToast({
        ...toastInfoOptions,
        ...options,
      });
    },
  };
}
