import React, { useRef, useState } from "react";
import Link from "next/link";
import CreateCollectionModal from "domains/collections/components/CreateCollectionModal";
import useAllCollections from "domains/collections/hooks/useAllCollections";
import { extraTheme } from "domains/theme";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import { Fade, HStack, Text, VStack } from "@chakra-ui/react";

interface SideMenuCollectionsProps {
  showFull: boolean;
}

export default function SideMenuCollections({
  showFull,
}: SideMenuCollectionsProps) {
  const { collections } = useAllCollections();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <VStack
      ref={containerRef}
      px={showFull ? 4 : 1}
      py={4}
      borderTopWidth={1}
      transition={extraTheme.transitions.fast}
      spacing={0.5}
    >
      <HStack justify={"space-between"} w={"100%"} px={2}>
        <Fade in={showFull} unmountOnExit>
          <Text
            color={"textSecondary"}
            transition={extraTheme.transitions.fast}
            size={"title.xs"}
          >
            Collections
          </Text>
        </Fade>
        <Button
          size={"xs"}
          variant={"ghost"}
          colorScheme={"secondary"}
          leftIcon={
            <Icon id="Ui/PlusSm" color={"textSecondary"} height="12px" />
          }
          onClick={() => setIsModalOpen(true)}
        />
      </HStack>
      {collections.map((collection) => (
        <Link
          style={{
            width: "100%",
          }}
          key={`collection-${collection.id}`}
          href={{
            pathname: "/collections/[id]",
            query: { id: collection.id },
          }}
        >
          <Button
            px={2}
            w={"100%"}
            maxW={"100%"}
            variant={"ghost"}
            justifyContent={"start"}
            colorScheme={"secondary"}
          >
            <Text color={"textSecondary"} isTruncated={true} size={"body.md"}>
              {collection.name}
            </Text>
          </Button>
        </Link>
      ))}
      <CreateCollectionModal
        containerRef={containerRef}
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
      />
    </VStack>
  );
}
