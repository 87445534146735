import { ComponentStyleConfig, defineStyleConfig } from "@chakra-ui/react";

export const Editable: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    preview: {
      px: "12px",
      py: "4px",
      cursor: "pointer",
      _hover: {
        bg: "whiteAlpha.100",
      },
      borderRadius: "xl",
    },
    input: {
      width: "min-content",
      // needs to be in px to account for the border width
      px: "11px",
      py: "3px",
      borderWidth: "1px",
      borderColor: "border.500",
      bg: "blackAlpha.100",
      _focus: {
        boxShadow: "unset",
      },
      borderRadius: "xl",
    },
  },
});
