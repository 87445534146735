export const mergeImage = async (imgTarget: string, imgToMerge: string) => {
  if (imgTarget && !imgToMerge) return imgTarget;
  if (!imgTarget && imgToMerge) return imgToMerge;
  if (!imgTarget && !imgToMerge) return "";
  if (!imgTarget || !imgToMerge) return "";

  const imageA = new Image();
  imageA.crossOrigin = "anonymous";
  imageA.src = await srcToBase64(imgTarget);

  const imageB = new Image();
  imageB.crossOrigin = "anonymous";
  imageB.src = await srcToBase64(imgToMerge);

  await Promise.all([
    new Promise((resolve) => (imageA.onload = resolve)),
    new Promise((resolve) => (imageB.onload = resolve)),
  ]);

  const canvas = document.createElement("canvas");
  // set cross origin to avoid tainted canvas
  canvas.setAttribute("crossOrigin", "anonymous");

  canvas.width = imageA.width > imageB.width ? imageA.width : imageB.width;
  canvas.height = imageA.height > imageB.height ? imageA.height : imageB.height;
  const ctx = canvas.getContext("2d");

  ctx?.drawImage(imageA, 0, 0);
  ctx?.drawImage(imageB, 0, 0);

  return canvas.toDataURL();
};
export const srcToBase64 = async (src: string): Promise<string> => {
  if (src.startsWith("data:")) {
    return src;
  }

  const response = await fetch(src);
  const contentType = response.headers.get("content-type");
  if (!contentType?.startsWith("image/")) {
    throw new Error("Invalid content type");
  }

  const data = await response.blob();
  if (data.size === 0) {
    throw new Error("Empty file");
  }

  const reader = new FileReader();
  const promise = new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error("Error reading file"));
    };
    reader.onload = () => {
      resolve(reader.result as string);
    };
  });
  reader.readAsDataURL(data);
  return promise;
};
