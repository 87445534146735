import React, { useCallback, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { EditButton } from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel";
import useAllAssets from "domains/assets/hooks/useAllAssets";
import useAssetParent from "domains/assets/hooks/useAssetParent";
import useAssetRootParent from "domains/assets/hooks/useAssetRootParent";
import { denormalizeInfluence } from "domains/assets/utils/getInfluence";
import {
  FileImageType,
  getImageThumbnail,
} from "domains/file-manager/interfaces";
import { getModelThumbnail } from "domains/generators/utils";
import { ALL_SCHEDULERS } from "domains/inference/constants/Schedulers";
import {
  getControlNetInfluenceFromString,
  getControlNetPresetFromString,
} from "domains/inference/enum/ControlNetPreset";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Icon from "domains/ui/components/Icon";
import PanelInfoGridLayout from "domains/ui/components/Panel/PanelInfoGridLayout";
import { useUser } from "domains/user/hooks/useUser";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
  useLazyGetAssetsByAssetIdQuery,
} from "infra/api/generated/api";

import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";

export interface AssetZoomInfoPanelProps {
  model: GetModelsByModelIdApiResponse["model"] | undefined;
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

const DerivedChildren = ({ assets }: { assets: FileImageType[] }) => {
  const router = useRouter();

  return (
    <Grid gap={0.5} templateColumns="repeat(3, 1fr)" w="100%">
      {assets.slice(0, 9).map((asset) => (
        <GridItem key={asset.id}>
          <Link
            style={{
              width: "100%",
            }}
            href={{
              pathname: router.pathname,
              query: {
                ...router.query,
                openAssetId: asset.id,
              },
            }}
          >
            <Box pos="relative" w="100%" pt="100%">
              <Image
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                objectFit="cover"
                alt="derived image"
                src={asset.thumbnail}
              />
            </Box>
          </Link>
        </GridItem>
      ))}
    </Grid>
  );
};

const AssetZoomInfoPanel = ({
  model,
  inference,
  asset,
}: AssetZoomInfoPanelProps) => {
  const { selectedTeam } = useTeamContext();
  const router = useRouter();
  const isInference = asset?.metadata.type.includes("inference");
  const rootParent = useAssetRootParent(asset);
  const directParent = useAssetParent(asset);
  const [parents, setParents] = useState<
    | {
        reference: GetAssetsByAssetIdApiResponse["asset"];
        modeMap: GetAssetsByAssetIdApiResponse["asset"] | undefined;
      }
    | undefined
  >();
  const { files: children } = useAllAssets({
    parent: asset,
  });
  const { nsfwFilteredTypes } = useUser();

  const [getAssetTrigger] = useLazyGetAssetsByAssetIdQuery();
  useEffect(() => {
    void (async () => {
      if (
        !directParent ||
        directParent.metadata.type !== "detection" ||
        !directParent.metadata.parentId ||
        !asset ||
        asset.metadata.type !== "inference-controlnet"
      ) {
        setParents(
          directParent
            ? {
                reference: directParent,
                modeMap: undefined,
              }
            : undefined
        );
        return;
      }

      if (directParent.metadata.parentId === parents?.reference.id) {
        setParents({
          reference: parents.reference,
          modeMap: directParent,
        });
        return;
      }

      if (parents) {
        setParents(undefined);
      }

      try {
        const detectionParentData = await getAssetTrigger({
          teamId: selectedTeam.id,
          assetId: directParent.metadata.parentId,
        }).unwrap();
        setParents({
          reference: detectionParentData.asset,
          modeMap: directParent,
        });
      } catch (_) {
        setParents(undefined);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, directParent]);

  const getInferenceInfo = useCallback(() => {
    if (!inference || !isInference || !asset) {
      return [];
    }

    const info: {
      label: string;
      value: string | number | React.ReactElement;
      fullRow?: boolean;
      copyable?: boolean;
    }[] = [
      {
        label: "Prompt",
        value: inference.displayPrompt,
        fullRow: true,
        copyable: true,
      },
    ];

    if (inference.parameters.negativePrompt) {
      info.push({
        label: "Negative Prompt",
        value: inference.parameters.negativePrompt,
        fullRow: true,
        copyable: true,
      });
    }

    info.push(
      {
        label: "Steps",
        value: inference.parameters.numInferenceSteps || "Unknown",
      },
      {
        label: "Size",
        value:
          asset.metadata.width && asset.metadata.height
            ? `${asset.metadata.width}x${asset.metadata.height}px`
            : "Unknown",
      },
      {
        label: "Guidance",
        value: inference.parameters.guidance || "Unknown",
        copyable: true,
      },
      {
        label: "Scheduler",
        value:
          (inference.parameters.scheduler &&
            ALL_SCHEDULERS[inference.parameters.scheduler]) ||
          "Default",
      },
      {
        label: "Seed",
        value: asset?.metadata.seed ?? "Unknown",
        fullRow: true,
        copyable: true,
      }
    );

    if (parents) {
      info.push(
        {
          label: "Reference",
          value: inference.parameters.modality
            ? `Composition Control - ${
                getControlNetPresetFromString(inference.parameters.modality)
                  ?.label
              } mode`
            : "image2image",
          fullRow: true,
        },
        {
          label: "Reference Image",
          value: (
            <Link
              href={{
                pathname: router.pathname,
                query: {
                  ...router.query,
                  openAssetId: parents.reference.id,
                },
              }}
            >
              <Image
                alt={"reference image"}
                src={getImageThumbnail({
                  nsfwFilteredTypes,
                  url: parents.reference.url,
                  nsfw: parents.reference.nsfw ?? [],
                  type: parents.reference.metadata.type,
                })}
              />
            </Link>
          ),
        }
      );

      if (parents.modeMap) {
        info.push({
          label: "Mode Map",
          value: (
            <Link
              href={{
                pathname: router.pathname,
                query: {
                  ...router.query,
                  openAssetId: parents.modeMap.id,
                },
              }}
            >
              <Image
                alt={"mode map"}
                src={getImageThumbnail({
                  nsfwFilteredTypes,
                  url: parents.modeMap.url,
                  nsfw: parents.modeMap.nsfw ?? [],
                  type: parents.modeMap.metadata.type,
                })}
              />
            </Link>
          ),
        });
      } else {
        // Add empty grid item for consistency
        info.push({
          label: "",
          value: "",
        });
      }

      if (inference.parameters.modality) {
        info.push({
          label: "Influence",
          value: Math.round(
            denormalizeInfluence(
              getControlNetInfluenceFromString(inference.parameters.modality)
            ) * 100
          ),
        });
      } else {
        info.push({
          label: "Influence",
          value: inference.parameters.strength
            ? Math.round(
                (1 - denormalizeInfluence(inference.parameters.strength)) * 100
              )
            : "Unknown",
        });
      }
    }

    return info;
  }, [
    asset,
    inference,
    isInference,
    router.pathname,
    router.query,
    nsfwFilteredTypes,
    parents,
  ]);

  if (!asset || (!inference && isInference)) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex direction={"column"} gap={5}>
      {model ? (
        <Link
          href={{
            pathname: "/generators/[id]",
            query: {
              id: model.id,
            },
          }}
        >
          <EditButton
            text={model.name ?? "Untitled"}
            icon={getModelThumbnail(model)}
            rightComponent={<Icon id={"Ui/ChevronRight"} pr={1.5} />}
            onClick={() => {}}
          />
        </Link>
      ) : isInference ? (
        <Text size={"body.lg"}>Model unavailable</Text>
      ) : null}
      {rootParent && rootParent.id !== parents?.reference.id ? (
        <Link
          href={{
            pathname: router.pathname,
            query: {
              ...router.query,
              openAssetId: rootParent.id,
            },
          }}
        >
          <EditButton
            text={"Original Reference"}
            icon={getImageThumbnail({
              nsfwFilteredTypes,
              url: rootParent.url,
              nsfw: rootParent.nsfw ?? [],
              type: rootParent.metadata.type,
            })}
            rightComponent={<Icon id={"Ui/ChevronRight"} pr={1.5} />}
            onClick={() => {}}
          />
        </Link>
      ) : null}
      {parents ? (
        <Link
          href={{
            pathname: router.pathname,
            query: {
              ...router.query,
              openAssetId: parents.reference.id,
            },
          }}
        >
          <EditButton
            text={"Reference"}
            icon={getImageThumbnail({
              nsfwFilteredTypes,
              url: parents.reference.url,
              nsfw: parents.reference.nsfw ?? [],
              type: parents.reference.metadata.type,
            })}
            rightComponent={<Icon id={"Ui/ChevronRight"} pr={1.5} />}
            onClick={() => {}}
          />
        </Link>
      ) : null}
      <PanelInfoGridLayout
        items={[
          ...getInferenceInfo(),
          ...(!isInference
            ? [
                {
                  label: "Type",
                  value: (() => {
                    switch (asset.metadata.type) {
                      case "background-removal":
                        return "Background Removal";
                      case "pixelization":
                        return "Pixelization";
                      case "upscale":
                        return "Upscale";
                      case "uploaded":
                        return "Uploaded";
                      case "detection":
                        return "Mode map";
                      default:
                        return "Unknown";
                    }
                  })(),
                  fullRow: true,
                },
                {
                  label: "Size",
                  value:
                    asset.metadata.width && asset.metadata.height
                      ? `${asset.metadata.width}x${asset.metadata.height}px`
                      : "Unknown",
                },
              ]
            : []),
          ...(children?.length
            ? [
                {
                  label: (
                    <Link
                      href={{
                        pathname: "/images/[id]/children",
                        query: {
                          id: asset.id,
                        },
                      }}
                    >
                      <HStack align={"center"}>
                        <Text color="white.500" size="body.bold.md">
                          Derived Images
                        </Text>
                        <Icon id="Ui/Link" h="10px" />
                      </HStack>
                    </Link>
                  ),
                  value: <DerivedChildren assets={children} />,
                  fullRow: true,
                },
              ]
            : []),
        ]}
      />
    </Flex>
  );
};

export default AssetZoomInfoPanel;
