import { pick } from "lodash";

import { extendTheme, Theme } from "@chakra-ui/react";

import { colors } from "./colors";
import { components } from "./components";
import { config } from "./config";
import { fonts } from "./fonts";
import { sizes } from "./sizes";

const extendedCustomTheme = extendTheme({
  sizes,
  fonts,
  colors,
  components,
  zIndices: {
    hide: -1,
    auto: "auto",
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
    fullscreen: 2000,
  },
  config: {
    ...config,
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  styles: {
    global: () => ({
      body: {
        bg: "background.500",
      },

      ".card": {
        bg: "black",
        borderRadius: "0.5rem",
      },

      ".bg-gradient-hover": {
        bg: "linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 29.95%, rgba(0, 0, 0, 0.2) 68.23%, rgba(0, 0, 0, 0.7) 100%)",
      },
    }),
  },
});

// override default theme here, it will not include the default values so we can restrict usage
const restrictedCustomTheme: Theme = {
  ...(extendedCustomTheme as any),

  // Only allow a subset of colors
  colors: {
    ...pick(extendedCustomTheme.colors, [
      // Keep some default colors as it is use by chakra (ex: form error color)
      "black",
      "white",
      "whiteAlpha",
      "blackAlpha",
      "gray",
      "transparent",

      "red",
      "orange",
      "yellow",
      "green",
      "blue",
    ] as (keyof Theme["colors"])[]),
    ...colors,
  },
};

/** Values that should be in theme but didn't found how to add them nicely as design token yet */
export const extraTheme = {
  transitions: {
    fast: "all 0.2s ease-in-out",
    slow: "all 0.5s ease-in-out",
  },
} as const;

export default restrictedCustomTheme;
