import { IconId } from "domains/ui/components/Icon";
import _ from "lodash";

export type GridViewKey = "masonry" | "fill" | "fit";
export interface GridViewInfo {
  label: string;
  icon: {
    default: IconId;
    active: IconId;
  };
}

export const gridViews: Record<GridViewKey, GridViewInfo> = {
  masonry: {
    label: "Masonry",
    icon: {
      default: "Domains/Assets/Grid/Masonry/Outline",
      active: "Domains/Assets/Grid/Masonry/Solid",
    },
  },
  fill: {
    label: "Fill in grid",
    icon: {
      default: "Domains/Assets/Grid/Fill/Outline",
      active: "Domains/Assets/Grid/Fill/Solid",
    },
  },
  fit: {
    label: "Fit in grid",
    icon: {
      default: "Domains/Assets/Grid/Fit/Outline",
      active: "Domains/Assets/Grid/Fit/Solid",
    },
  },
};

export const gridViewKeys = _.keys(gridViews) as GridViewKey[];
