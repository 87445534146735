import { Periodicity, PlanId, Plans } from "domains/teams/interfaces/Plan";
import _ from "lodash";

export const PERIODICITY: Periodicity[] = ["month", "year"];

export const PLANS: Plans = {
  free: {
    id: "free",
    label: "Starter",
    hasTrial: false,
    amountPerSeat: "Free",
    keyFeatures: (data) =>
      _.compact([
        "Low-priority processing*",
        !data?.isPrivate && "Single-user workspace",
        "Access curated generators",
        "Community support",
      ]),
    support: [
      {
        title: "Join Our Discord Community",
        text: "Connect with fellow creators and game professionals in our supportive Discord server. Share ideas, ask questions, and learn from the successes of others.",
      },
      {
        title: "Feature Request Board",
        text: "Make your voice heard by requesting new features or upvoting existing requests directly within our app.",
      },
      {
        title: "Comprehensive Knowledge Center & Automated Support",
        text: "Access a public, user-friendly knowledge center with articles tailored for all experience levels. Our knowledge center bot simplifies navigation and offers quick solutions.",
      },
    ],
    details: {
      collaborativeWorkspace: false,
      images: {
        generations: "None", // The new label being "Priority Generations" for the free plan, we can say here "None" instead of whatever the limit is.
        rateLimit: "Variable",
        enhancements: "Variable",
        additionalGenerations: "-",
        maxImagesPerBatch: "4",
        texturesAndSkyboxes: false,
        concurrentGeneration: "Variable",
      },
      canvas: {
        numberOfProjects: "5",
        numberOfLayers: "20",
        snapshotDuration: "7 days",
      },
      generators: {
        monthlyTrainings: "-",
        maxGenerators: "-",
        concurrentTraining: "-",
      },
      workspaceManagement: {
        maxCollections: "-",
        storage: "-",
        apiAccess: false,
        sso: false,
      },
      support: {
        supportTier: "Community",
        knowledgeCenter: true,
        tickets: false,
        trainingAssistance: false,
        accountManager: false,
      },
    },
  },
  essential: {
    id: "essential",
    label: "Essential",
    hasTrial: false,
    amountPerSeat: { month: 2900, year: 29000 },
    keyFeatures: [
      "Priority processing",
      "500 images / seat / mo.",
      "Unlimited generator trainings",
      "Store 20 trained generators",
      "Standard support",
    ],
    link: {},
    details: {
      collaborativeWorkspace: true,
      images: {
        generations: "500 / seat / mo.",
        rateLimit: "2,000",
        enhancements: "Unlimited",
        additionalGenerations: "$0.06",
        maxImagesPerBatch: "4",
        texturesAndSkyboxes: false,
        concurrentGeneration: "Uncapped",
      },
      canvas: {
        numberOfProjects: "20 / seat",
        numberOfLayers: "Unlimited",
        snapshotDuration: "30 days",
      },
      generators: {
        monthlyTrainings: "Unlimited",
        maxGenerators: "20",
        concurrentTraining: "Uncapped",
      },
      workspaceManagement: {
        maxCollections: "20",
        storage: "50 Gb",
        apiAccess: true,
        sso: false,
      },
      support: {
        supportTier: "Standard",
        knowledgeCenter: true,
        tickets: true,
        trainingAssistance: false,
        accountManager: false,
      },
    },
    support: [
      {
        title: "All Starter Features Included",
      },
      {
        plus: true,
        title: "Ticket Support",
      },
    ],
    options: {
      supportFormId: "a41beb50-c3db-4b3d-b718-27d0fe9ec9f4",
    },
  },
  pro: {
    id: "pro",
    label: "Pro",
    hasTrial: false,
    amountPerSeat: { month: 9900, year: 99000 },
    keyFeatures: [
      "Priority processing",
      "2,000 images / seat / mo.",
      "Unlimited generator trainings",
      "Store 100 trained generators",
      "Enhanced support",
    ],
    link: {},
    details: {
      collaborativeWorkspace: true,
      images: {
        generations: "2,000 / seat / mo.",
        rateLimit: "2,000",
        enhancements: "Unlimited",
        additionalGenerations: "$0.05",
        maxImagesPerBatch: "16",
        texturesAndSkyboxes: true,
        concurrentGeneration: "Uncapped",
      },
      canvas: {
        numberOfProjects: "100 / seat",
        numberOfLayers: "Unlimited",
        snapshotDuration: "90 days",
      },
      generators: {
        monthlyTrainings: "Unlimited",
        maxGenerators: "100",
        concurrentTraining: "Uncapped",
      },
      workspaceManagement: {
        maxCollections: "100",
        storage: "1 Tb",
        apiAccess: true,
        sso: true,
      },
      support: {
        supportTier: "Enhanced",
        knowledgeCenter: true,
        tickets: true,
        trainingAssistance: true,
        accountManager: false,
      },
    },
    support: [
      {
        title: "All Essential Features Included",
      },
      {
        plus: true,
        title: "Priority Ticketed Support",
        text: "Receive faster response times and personalized assistance through our dedicated ticketing system.",
      },
      {
        plus: true,
        title: "Exclusive Master Class Webinars",
        text: "Gain access to regular webinars and master classes for you and your team with a Pro membership.",
      },
    ],
    options: {
      supportFormId: "620f121c-6584-4040-9bf0-9c19b3de98ec",
    },
  },
  premium: {
    id: "premium",
    label: "Premium",
    hasTrial: false,
    amountPerSeat: { month: 19900, year: 199000 },
    keyFeatures: [
      "High-priority processing",
      "5,000 images / seat / mo.",
      "Unlimited generator trainings",
      "Store unlimited generators",
      "Advanced support",
    ],
    link: {},
    details: {
      collaborativeWorkspace: true,
      images: {
        generations: "5,000 / seat / mo.",
        rateLimit: "5,000",
        enhancements: "Unlimited",
        additionalGenerations: "$0.04",
        maxImagesPerBatch: "16",
        texturesAndSkyboxes: true,
        concurrentGeneration: "Uncapped",
      },
      canvas: {
        numberOfProjects: "Unlimited",
        numberOfLayers: "Unlimited",
        snapshotDuration: "Unlimited",
      },
      generators: {
        monthlyTrainings: "Unlimited",
        maxGenerators: "Unlimited",
        concurrentTraining: "Uncapped",
      },
      workspaceManagement: {
        maxCollections: "Unlimited",
        storage: "5 Tb",
        apiAccess: true,
        sso: true,
      },
      support: {
        supportTier: "Advanced",
        knowledgeCenter: true,
        tickets: true,
        trainingAssistance: true,
        accountManager: true,
      },
    },
    support: [
      {
        title: "All Pro Features Included",
      },
      {
        plus: true,
        title: "Personalized Onboarding Support",
        text: "A dedicated Customer Success agent will guide you through the onboarding process, recommending workflows, tools, dataset curation, and generator training parameters tailored to your goals.",
      },
      {
        plus: true,
        title: "Custom Model Training",
        text: "Receive up to three custom iterative generators, trained by Scenario using provided assets, to help identify the best strategies for your unique art style.",
      },
      {
        plus: true,
        title: "Same Day Support",
        text: "Our global team is available 24/7 to provide assistance when you need it most.",
      },
      {
        plus: true,
        title: "Priority Ticketing",
        text: "Enjoy faster response times for help tickets with a dedicated Customer Success team member on your case.",
      },
    ],
    options: {
      supportFormId: "6745e3d9-dee0-48a3-983d-70a3dcbaabab",
    },
  },
  enterprise: {
    id: "enterprise",
    label: "Custom",
    subLabel:
      "Contact us for a custom plan tailored to your organization's needs.",
    hasTrial: false,
    amountPerSeat: "Custom",
    link: { label: "Talk To Us!", white: true },
    details: {
      collaborativeWorkspace: true,
      images: {
        generations: "Custom",
        rateLimit: "Custom",
        enhancements: "Unlimited",
        additionalGenerations: "Custom",
        maxImagesPerBatch: "Custom",
        texturesAndSkyboxes: true,
        concurrentGeneration: "Uncapped",
      },
      canvas: {
        numberOfProjects: "Unlimited",
        numberOfLayers: "Unlimited",
        snapshotDuration: "Unlimited",
      },
      generators: {
        monthlyTrainings: "Custom",
        maxGenerators: "Custom",
        concurrentTraining: "Uncapped",
      },
      workspaceManagement: {
        maxCollections: "Unlimited",
        storage: "Custom",
        apiAccess: true,
        sso: true,
      },
      support: {
        supportTier: "Advanced",
        knowledgeCenter: true,
        tickets: true,
        trainingAssistance: true,
        accountManager: true,
      },
    },
    support: [
      {
        title: "All Premium Features Included",
      },
      {
        plus: true,
        title: "White Glove Advanced Model Training",
        text: "Benefit from full-service generator training support, designed to make onboarding seamless. Advanced generators offer a bespoke model with the ability to iterate and produce near-production-ready outputs.",
      },
      {
        plus: true,
        title: "On-site Staff Training Seminars",
        text: "Custom plan customers can opt for on-site training packages tailored to their needs.",
      },
    ],
    options: {
      supportFormId: "6745e3d9-dee0-48a3-983d-70a3dcbaabab",
    },
  },
  "no-limits": {
    id: "no-limits",
    label: "No limits",
    hasTrial: false,
    amountPerSeat: { month: 0, year: 0 },
    support: [
      {
        title: "Join Our Discord Community",
        text: "Connect with fellow creators and game professionals in our supportive Discord server. Share ideas, ask questions, and learn from the successes of others.",
      },
      {
        title: "Feature Request Board",
        text: "Make your voice heard by requesting new features or upvoting existing requests directly within our app.",
      },
      {
        title: "Comprehensive Helpdesk & Automated Support",
        text: "Access a public, user-friendly helpdesk with articles tailored for all experience levels. Our helpdesk bot simplifies navigation and offers quick solutions.",
      },
    ],
    options: {
      supportFormId: "6745e3d9-dee0-48a3-983d-70a3dcbaabab",
    },
  },
};

export const ORDERED_PLANS: PlanId[] = [
  "free",
  "essential",
  "pro",
  "premium",
  "enterprise",
  "no-limits",
];

export const SUBSCRIPTION_PLANS: PlanId[] = [
  "free",
  "essential",
  "pro",
  "premium",
  "enterprise",
];

export const SUPPORT_PLANS: PlanId[] = [
  "free",
  "essential",
  "pro",
  "premium",
  "enterprise",
];
