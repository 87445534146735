import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderWidth: 0.5,
    borderRadius: "lg",
  },
  header: {
    paddingTop: 1,
    paddingBottom: 0.5,
  },
  body: {
    fontSize: "sm",
    fontWeight: "normal",
    paddingTop: 0.5,
  },
  footer: {
    paddingTop: "0px",
  },
});

const variants = {
  default: definePartsStyle({
    container: {
      borderColor: "border.500",
      backgroundColor: "secondary.900",
    },
  }),
};

const sizes = {};

export const Card = defineMultiStyleConfig({
  variants,
  baseStyle,
  sizes,
  defaultProps: { variant: "default" },
});
