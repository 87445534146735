import { countries, ICountry, TCountryCode } from "countries-list";
import _ from "lodash";

export type Country = {
  id: string;
  phoneCode: number;
  label: string;
};

export const COUNTRIES = (() => {
  const value = Object.keys(countries).reduce<{
    ids: string[];
    hash: { [key: string]: Country };
  }>(
    (memo, key: string) => {
      const country: ICountry = countries[key as TCountryCode];
      const id = key.toLowerCase();
      const phoneCode =
        {
          DO: 1,
          KZ: 7,
          PR: 1,
          XK: 383,
        }[id] ?? country.phone[0];
      const label = country.name;

      memo.ids.push(id);
      memo.hash[id] = {
        id,
        phoneCode,
        label,
      };
      return memo;
    },
    {
      ids: [],
      hash: {},
    }
  );
  value.ids = _.sortBy(value.ids, (id) => value.hash[id].label);
  return value;
})();
