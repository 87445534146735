import React, { useCallback } from "react";
import { useRouter } from "next/router";
import { ACTIONS } from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel";
import useAssetUpscale from "domains/assets/hooks/useAssetUpscale";
import Button from "domains/ui/components/Button";
import UpscaleParams from "domains/ui/components/UpscaleParams";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

import { VStack } from "@chakra-ui/react";

export interface AssetZoomUpscaleProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  updateCurrentAction: (action: ACTIONS | undefined) => void;
}

const AssetZoomUpscale = ({
  asset,
  updateCurrentAction,
}: AssetZoomUpscaleProps) => {
  const router = useRouter();
  const { upscaleSettings, handleUpscale, isUpscaleLoading } =
    useAssetUpscale();

  const onUpscale = useCallback(async () => {
    const newAsset = await handleUpscale({
      image: "",
      assetId: asset.id,
      trackingExtraParams: {
        origin: "image edit",
      },
    });
    if (newAsset) {
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: newAsset.id,
        },
      });
      updateCurrentAction(undefined);
    }
  }, [asset, handleUpscale, router, updateCurrentAction]);

  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <UpscaleParams {...upscaleSettings} />
      <Button onClick={onUpscale} isLoading={isUpscaleLoading} w={"100%"}>
        Upscale
      </Button>
    </VStack>
  );
};

export default AssetZoomUpscale;
