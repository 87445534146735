import sizeof from "object-sizeof";

import { AnyAction, isRejectedWithValue, Middleware } from "@reduxjs/toolkit";

export const apiResponseMiddleware: Middleware =
  () => (next) => (action: AnyAction) => {
    try {
      if (
        isRejectedWithValue()(action) &&
        sizeof((action.meta.arg as any).originalArgs.body) > 6 * 1024 * 1024 // 6mb
      ) {
        action.payload = {
          status: 413,
        };
      }
    } catch (_) {}

    return next(action);
  };
