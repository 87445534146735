import React from "react";
import Link from "next/link";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { useUser } from "domains/user/hooks/useUser";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";
import _ from "lodash";

import { Box, Text, VStack } from "@chakra-ui/react";

export interface AssetZoomNsfwProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  revealed: string[];
  onReveal: (id: string) => void;
}

const AssetZoomNsfw = ({ asset, revealed, onReveal }: AssetZoomNsfwProps) => {
  const { nsfwFilteredTypes } = useUser();

  if (
    _.intersection(nsfwFilteredTypes, asset.nsfw).length === 0 ||
    revealed.includes(asset.id)
  ) {
    return null;
  }

  return (
    <>
      <VStack
        pos="absolute"
        top="50%"
        left="50%"
        textAlign="center"
        transform="translate(-50%, -50%)"
        spacing={5}
      >
        <Icon id="Ui/EyeClosed" />
        <VStack spacing={2}>
          <Text size="title.sm">Potentially Sensitive Content</Text>
          <Text size="body.lg">
            The content of this image might
            <br />
            not be suitable for all audiences.
          </Text>
        </VStack>
        <Box
          w="fit-content"
          h="fit-content"
          borderRadius={"md"}
          bgColor="secondary.900"
        >
          <Button
            variant="secondary"
            onClick={() => {
              onReveal(asset.id);
            }}
          >
            View image
          </Button>
        </Box>
      </VStack>
      <Link
        href={{
          pathname: "/profile",
        }}
      >
        <Button
          pos="absolute"
          top="90%"
          left="50%"
          transform="translateX(-50%)"
          variant="underline"
          colorScheme="white"
          size="sm"
          fontWeight={400}
        >
          Disable Safety Filter on Scenario
        </Button>
      </Link>
    </>
  );
};

export default AssetZoomNsfw;
