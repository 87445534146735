import { Heading, Text, VStack } from "@chakra-ui/react";

interface TopTitleProps {
  title: string | React.ReactElement;
  subTitle?: string | React.ReactElement;
}

export default function TopTitle({ title, subTitle }: TopTitleProps) {
  return (
    <VStack align="flex-start" spacing={3}>
      <Heading color="textPrimary" data-testid="top-title-text" size="lg">
        {title}
      </Heading>
      {!!subTitle && (
        <Text
          color="textSecondary"
          data-testid="top-title-secondary-text"
          size="body.lg"
        >
          {subTitle}
        </Text>
      )}
    </VStack>
  );
}
