import { useEffect } from "react";
import type { AppProps } from "next/app";
import { NextSeo } from "next-seo";
import AuthProvider from "domains/auth/components/AuthProvider";
import { AuthRedirectGuard } from "domains/auth/components/AuthRedirectGuard";
import { CanvasLockProvider } from "domains/canvasv2/hooks/useCanvasLock";
import { DragDropBackend } from "domains/commons/dnd";
import { IntlProvider } from "domains/messages/components/IntlProvider";
import { ChatProvider } from "domains/navigation/contexts/ChatProvider";
import { SidebarProvider } from "domains/navigation/contexts/SidebarProvider";
import { PlanProvider } from "domains/teams/contexts/PlanProvider";
import { TeamProvider } from "domains/teams/contexts/TeamProvider";
import theme from "domains/theme";
import Layout from "domains/ui/components/Layout";
import OnboardingForm from "domains/user/components/OnboardingForm";
import PolicyAcceptance from "domains/user/components/PolicyAcceptance";
import ErrorBoundary from "infra/errors/ErrorBoundary";
import store from "infra/store/store";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore moment/min/moment-with-locales does exist but ts doesn't see it that way
import moment from "moment/min/moment-with-locales";
import { DndProvider } from "react-dnd";
import { Provider as ReduxProvider } from "react-redux";

import { CSSReset } from "@chakra-ui/css-reset";
import { ChakraProvider } from "@chakra-ui/react";

import "domains/theme/globals.css";
// We need to import this for the canvas, if we place it in another file than _app it will break the style once build
import "domains/canvasv2/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      return;
    }
  }, []);

  useEffect(() => {
    moment.locale(navigator.language || "en-US");
  }, []);

  return (
    <IntlProvider>
      <AuthProvider>
        <NextSeo
          titleTemplate="%s | Scenario"
          openGraph={{
            title: "Scenario - AI-generated game assets",
            description:
              "Craft unique and style-consistent game assets with custom-trained AI models",
            images: [
              {
                url: "/og.png",
                height: 1068,
                width: 2048,
              },
            ],
          }}
          twitter={{
            cardType: "summary_large_image",
            handle: "@scenario_gg",
            site: "https://scenario.com",
          }}
        />
        <ReduxProvider store={store}>
          <TeamProvider>
            <SidebarProvider>
              <ChakraProvider theme={theme}>
                <CSSReset />
                <ErrorBoundary>
                  <AuthRedirectGuard>
                    <PlanProvider>
                      <ChatProvider>
                        <Layout sidebar>
                          <CanvasLockProvider>
                            <DndProvider backend={DragDropBackend}>
                              <Component {...pageProps} />
                              <PolicyAcceptance />
                              <OnboardingForm />
                            </DndProvider>
                          </CanvasLockProvider>
                        </Layout>
                      </ChatProvider>
                    </PlanProvider>
                  </AuthRedirectGuard>
                </ErrorBoundary>
              </ChakraProvider>
            </SidebarProvider>
          </TeamProvider>
        </ReduxProvider>
      </AuthProvider>
    </IntlProvider>
  );
}
