import { useMemo, useState } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Avatar from "domains/ui/components/Avatar";
import Button from "domains/ui/components/Button";
import DecisionModal from "domains/ui/components/DecisionModal";
import Icon, { IconId } from "domains/ui/components/Icon";
import { MenuItem, MenuItemProps } from "domains/ui/components/Menu";
import { useUser } from "domains/user/hooks/useUser";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuList,
  Spinner,
  Text,
} from "@chakra-ui/react";

type ActionListItem =
  | {
      id: string;
      iconId: IconId;
      label: string;
      variant?: "ghost" | "primary";
      externalLink?: MenuItemProps["externalLink"];
      internalLink?: MenuItemProps["internalLink"];
      onClick?: "signOut" | "newWorkspace" | MenuItemProps["onClick"];
      dataTestId?: string;
    }
  | "divider";

const ACTION_LIST: ActionListItem[] = [
  {
    id: "workspaceSettings",
    iconId: "Layout/Settings",
    label: "Workspace Settings",
    internalLink: "/team",
    dataTestId: "user-menu-workspace-settings-button",
    onClick: () => {
      Track(AnalyticsEvents.Navigation.ClickedTeamSettings);
    },
  },
  {
    id: "newWorkspace",
    iconId: "Ui/Plus",
    label: "New Workspace",
    onClick: "newWorkspace",
    dataTestId: "user-menu-new-workspace-button",
  },
  "divider",
  {
    id: "personalProfile",
    iconId: "Layout/Profile",
    label: "Profile",
    internalLink: "/profile",
    dataTestId: "user-menu-profile-button",
    onClick: () => {
      Track(AnalyticsEvents.Navigation.ClickedProfile);
    },
  },
  "divider",
  {
    id: "signOut",
    iconId: "Layout/SignOut",
    label: "Logout",
    onClick: "signOut",
    dataTestId: "user-menu-logout-button",
  },
];

export default function WorkspaceButtonDropdown() {
  const { logout } = useUser();
  const { teams, selectedTeam, setSelectedTeam, showCreateModal } =
    useTeamContext();

  const [isSignOutConfirmDialogOpen, setIsSignOutConfirmDialogOpen] =
    useState<boolean>(false);

  const isTeamLoading = teams.length === 0;
  const canUpgrade = selectedTeam.plan !== "enterprise";

  const actionList = useMemo(
    () =>
      ACTION_LIST.filter((item) => {
        if (item === "divider") return true;
        else if (item.id === "upgrade" && !canUpgrade) return false;
        return true;
      }),
    [canUpgrade]
  );

  const handleSignOut = () => {
    Track(AnalyticsEvents.Navigation.ClickedLogout);
    logout();
    setIsSignOutConfirmDialogOpen(false);
  };

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          w="auto"
          h="auto"
          p={0}
          _hover={{
            bgColor: "none",
            opacity: 0.7,
          }}
          _active={{
            bgColor: "none",
            opacity: 1,
          }}
          colorScheme="white"
          data-testid="topbar-user-setting-dropdown-button"
          rightIcon={<Icon id="Ui/ChevronDownSm" />}
          variant="ghost"
        >
          <Avatar w="36px" h="36px" {...selectedTeam.avatar} />
        </MenuButton>

        <MenuList
          overflowY="auto"
          w="220px"
          maxH="80vh"
          px={2}
          data-testid="topbar-user-setting-dropdown-content"
        >
          <MenuGroup title="Workspaces">
            {isTeamLoading && <Spinner ml={2} size="xs" />}

            {teams.map((team) => {
              const isSelected = selectedTeam.id === team.id;
              return (
                <MenuItem
                  key={team.id}
                  p={2}
                  borderRadius="md"
                  _hover={{
                    bgColor: isSelected
                      ? "primarySoft"
                      : "backgroundTertiary.800",
                  }}
                  bgColor={isSelected ? "primarySoft" : undefined}
                  onClick={() => setSelectedTeam(team)}
                >
                  <HStack align="flex-start" w={"100%"} spacing={2}>
                    <Flex
                      pos="relative"
                      align="center"
                      justify="center"
                      w="20px"
                      h="20px"
                    >
                      {isSelected && <Icon id="Ui/Check" color="primary.500" />}
                    </Flex>
                    <Text flex={1} isTruncated={true} size="body.md">
                      {team.name}
                    </Text>
                  </HStack>
                </MenuItem>
              );
            })}
          </MenuGroup>

          <MenuDivider mx={-2} />

          {actionList.map((item, idx) => {
            if (item === "divider") {
              return <MenuDivider key={`divider-${idx}`} mx={-2} />;
            }

            const onActionClick = (() => {
              if (item.onClick === "newWorkspace") {
                return showCreateModal;
              } else if (item.onClick === "signOut") {
                return () => {
                  setIsSignOutConfirmDialogOpen(true);
                };
              } else if (typeof item.onClick === "function") {
                return item.onClick;
              }
            })();

            return (
              <MenuItemAction
                key={item.id}
                p={2}
                internalLink={item.internalLink}
                externalLink={item.externalLink}
                onClick={onActionClick}
                variant={item.variant}
                borderRadius="md"
                data-testid={item.dataTestId}
              >
                <HStack align="flex-start" spacing={2}>
                  <Flex
                    pos="relative"
                    align="center"
                    justify="center"
                    w="20px"
                    h="20px"
                  >
                    <Icon id={item.iconId} />
                  </Flex>
                  <Text size="body.md">{item.label}</Text>
                </HStack>
              </MenuItemAction>
            );
          })}
        </MenuList>
      </Menu>

      <DecisionModal
        isOpen={isSignOutConfirmDialogOpen}
        body="Are you sure you want to logout?"
        onConfirm={handleSignOut}
        onClose={() => setIsSignOutConfirmDialogOpen(false)}
        colorScheme="danger"
        confirmMessage="Logout"
      />
    </>
  );
}

// ------------------------------------

interface MenuItemActionProps extends MenuItemProps {
  variant?: "ghost" | "primary";
}

function MenuItemAction({ variant, ...props }: MenuItemActionProps) {
  if (variant === "primary") {
    return (
      <MenuItem
        bgColor="primary.500"
        _hover={{
          bgColor: "primary.300",
        }}
        _active={{
          bgColor: "primary.400",
        }}
        {...props}
      />
    );
  } else {
    return (
      <MenuItem
        _hover={{
          bgColor: "backgroundTertiary.800",
        }}
        {...props}
      />
    );
  }
}
