import { sliderAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle((props) => {
  const { colorScheme } = props;

  return {
    thumb: {
      bg: `secondary.900`,
      borderColor: `${colorScheme}.500`,
      borderWidth: "2px",
    },
    filledTrack: {
      bg: `${colorScheme}.500`,
    },
  };
});
export const Slider = defineMultiStyleConfig({ baseStyle });
