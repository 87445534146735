import { useCallback, useState } from "react";

interface UseImageUploadDragDropProps {
  onImageDrop?: (imageUrl: string) => Promise<void>;
  allowEventFromApp?: boolean;
  stopPropagation?: boolean;
}

export default function useImageUploadDragDrop({
  onImageDrop,
  allowEventFromApp = true,
}: UseImageUploadDragDropProps) {
  const [isDraggingHover, setIsDraggingHover] = useState(0);

  const onDragEnter = useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      if (!allowEventFromApp && !event.dataTransfer.types.includes("Files")) {
        return;
      }

      setIsDraggingHover((prevState) => prevState + 1);
    },
    [allowEventFromApp]
  );

  const onDragLeave = useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      if (!allowEventFromApp && !event.dataTransfer.types.includes("Files")) {
        return;
      }

      setIsDraggingHover((prevState) => {
        if (prevState > 0) {
          return prevState - 1;
        }
        return prevState;
      });
    },
    [allowEventFromApp]
  );

  const onDragOver = useCallback((event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onDrop = useCallback(
    (
      event: React.DragEvent<HTMLElement>,
      callback?: (event: React.DragEvent<HTMLElement>) => void
    ) => {
      setIsDraggingHover(0);
      const imageUrl = event?.dataTransfer?.getData("URL");

      if (imageUrl) {
        void onImageDrop?.(imageUrl);
      }

      if (callback) {
        callback(event);
      }
    },
    [onImageDrop]
  );

  return {
    isDraggingHover: isDraggingHover > 0,
    dragFunctions: {
      onDragEnter,
      onDragLeave,
      onDragOver,
    },
    onDrop,
  };
}
