import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import Button from "domains/ui/components/Button";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import { useHandleApiError } from "infra/api/error";
import {
  useGetTeamsLimitsByTeamIdQuery,
  usePostCollectionMutation,
} from "infra/api/generated/api";

import {
  Modal,
  ModalBody,
  ModalContent,
  Text,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";

export interface CreateCollectionModalProps {
  containerRef: React.RefObject<HTMLDivElement>;
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}

const CreateCollectionModal = ({
  containerRef,
  setIsOpen,
  isOpen,
}: CreateCollectionModalProps) => {
  const { successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { selectedTeam } = useTeamContext();
  const { showLimitModal } = usePlanContext();
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [newName, setNewName] = useState("");
  const { data: limitData } = useGetTeamsLimitsByTeamIdQuery({
    teamId: selectedTeam.id,
  });
  const canCreateCollection = useMemo(() => {
    return (
      !limitData ||
      limitData.limits.collections === undefined ||
      limitData.limits.collections === -1 ||
      limitData.limits.collections > limitData.values.collections
    );
  }, [limitData]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    setNewName("");
  }, [setIsOpen]);

  useEffect(() => {
    if (isOpen && !canCreateCollection) {
      showLimitModal("planCollections");
      onClose();
    }
  }, [canCreateCollection, isOpen, setIsOpen, showLimitModal, onClose]);

  const [createCollectionTrigger, { isLoading: isLoadingCreateCollection }] =
    usePostCollectionMutation();
  const createCollection = useCallback(async () => {
    if (!newName) {
      return;
    }
    try {
      await createCollectionTrigger({
        teamId: selectedTeam.id,
        body: {
          name: newName,
        },
      }).unwrap();
      successToast({
        title: "Collection created",
      });
      onClose();
    } catch (error) {
      handleApiError(error, "Error creating collection", {
        quota: () => {
          showLimitModal("planCollections");
          onClose();
        },
      });
    }
  }, [
    createCollectionTrigger,
    handleApiError,
    newName,
    onClose,
    selectedTeam.id,
    showLimitModal,
    successToast,
  ]);

  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: modalRef,
    handler: () => {
      onClose();
    },
  });

  return (
    <Modal
      initialFocusRef={nameInputRef}
      isOpen={isOpen && canCreateCollection}
      onClose={onClose}
      portalProps={{
        containerRef,
        appendToParentPortal: true,
      }}
      variant={"modern"}
    >
      <ModalContent
        ref={modalRef}
        w={"320px"}
        maxW={"320px"}
        p={0}
        containerProps={{
          width: "100%",
          height: "100%",
          borderRadius: "xl",
        }}
      >
        <ModalBody w={"100%"} p={4}>
          <VStack align={"start"} gap={2}>
            <Text size={"body.bold.lg"}>Create collection</Text>
            <ScenarioInput
              ref={nameInputRef}
              value={newName}
              setValue={setNewName}
              placeholder={"Name"}
              onEnter={createCollection}
            />
            <Button
              w={"100%"}
              isDisabled={!newName}
              onClick={createCollection}
              isLoading={isLoadingCreateCollection}
            >
              Create collection
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateCollectionModal;
