import { subscriptionsTags } from "domains/subscriptions/api/endpoints";
import { Endpoints } from "infra/store/apiSlice";

export const collectionsTags = {
  collection: "collection",
} as const;

export const collectionsEndpoints: Endpoints = {
  postCollection: {
    invalidatesTags: [collectionsTags.collection, subscriptionsTags.limits],
  },
  getCollections: { providesTags: [collectionsTags.collection] },
  deleteCollectionsByCollectionId: {
    invalidatesTags: [collectionsTags.collection, subscriptionsTags.limits],
  },
  putCollectionsByCollectionId: {
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: collectionsTags.collection,
          id: `collectionId:${arg.collectionId}`,
        },
      ];
    },
  },
  getCollectionsByCollectionId: {
    providesTags: (_result, _error, arg) => {
      return [
        {
          type: collectionsTags.collection,
          id: `collectionId:${arg.collectionId}`,
        },
      ];
    },
  },
};
