import { getModelThumbnail } from "domains/generators/utils";
import { PostSearchApiTransformedResponse } from "domains/search/interfaces/Search";
import { NsfwType } from "domains/user/constants/Nsfw";
import {
  GetAssetsApiResponse,
  GetAssetsByAssetIdApiResponse,
  GetInferencesApiResponse,
  GetModelsByModelIdApiResponse,
} from "infra/api/generated/api";
import _ from "lodash";

import { FilePreviewProps } from "./components/FilePreview";

export interface FileTypeBase {
  id: string;
  name: string;
  thumbnail: string;
  width: number;
  height: number;
}

export interface FileCanvasType extends FileTypeBase {
  type: "canvas";

  meta: GetAssetsByAssetIdApiResponse["asset"];
}

// This is to make sure we always create the thumbnail with the function getThumbnail that will add blur and compress, ...
export type FileImageThumbnail = string & {
  placeholder: any;
};

export interface FileImageType extends FileTypeBase {
  type: "image";
  thumbnail: FileImageThumbnail;
  status: "success" | "error" | "processing" | "queued" | "placeholder";

  meta: GetAssetsByAssetIdApiResponse["asset"];
}

export interface FileGeneratorType extends FileTypeBase {
  type: "generator";

  meta: GetModelsByModelIdApiResponse["model"];
}

export type Action<F> = {
  kind: ActionKind | ActionKind[];
  label: string;
  shortcut?: (e: KeyboardEvent) => boolean;
  onAction: (files: F[]) => void;
  withConfirmation?: boolean;
  confirmationMessage?: string;
  waitUntilDone?: boolean;
  Component: (props: { files: F[]; onAction: () => void }) => JSX.Element;
};

export type FileType = FileImageType | FileGeneratorType | FileCanvasType;

/**
 * Each action can be of a different kind, and each kind has a different UI
 * selectionBar: the action will be shown in the selection bar
 * quickAction: the action will be shown in the quick action menu on the file preview
 */
export type ActionKind = "selectionBar" | "quickAction";

export type FileHandler<F extends FileType> = {
  onOpen?: (file: F) => void;

  icon?: JSX.Element;

  actions?: Action<F>[];

  /** Component to be rendered when there are no files to show */
  EmptyState?: JSX.Element;

  /** Component to display the file, alway prefer wrapping the domains/file-manager/components/FilePreview by adding logic above it like this
   * @example
   * const CustomFilePreview = (props: FilePreviewProps) => {
   *
   * const [customLogic, setCustomLogic] = useState(false)
   *
   * ...
   *
   * return (
   * <FilePreview {...props} isLoading={customLogic}>
   * )
   * }
   * */
  FilePreview?: (props: FilePreviewProps<F>) => React.ReactElement;

  // TODO: Add more events, not used for now
  // onShare?: () => void;
  // onContextMenu?: () => void;
  // onMove?: () => void;
  // onDisabled?: () => void;
  // onDragStart?: () => void;
  // onDragEnd?: () => void;
  // onRename?: () => void;
  // onCopy?: () => void;
  // onCut?: () => void;
  // onPaste?: () => void;
  // onDownload?: () => void;
  // onUpload?: () => void;
};

export const mapGeneratorsToFiles = (
  generators: GetModelsByModelIdApiResponse["model"][]
): FileGeneratorType[] =>
  generators.map((generator) => ({
    type: "generator",
    id: generator.id,
    name: generator.name || "Untitled",
    thumbnail: getModelThumbnail(generator),
    width: 512,
    height: 512,
    meta: {
      ...generator,
      // don't trust api.ts, sometimes type is not there
      type: generator.type ?? "sd-1_5",
    },
  }));

export const getImageThumbnail = ({
  nsfwFilteredTypes,
  url,
  nsfw,
  type,
}: {
  nsfwFilteredTypes: NsfwType[];
  url: string;
  nsfw: string[];
  type: string;
}): FileImageThumbnail => {
  if (!url) {
    return "" as FileImageThumbnail;
  }
  let thumbnail = url + "&quality=80";
  if (type !== "background-removal") {
    thumbnail += "&format=jpeg";
  }
  if (
    nsfw &&
    nsfwFilteredTypes &&
    _.intersection(nsfwFilteredTypes, nsfw).length > 0
  ) {
    thumbnail += "&blur=100";
  }
  return thumbnail as FileImageThumbnail;
};

export const mapSearchResultsToImagesFiles = (
  images: PostSearchApiTransformedResponse["results"],
  nsfwFilteredTypes: NsfwType[]
): FileImageType[] => {
  return images.map((image) => {
    return {
      type: "image",
      id: image.id,
      name: image.prompt ?? "",
      thumbnail: getImageThumbnail({
        nsfwFilteredTypes,
        url: image.thumbnailUrl ?? "",
        nsfw: image.nsfw ?? [],
        type: image.assetType ?? "",
      }),
      width: image.width ?? 512,
      height: image.height ?? 512,
      status: "success",
      meta: {
        createdAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
        metadata: {
          kind: "image",
          type: image.assetType ?? "inference-img2img",
          negativePrompt: image.negativePrompt,
          name: image.name,
          prompt: image.prompt,
        },
        description: image.captioning,
        url: image.thumbnailUrl ?? "",
        tags: image.tags,
        updatedAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
        collectionIds: image.collectionIds ?? [],
        privacy: "private",
        id: image.id,
        mimeType: "",
        authorId: "",
        ownerId: "",
        status: "success",
        nsfw: image.nsfw,
      },
    };
  });
};

export const mapSearchResultsToGeneratorFiles = (
  generators: PostSearchApiTransformedResponse["results"]
): FileGeneratorType[] => {
  return generators.map((generator) => ({
    id: generator.id,
    name: generator.name || "Untitled",
    type: "generator",
    thumbnail: getModelThumbnail({
      ...(generator.thumbnailUrl
        ? {
            thumbnail: {
              url: generator.thumbnailUrl,
            },
          }
        : {}),
      trainingImages: generator.trainingImages ?? undefined,
    } as any),
    width: 512,
    height: 512,
    meta: {
      id: generator.id,
      type: "sd-1_5", // Should be replaced with search generator type
      createdAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
      name: generator.name ?? "",
      tags: [],
      privacy: "public",
      status: "trained",
      trainingImages: generator.trainingImages ?? undefined,
      trainingImagesNumber: generator.trainingImages?.length ?? 0,
      collectionIds: generator.collectionIds ?? [],
      updatedAt: new Date("2000-01-01 00:00:00").toString(), // NOTE: this is a placeholder, it's not used.
    },
  }));
};

export const mapAssetsToImagesFiles = (
  assets: GetAssetsApiResponse["assets"],
  nsfwFilteredTypes: NsfwType[]
): FileImageType[] => {
  return assets.map((asset) => {
    return {
      id: asset.id,
      name: asset.metadata.prompt ?? "",
      type: "image",
      thumbnail: getImageThumbnail({
        nsfwFilteredTypes,
        url: asset.url,
        nsfw: asset.nsfw ?? [],
        type: asset.metadata.type,
      }),
      width: asset.metadata.width ?? 512,
      height: asset.metadata.height ?? 512,
      meta: asset,
      status: asset.status === "pending" ? "processing" : asset.status,
    };
  });
};

export const mapInferencesToImagesFiles = (
  inferences: GetInferencesApiResponse["inferences"]
): FileImageType[] => {
  const files: FileImageType[] = [];
  const inferenceStatusToAssetStatus: {
    [key: string]: FileImageType["status"];
  } = {
    succeeded: "success",
    failed: "error",
    queued: "queued",
  };

  for (const inference of inferences) {
    for (const image of inference.images) {
      files.push({
        type: "image",
        id: image.id,
        name: inference.displayPrompt,
        thumbnail: getImageThumbnail({
          nsfwFilteredTypes: [],
          url: image.url,
          nsfw: [],
          type: "inference-txt2img",
        }),
        width: inference.parameters.width ?? 512,
        height: inference.parameters.height ?? 512,
        status: inferenceStatusToAssetStatus[inference.status] ?? "processing",
        meta: {
          createdAt: new Date(inference.createdAt).toString(),
          metadata: {
            kind: "image",
            type: "inference-txt2img",
            negativePrompt: inference.parameters.negativePrompt,
            name: inference.displayPrompt,
            prompt: inference.parameters.prompt,
            seed: image.seed,
            inferenceId: inference.id,
            modelId: inference.modelId,
            width: inference.parameters.width ?? 512,
            height: inference.parameters.height ?? 512,
          },
          description: "",
          url: image.url,
          tags: [],
          updatedAt: new Date(inference.createdAt).toString(),
          collectionIds: [],
          privacy: "private",
          id: image.id,
          mimeType: "",
          authorId: "",
          ownerId: "",
          status: (() => {
            if (inference.status === "succeeded") {
              return "success";
            } else if (inference.status === "failed") {
              return "error";
            } else {
              return "pending";
            }
          })(),
        },
      });
    }
  }

  return files;
};

export const mapAssetsToCanvasFiles = (
  assets: GetAssetsApiResponse["assets"],
  nsfwFilteredTypes: NsfwType[]
): FileCanvasType[] =>
  assets.map((asset) => {
    return {
      type: "canvas",
      id: asset.id,
      name: asset.metadata.name || "Untitled",
      thumbnail: getImageThumbnail({
        nsfwFilteredTypes,
        url: asset.metadata.thumbnail?.url ?? "",
        nsfw: asset.nsfw ?? [],
        type: asset.metadata.type,
      }),
      width: 512,
      height: 512,
      meta: asset,
    };
  });
