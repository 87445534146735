import Link from "next/link";
import isBreakpointMobile from "domains/commons/isMobile";
import { useSideBarWidth } from "domains/navigation/components/SideMenu";
import TopBarSearch from "domains/navigation/components/TopBar/TopBarSearch";
import { useSidebarContext } from "domains/navigation/contexts/SidebarProvider";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { extraTheme } from "domains/theme";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { FormattedMessage } from "react-intl";

import { Flex, IconButton, useBreakpoint } from "@chakra-ui/react";

import HelpButtonDropdown from "./HelpButtonDropdown";
import LinkButtonDropdown from "./LinkButtonDropdown";
import WorkspaceButtonDropdown from "./WorkspaceButtonDropdown";

export const TOP_BAR_HEIGHT = 56;

export default function TopBar() {
  const { selectedTeam } = useTeamContext();
  const { isOpen, open, close } = useSidebarContext();
  const breakpoint = useBreakpoint({ ssr: true });
  const isMobile = isBreakpointMobile(breakpoint);
  const sideBarWidth = useSideBarWidth();

  const canUpgrade =
    selectedTeam.plan === "free" && selectedTeam.userRole === "admin";

  return (
    <Flex
      pos="fixed"
      zIndex="banner"
      align="center"
      justify="space-between"
      gap={14}
      w={isMobile ? "100%" : `calc(100% - ${sideBarWidth})`}
      h={`${TOP_BAR_HEIGHT}px`}
      px={4}
      borderBottomWidth="1px"
      borderBottomColor="border.500"
      transition={extraTheme.transitions.fast}
      bgColor="black.500"
    >
      {/* Left side */}
      <Flex w={"100%"}>
        {isMobile && (
          <IconButton
            aria-label={"Open menu"}
            onClick={() => {
              if (isOpen) {
                close();
              } else {
                open();
              }
            }}
            size={"md"}
            variant="unstyled"
          >
            <Icon id="Layout/MobileMenu" />
          </IconButton>
        )}
        {!isMobile && <TopBarSearch />}
      </Flex>

      {/* Right side */}
      <Flex align="center" gap={4}>
        {canUpgrade && (
          <Link href={"/team?tab=plans" as "/team"}>
            <Button
              variant="primary"
              data-testid="topbar-plans-button"
              size="xs"
              onClick={() => Track(AnalyticsEvents.Navigation.ClickedUpgrade)}
            >
              <FormattedMessage
                id="layout.topbar.upgrade"
                defaultMessage="Upgrade Plan"
              />
            </Button>
          </Link>
        )}

        <HelpButtonDropdown />
        <LinkButtonDropdown />
        <WorkspaceButtonDropdown />
      </Flex>
    </Flex>
  );
}
