import { useRef } from "react";
import Button from "domains/ui/components/Button";

import {
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export interface DecisionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;

  colorScheme?: ButtonProps["colorScheme"];
  body: React.ReactNode | string;
  headerMessage?: React.ReactNode | string;
  confirmMessage?: React.ReactNode | string;
  showCancelButton?: boolean;
  cancelMessage?: React.ReactNode | string;
  isConfirmButtonDisabled?: boolean;
  isConfirmButtonLoading?: boolean;
}

/**
 * Modal to confirm a decision
 *
 * @link <url to figma if exist>
 * @link <url to storybook if exist>
 */
const DecisionModal = ({
  isOpen,
  onClose,
  onConfirm,
  colorScheme,

  headerMessage = "Are you sure ?",
  showCancelButton = true,
  cancelMessage = "Cancel",
  confirmMessage = "Confirm",
  isConfirmButtonDisabled = false,
  isConfirmButtonLoading = false,
  body,
}: DecisionModalProps) => {
  const confirmRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal
      finalFocusRef={document.body as any}
      initialFocusRef={confirmRef}
      isOpen={isOpen}
      onClose={onClose}
      variant={"modern"}
    >
      <ModalOverlay />
      <ModalContent
        zIndex="skipLink"
        containerProps={
          {
            "data-outside-click-excluded": true,
          } as any
        }
        data-outside-click-excluded={true}
      >
        <ModalCloseButton />
        <ModalHeader data-testid="decision-modal-header">
          {headerMessage}
        </ModalHeader>

        <ModalBody data-testid="decision-modal-body">{body}</ModalBody>

        <ModalFooter>
          {showCancelButton && (
            <Button
              onClick={onClose}
              variant={"secondary"}
              data-testid="decision-modal-cancel-button"
            >
              {cancelMessage}
            </Button>
          )}
          <Button
            ref={confirmRef}
            colorScheme={colorScheme}
            onClick={onConfirm}
            variant={"primary"}
            isDisabled={isConfirmButtonDisabled}
            isLoading={isConfirmButtonLoading}
            data-testid="decision-modal-confirm-button"
            _focus={{
              boxShadow: "var(--chakra-shadows-outline)",
            }}
          >
            {confirmMessage}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DecisionModal;
