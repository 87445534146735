import { checkboxAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

// default base style from the Input theme
const baseStyle = definePartsStyle({
  control: defineStyle({
    _readOnly: {
      cursor: "default",
    },
    _checked: {
      bg: "primary.500",
      color: "textPrimary",
      borderWidth: 0,
      _hover: {
        bg: "primary.400",
        _readOnly: {
          bg: "primary.500",
        },
      },
    },
  }),
  container: defineStyle({
    boxShadow: "sm",
    _focus: {
      boxShadow: "outline",
    },
  }),
});

// Defining a custom variant called withBackground
const withBackground = definePartsStyle({
  container: {
    bg: "gray.50",
    padding: 2,
    borderRadius: "10",
    _hover: {
      bg: "gray.100",
    },
    _active: {
      bg: "gray.200",
    },
    _checked: {
      bg: "gray.200",
      color: "blue.500",
    },
    _focus: {
      boxShadow: "outline",
      border: "none",
    },
    _disabled: {
      bg: "gray.50",
      _hover: {
        // hover should not be applied when disabled
        bg: "gray.50",
      },
    },
  },
});

const variantCircular = definePartsStyle({
  control: defineStyle({
    rounded: "full",
    borderColor: "border.500",
    _checked: {
      bg: "primary.500",
      color: "white.500",
      borderColor: "white.500",
    },
  }),
  container: defineStyle({
    boxShadow: 0,
    p: 0.5,
  }),
});

const variantLightCircular = definePartsStyle({
  control: defineStyle({
    borderColor: "white.500",
    rounded: "full",
    _checked: {
      bg: "primary.500",
      color: "white.500",
      borderColor: "white.500",
    },
  }),
  container: defineStyle({
    boxShadow: 0,
    p: 0.5,
  }),
});

const variantDefault = definePartsStyle({
  control: defineStyle({
    borderColor: "white.500",
    _checked: {
      bg: "primary.500",
      color: "white.500",
      borderColor: "white.500",
    },
  }),
  container: defineStyle({
    boxShadow: 0,
    p: 0.5,
  }),
});

const variants = {
  withBackground,
  default: variantDefault,
  circular: variantCircular,
  lightCircular: variantLightCircular,
};

const sizes = {
  xl: definePartsStyle({
    control: defineStyle({
      boxSize: 6,
    }),
    icon: defineStyle({
      width: 2,
      height: 2,
    }),
  }),
};

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
});
