import { Dispatch, SetStateAction, useCallback } from "react";
import Button from "domains/ui/components/Button";
import { OnboardingForm } from "domains/user/interfaces/OnboardingForm";

import { Box, Checkbox, Heading, VStack } from "@chakra-ui/react";

const SOURCE_IDS = [
  "search",
  "socialMedia",
  "wordOfMouth",
  "event",
  "directOutreach",
  "invitation",
] as const;

export type SourceId = (typeof SOURCE_IDS)[number];

type Source = {
  label: string;
  custom?: boolean;
};

const SOURCE_HASH: { [key in SourceId]: Source } = {
  search: {
    label: "Search Engine",
  },
  socialMedia: {
    label: "Social Media / Blogs",
  },
  wordOfMouth: {
    label: "Word of Mouth",
  },
  event: {
    label: "Event / Conference",
  },
  directOutreach: {
    label: "Direct Outreach",
  },
  invitation: {
    label: "Invitation",
  },
};

const SOURCES = SOURCE_IDS.map((id) => ({ ...SOURCE_HASH[id], id }));

type SectionSourceProps = {
  form: OnboardingForm;
  setForm: Dispatch<SetStateAction<OnboardingForm>>;
};

export default function SectionSource({ form, setForm }: SectionSourceProps) {
  const handleOptionClick = useCallback(
    (sourceId: SourceId) => {
      setForm({ ...form, source: sourceId });
    },
    [setForm, form]
  );

  return (
    <VStack align="stretch" w="100%" spacing={4}>
      <Heading pb={1} color="textPrimary" size="sm">
        {"How did you hear about Scenario?"}
      </Heading>

      <VStack align="stretch" spacing={1}>
        {SOURCES.map((source) => {
          const isChecked = form.source === source.id;
          return (
            <Button
              key={source.id}
              variant="inputSelect"
              size="lg"
              fontSize={["14px", "16px"]}
              data-testid={source.id}
              leftIcon={<Checkbox isChecked={isChecked} variant="circular" />}
              onClick={() => handleOptionClick(source.id)}
              colorScheme={isChecked ? "secondary" : undefined}
            >
              <Box as="span" overflow="hidden" textOverflow="ellipsis">
                {source.label}
              </Box>
            </Button>
          );
        })}
      </VStack>
    </VStack>
  );
}
