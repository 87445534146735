import { useCallback, useMemo, useState } from "react";
import { MAX_BASE64_UPLOAD_SIZE } from "domains/assets/constants/upload";
import { getBase64Size } from "domains/assets/utils/getFileSize";
import { resizeImageForUpload } from "domains/assets/utils/resizeImage";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import loadBase64ImageFromUrl from "domains/image/methods/loadBase64ImageFromUrl";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import SearchBarFileUpload from "domains/search/components/SearchBar/fileUpload";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  useGetAssetsByAssetIdQuery,
  usePostAssetMutation,
} from "infra/api/generated/api";
import ImageUploading, { ImageListType } from "react-images-uploading";

import { Box, Flex, Image, Spinner, Text, Tooltip } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export interface SearchQueryData {
  query: string;
  assetId: string | undefined;
}

interface SearchBarProps {
  query: string;
  setQuery: (query: string) => void;
  assetId: string | undefined;
  setAssetId: (assetId: string | undefined) => void;
  onSearch: (searchQueryData: SearchQueryData) => void;
}

export default function SearchBar({
  query,
  setQuery,
  assetId,
  setAssetId,
  onSearch,
}: SearchBarProps) {
  const { errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { selectedTeam } = useTeamContext();
  const [postAssetTrigger] = usePostAssetMutation();
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [inputHasFocus, setInputHasFocus] = useState(false);

  const callOnSearch = useCallback(
    (searchQueryData: SearchQueryData) => {
      if (searchQueryData.query || searchQueryData.assetId) {
        Track(AnalyticsEvents.Search.Searched, searchQueryData);
      }
      onSearch(searchQueryData);
    },
    [onSearch]
  );

  const clearAll = useCallback(() => {
    setQuery("");
    setAssetId(undefined);
    onSearch({
      query: "",
      assetId: undefined,
    });
  }, [setAssetId, setQuery, onSearch]);

  const assetData = useGetAssetsByAssetIdQuery(
    assetId
      ? {
          teamId: selectedTeam.id,
          assetId: assetId,
        }
      : skipToken
  );

  const assetUrl = useMemo(() => {
    if (!assetId || assetId !== assetData.data?.asset.id) {
      return undefined;
    }
    return assetData.data?.asset.url;
  }, [assetId, assetData]);

  const handleImageUpload = useCallback(
    async (imageData: string) => {
      setIsUploadLoading(true);

      const imageBase64Size = getBase64Size(imageData);
      Track(AnalyticsEvents.Search.ImportedImage, {
        size: imageBase64Size,
        resize: imageBase64Size > MAX_BASE64_UPLOAD_SIZE,
      });

      const resizedImage = await resizeImageForUpload(imageData);
      if (!resizedImage) {
        errorToast({
          title: "Error importing image",
          description:
            "There was an error importing the image. Please try again.",
        });
        return;
      }

      try {
        const response = await postAssetTrigger({
          teamId: selectedTeam.id,
          body: {
            image: resizedImage.data,
            name: "search-image",
          },
        }).unwrap();
        setAssetId(response.asset.id);
        callOnSearch({
          query,
          assetId: response.asset.id,
        });
      } catch (error) {
        handleApiError(error, "Error importing image", {
          file_size: () => {
            errorToast({
              title: "Error importing image",
              description: "The image file size is too big.",
            });
          },
        });
      } finally {
        setIsUploadLoading(false);
      }
    },
    [
      postAssetTrigger,
      selectedTeam.id,
      callOnSearch,
      errorToast,
      handleApiError,
      setAssetId,
      query,
    ]
  );

  const { isDraggingHover, dragFunctions, onDrop } = useImageUploadDragDrop({
    onImageDrop: async (imageUrl) => {
      const base64 = await loadBase64ImageFromUrl(imageUrl);
      if (base64) {
        void handleImageUpload(base64);
      } else {
        errorToast({
          title: "Error importing image",
        });
      }
    },
  });

  const includesOperator = !!query.toLowerCase().match(/\b(and|or|not)\b/);

  return (
    <ImageUploading
      value={[]}
      onChange={async (imageList: ImageListType) => {
        if (imageList.length === 0) {
          return;
        }

        const dataUrl = imageList[0].dataURL;
        if (dataUrl === undefined) {
          return;
        }

        void handleImageUpload(dataUrl);
      }}
      allowNonImageType={false}
      acceptType={["jpeg", "jpg", "png", "webp"]}
      maxNumber={1}
      multiple={false}
    >
      {({ dragProps: { onDrop: dragPropsOnDrop } }) => {
        return (
          <Tooltip
            hasArrow
            isOpen={inputHasFocus && includesOperator}
            label='"AND", "OR", "NOT" (in caps) are search functions'
            offset={[20, 7]}
            placement="bottom-start"
          >
            <Box
              {...dragFunctions}
              w={"100%"}
              onDrop={(e) => onDrop(e, dragPropsOnDrop)}
            >
              <ScenarioInput
                inputProps={{
                  onFocus: () => {
                    setInputHasFocus(true);
                  },
                  onBlur: () => {
                    setInputHasFocus(false);
                  },
                }}
                onEnter={() => {
                  callOnSearch({
                    query,
                    assetId,
                  });
                }}
                value={query}
                setValue={setQuery}
                placeholder={"Search"}
                leftComponent={
                  <Flex align={"center"} gap={2}>
                    <Icon mb={0.5} id={"Ui/Search"} color={"textTertiary"} />
                    {assetId && (
                      <Flex
                        align={"center"}
                        gap={2}
                        w={"max-content"}
                        h={"32px"}
                        p={1}
                        borderRadius={"base"}
                        bgColor={"whiteAlpha.100"}
                      >
                        {assetUrl ? (
                          <Image
                            maxW={"24px"}
                            maxH={"24px"}
                            borderWidth={1}
                            borderRadius={"sm"}
                            alt={"image search"}
                            src={assetUrl}
                          />
                        ) : (
                          <Spinner w={"20px"} h={"20px"} color={"white"} />
                        )}
                        <Text size={"body.md"}>Image Search</Text>
                        <Button
                          variant={"ghost"}
                          h={"28px"}
                          w={"28px"}
                          minW={"28px"}
                          colorScheme={"white"}
                          onClick={() => {
                            setAssetId(undefined);
                            callOnSearch({
                              query,
                              assetId: undefined,
                            });
                          }}
                        >
                          <Icon id={"Ui/Cross"} color={"white"} h={"12px"} />
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                }
                rightComponent={
                  <Flex align={"center"} gap={2}>
                    {assetId || query ? (
                      <>
                        <Button
                          px={1}
                          h={"28px"}
                          variant="ghost"
                          onClick={clearAll}
                          colorScheme={"white"}
                          size={"xs"}
                        >
                          <Text color={"textSecondary"}>Clear</Text>
                        </Button>
                        <Divider
                          dividerProps={{
                            w: "1px",
                            h: "20px",
                          }}
                        />
                      </>
                    ) : null}
                    <SearchBarFileUpload
                      onImageUpload={handleImageUpload}
                      isUploadLoading={isUploadLoading}
                    />
                  </Flex>
                }
                bgColor={"backgroundTertiary.500"}
                borderColor={isDraggingHover ? "primary.500" : undefined}
              />
            </Box>
          </Tooltip>
        );
      }}
    </ImageUploading>
  );
}
