import { ReactNode, useCallback, useMemo, useState } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import Button from "domains/ui/components/Button";
import defaultColorPalettes, {
  ColorPalette,
} from "domains/ui/components/ColorPicker/defaultColorPalettes";
import Icon from "domains/ui/components/Icon";
import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "domains/ui/components/Tabs";
import { useUser } from "domains/user/hooks/useUser";
import { useHandleApiError } from "infra/api/error";

import {
  Box,
  Flex,
  Grid,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

const MODAL_ID = "pixelateSelectColorPalette";

const ColorPaletteThumbnail = ({
  colorPalette,
}: {
  colorPalette: ColorPalette;
}) => {
  const colors = colorPalette.colors;
  const lineWidth = 100 / colors.length;
  return (
    <>
      {colors.map((color, index) => {
        return (
          <Box
            key={`color-palette-${color}-line-${index}`}
            w={`${lineWidth}%`}
            h={"100%"}
            bgColor={color}
          />
        );
      })}
    </>
  );
};

const ColorPaletteSelectorTab = ({
  colorPalettes,
  handleColorPaletteClick,
  additionalChildren,
  showNames,
}: {
  colorPalettes: ColorPalette[];
  handleColorPaletteClick: (colorPalette: ColorPalette | undefined) => void;
  additionalChildren?: ReactNode[];
  showNames?: boolean;
}) => {
  return (
    <Grid
      justifyContent={"space-between"}
      rowGap={5}
      columnGap={3}
      templateColumns={["repeat(auto-fill, 200px)"]}
    >
      {additionalChildren && additionalChildren.map((child) => child)}
      {colorPalettes.map((colorPalette) => {
        return (
          <VStack
            key={`color-palette-${colorPalette.id}`}
            cursor={"pointer"}
            onClick={() => handleColorPaletteClick(colorPalette)}
            spacing={0}
          >
            <HStack w={"200px"} h={"200px"} spacing={0}>
              <ColorPaletteThumbnail colorPalette={colorPalette} />
            </HStack>
            {showNames && (
              <Flex align={"center"} justify={"center"} w={"100%"} h={"40px"}>
                <Text
                  overflow={"hidden"}
                  w={"100%"}
                  textColor={"textPrimary"}
                  textOverflow={"ellipsis"}
                  size={"body.sm"}
                >
                  {colorPalette.name}
                </Text>
              </Flex>
            )}
          </VStack>
        );
      })}
    </Grid>
  );
};

const ColorPaletteSelector = ({
  imgSrc,
  setSelectedColorPalette,
  isOpen,
  setIsOpen,
  setShouldUseNewColorPalette,
  savedColorPalettes,
}: {
  imgSrc: string | undefined;
  setSelectedColorPalette: (colorPalette: ColorPalette | undefined) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setShouldUseNewColorPalette: (value: boolean) => void;
  savedColorPalettes: ColorPalette[];
}) => {
  const handleColorPaletteClick = (colorPalette: ColorPalette | undefined) => {
    setSelectedColorPalette(colorPalette);
    setIsOpen(false);
    setShouldUseNewColorPalette(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} variant={"modern"}>
      <ModalOverlay />
      <ModalContent w={"90vw"} maxW={"unset"} h={"80vh"} p={0}>
        <ModalCloseButton />
        <ModalBody w={"100%"} id={MODAL_ID}>
          <Flex direction={"column"} w={"100%"} pb={3}>
            <Text
              px={5}
              py={5}
              textAlign={"left"}
              borderBottomWidth={1}
              size={"body.bold.lg"}
            >
              Choose color palette
            </Text>
            <Box pt={4} px={5}>
              <Tabs w="100%">
                <TabList>
                  <Tab>Custom</Tab>
                  <Tab>Signature</Tab>
                </TabList>
                <TabIndicator />
                <TabPanels mt={5}>
                  <TabPanel>
                    <ColorPaletteSelectorTab
                      colorPalettes={savedColorPalettes}
                      handleColorPaletteClick={handleColorPaletteClick}
                      additionalChildren={[
                        <Box
                          key={"color-palette-none"}
                          w={"200px"}
                          h={"200px"}
                          cursor={"pointer"}
                          onClick={() => handleColorPaletteClick(undefined)}
                        >
                          <Box
                            w={"100%"}
                            h={"100%"}
                            bgColor="backgroundQuaternary.500"
                          >
                            {imgSrc && (
                              <Image
                                w={"100%"}
                                h={"100%"}
                                objectFit={"contain"}
                                alt={"image"}
                                src={imgSrc}
                              />
                            )}
                          </Box>
                        </Box>,
                      ]}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ColorPaletteSelectorTab
                      colorPalettes={defaultColorPalettes}
                      handleColorPaletteClick={handleColorPaletteClick}
                      showNames={true}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export interface PixelateSelectColorPaletteProps {
  imgSrc: string | undefined;
  selectedColorPalette: ColorPalette | undefined;
  setSelectedColorPalette: (value: ColorPalette | undefined) => void;
  shouldUseNewColorPalette: boolean;
  setShouldUseNewColorPalette: (value: boolean) => void;
  newColorPalette: ColorPalette;
  setNewColorPalette: (value: ColorPalette) => void;
}

const PixelateSelectColorPalette = ({
  imgSrc,
  selectedColorPalette,
  setSelectedColorPalette,
  shouldUseNewColorPalette,
  setShouldUseNewColorPalette,
  newColorPalette,
  setNewColorPalette,
}: PixelateSelectColorPaletteProps) => {
  const { successToast } = useScenarioToast();
  const [isOpen, setIsOpen] = useState(false);
  const handleApiError = useHandleApiError();
  const { updateUserSettings, userSettings } = useUser();

  const savedColors: string[][] = useMemo(() => {
    return userSettings["color-palettes"] ?? [];
  }, [userSettings]);

  const savedColorPalettes = useMemo(() => {
    if (!savedColors) {
      return [];
    }
    return savedColors.map((colors) => {
      return {
        id: Math.random().toString(),
        name: "Custom palette",
        colors,
      };
    });
  }, [savedColors]);

  const saveColorPalette = useCallback(async () => {
    try {
      const newColorPalettes: string[][] = [
        ...savedColorPalettes.map(
          (savedColorPalette) => savedColorPalette.colors
        ),
        newColorPalette.colors,
      ];
      await updateUserSettings({
        "color-palettes": newColorPalettes,
      });
      successToast({
        title: "Your color palette was saved",
      });
      setSelectedColorPalette(newColorPalette);
      setShouldUseNewColorPalette(false);
      setNewColorPalette({
        ...newColorPalette,
        colors: [],
      });
    } catch (error) {
      handleApiError(error, "There was an error saving your color palette");
    }
  }, [
    updateUserSettings,
    savedColorPalettes,
    newColorPalette,
    successToast,
    setSelectedColorPalette,
    setShouldUseNewColorPalette,
    setNewColorPalette,
    handleApiError,
  ]);

  return (
    <Box w={"100%"}>
      <Button
        variant={"secondary"}
        size={"lg"}
        onClick={() => setIsOpen(true)}
        px={2}
        w={"100%"}
      >
        <Flex align={"center"} justify={"start"} gap={3} w={"100%"}>
          {selectedColorPalette || shouldUseNewColorPalette ? (
            <HStack w={"30px"} h={"30px"} spacing={0}>
              <ColorPaletteThumbnail
                colorPalette={
                  shouldUseNewColorPalette
                    ? newColorPalette
                    : selectedColorPalette!
                }
              />
            </HStack>
          ) : (
            <Box w={"30px"} h={"30px"} bgColor="backgroundQuaternary.500">
              {imgSrc && (
                <Image
                  w={"100%"}
                  h={"100%"}
                  objectFit={"contain"}
                  alt={"image"}
                  src={imgSrc}
                />
              )}
            </Box>
          )}
          <Text
            flex={1}
            overflow={"hidden"}
            textAlign={"start"}
            textOverflow={"ellipsis"}
            size={"body.bold.md"}
          >
            {selectedColorPalette || shouldUseNewColorPalette
              ? (shouldUseNewColorPalette
                  ? newColorPalette
                  : selectedColorPalette!
                ).name
              : "Image palette"}
          </Text>
          {shouldUseNewColorPalette && newColorPalette.colors.length > 0 && (
            <Button
              variant={"ghost"}
              colorScheme={"secondary"}
              onClick={(e) => {
                e.stopPropagation();
                void saveColorPalette();
              }}
              px="5px"
              leftIcon={<Icon color="white" id="Ui/Save" />}
            />
          )}
        </Flex>
      </Button>
      <ColorPaletteSelector
        imgSrc={imgSrc}
        setSelectedColorPalette={setSelectedColorPalette}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setShouldUseNewColorPalette={setShouldUseNewColorPalette}
        savedColorPalettes={savedColorPalettes}
      />
    </Box>
  );
};

export default PixelateSelectColorPalette;
