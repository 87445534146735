import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouter } from "next/router";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import { Team } from "domains/teams/interfaces/Team";
import { useUser } from "domains/user/hooks/useUser";
import { useGetTeamsQuery } from "infra/api/generated/api";
import _ from "lodash";

import { skipToken } from "@reduxjs/toolkit/dist/query";

const defaultTeam: Team = {
  id: "",
  name: "Private Workspace",
  avatar: { label: "p", color: "primary" },
  type: "personal",
  plan: "free",
  userRole: "admin",
  usersCount: 1,
  isFetching: true,
};

export let globalSelectedTeam: Team = defaultTeam;

interface TeamProviderProps {
  isCreateModalVisible: boolean;
  showCreateModal: () => void;
  hideCreateModal: () => void;
  teams: Team[];
  selectedTeam: Team;
  setSelectedTeam: (team: Team, withRedirect?: boolean) => void;
  selectPersonalTeam: () => void;
}

export const TeamContext = createContext<TeamProviderProps>({
  isCreateModalVisible: false,
  showCreateModal: () => {},
  hideCreateModal: () => {},
  teams: [],
  selectedTeam: defaultTeam,
  setSelectedTeam: () => {},
  selectPersonalTeam: () => {},
});

export function TeamProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const { user } = useUser();
  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);
  const { data } = useGetTeamsQuery(user ? undefined : skipToken);
  const [selectedTeam, setSelectedTeam] = usePersistedState<Team>(
    PersistedStateKey.SELECTED_TEAM,
    { defaultValue: defaultTeam }
  );
  const router = useRouter();

  const selectTeam = useCallback(
    (team: Team, withRedirect = true) => {
      if (selectedTeam.id === team.id) {
        return;
      }

      if (withRedirect) {
        window.location.href = "/?teamId=" + team.id;
      } else if (selectedTeam.id !== team.id) {
        router.query.teamId = team.id;
        void router.push({
          query: router.query,
        });
      }
    },
    [selectedTeam.id, router]
  );

  const teams: Team[] = useMemo(() => {
    let _teams: Team[] = data?.teams
      ? data?.teams.map((team) => ({
          id: team.id,
          name: team.name,
          type: team.type,
          plan: team.plan,
          userRole: team.context.userRole,
          usersCount: team.context.usersCount,
          avatar:
            team.type === "personal"
              ? { label: (user?.email || "p").charAt(0), color: "primary" }
              : { label: team.name, color: "random" },
          isFetching: false,
        }))
      : [];
    if (!_teams || !_teams.length) {
      return [];
    }

    let personalTeam = _teams.find((team) => team.type === "personal");

    if (!personalTeam) {
      throw new Error("Personal team not found");
    }

    personalTeam = {
      ...personalTeam,
      name: "Private Workspace",
    };

    _teams = _teams.filter((team) => team.type !== "personal");
    _teams = _teams.sort((a, b) => a.name.localeCompare(b.name));

    _teams.unshift(personalTeam);

    if (selectedTeam.id !== "") {
      const selectedTeamExists = _teams.find(
        (team) =>
          team.id.replace("%7C", "|") === selectedTeam.id.replace("%7C", "|")
      );
      if (!selectedTeamExists) {
        selectTeam(personalTeam);
      } else if (!_.isEqual(selectedTeam, selectedTeamExists)) {
        setSelectedTeam(selectedTeamExists);
      }
    } else {
      selectTeam(personalTeam, false);
    }

    return _teams;
  }, [data?.teams, selectTeam, selectedTeam, user?.email, setSelectedTeam]);

  // this watches for changes in the router query and updates the selected team accordingly
  useEffect(() => {
    if (router.query.teamId) {
      let newSelectedTeam = teams.find(
        (team) => team.id === router.query.teamId
      );
      if (!newSelectedTeam && !teams.length) {
        newSelectedTeam = {
          ...defaultTeam,
          id: router.query.teamId as string,
        };
      }
      if (newSelectedTeam && newSelectedTeam.id !== selectedTeam.id) {
        setSelectedTeam(newSelectedTeam);
      }
    }
  }, [router, selectedTeam.id, setSelectedTeam, teams]);

  const selectPersonalTeam = useCallback(() => {
    selectTeam(teams.find((team) => team.type === "personal")!);
  }, [selectTeam, teams]);

  // Update for global usage in queries
  useEffect(() => {
    globalSelectedTeam = selectedTeam;
  }, [selectedTeam]);

  useEffect(() => {
    const remoteTeam = teams.find((team) => team.id === selectedTeam.id);
    if (!remoteTeam || _.isEqual(selectedTeam, remoteTeam)) return;
    setSelectedTeam(remoteTeam);
  }, [teams, selectedTeam, setSelectedTeam]);

  return (
    <TeamContext.Provider
      value={{
        isCreateModalVisible: isCreateModalVisible,
        showCreateModal: () => setIsCreateModalVisible(true),
        hideCreateModal: () => setIsCreateModalVisible(false),
        teams: teams,
        selectedTeam: selectedTeam,
        setSelectedTeam: selectTeam,
        selectPersonalTeam: selectPersonalTeam,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
}

export function useTeamContext() {
  return useContext<TeamProviderProps>(TeamContext);
}
