import React from "react";
import ErrorBoundary from "domains/ui/components/ErrorBoundary";

import * as Sentry from "@sentry/react";

interface SentryErrorBoundaryProps {
  children: React.ReactNode;
}

export default function SentryErrorBoundary({
  children,
}: SentryErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={() => <ErrorBoundaryFallback />}
      beforeCapture={(scope) => {
        scope.setTag("error-boundary", "true");
        scope.setLevel("fatal");
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

const ErrorBoundaryFallback = () => {
  return <ErrorBoundary />;
};
