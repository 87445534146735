import { forwardRef, isValidElement } from "react";
import InternalLink, { LinkProps as InternalLinkProps } from "next/link";
import { FormattedMessage } from "react-intl";

// eslint-disable-next-line no-restricted-imports
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  Link,
  LinkProps,
  Tooltip,
} from "@chakra-ui/react";
import * as _chakra_ui_system from "@chakra-ui/system";

export interface ButtonProps extends ChakraButtonProps {
  internalLink?: InternalLinkProps["href"];
  externalLink?: LinkProps["href"];
  tooltip?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { externalLink, internalLink, children, tooltip, ...props },
  ref
) {
  const childrenIsString = typeof children === "string";
  const childrenIsFormattedMessage =
    isValidElement(children) && children.type === FormattedMessage;
  const content =
    childrenIsString || childrenIsFormattedMessage ? (
      <span>{children}</span>
    ) : (
      children
    );
  let buttonComponent = (
    <ChakraButton ref={ref} {...props}>
      {content}
    </ChakraButton>
  );

  if (externalLink) {
    buttonComponent = (
      <ChakraButton
        ref={ref}
        as={Link}
        href={externalLink}
        isExternal
        {...props}
      >
        {content}
      </ChakraButton>
    );
  } else if (internalLink) {
    buttonComponent = (
      <ChakraButton ref={ref} as={InternalLink} href={internalLink} {...props}>
        {content}
      </ChakraButton>
    );
  }
  if (tooltip) {
    buttonComponent = (
      <Tooltip label={tooltip} placement="auto">
        {buttonComponent}
      </Tooltip>
    );
  }
  return buttonComponent;
});

// Override default props types so it can accept `as` prop
export default Button as _chakra_ui_system.ComponentWithAs<
  "button",
  ButtonProps
>;
