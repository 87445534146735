export type GridSortKey = "createdAsc" | "createdDesc" | "nameAsc" | "nameDesc";
export interface GridSort {
  label: string;
  value: [string, string];
}
export type GridSortMap = { [key in GridSortKey]: GridSort };
export type GridSortValue = GridSortKey;

export const gridSorts: GridSortMap = {
  createdAsc: { label: "Oldest", value: ["createdAt", "asc"] },
  createdDesc: { label: "Newest", value: ["createdAt", "desc"] },
  nameAsc: { label: "Name (A-Z)", value: ["name", "asc"] },
  nameDesc: { label: "Name (Z-A)", value: ["name", "desc"] },
};
export const gridSortKeys = Object.keys(gridSorts) as GridSortKey[];
