import {
  PutImagesRemoveBackgroundApiArg,
  PutImagesRemoveBackgroundApiResponse,
} from "infra/api/generated/api";
import { Endpoints } from "infra/store/apiSlice";

export const imagesTags = {
  image: "image",
} as const;

export const imagesEndpoints: Endpoints = {
  putImagesRemoveBackground: {
    transformResponse: (
      response: PutImagesRemoveBackgroundApiResponse,
      _,
      args: PutImagesRemoveBackgroundApiArg
    ) => {
      return `data:image/${args.body.format};base64,${response}`;
    },
  },
};
