import React, { useCallback, useState } from "react";
import {
  ButtonsField,
  SwitchField,
} from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel/AssetZoomEditPanelCommons";
import PixelateSelectColorPalette from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel/AssetZoomPixelate/colorPalette";
import {
  PIXEL_GRID_SIZES,
  PixelateSettings,
} from "domains/assets/hooks/useAssetPixelate";
import Button from "domains/ui/components/Button";
import ColorPicker from "domains/ui/components/ColorPicker";
import { ColorPalette } from "domains/ui/components/ColorPicker/defaultColorPalettes";
import Icon from "domains/ui/components/Icon";
import SliderWithInputNumber from "domains/ui/components/SliderWithInputNumber";
import { Color } from "react-color";

import {
  Box,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";

const NewColorsField = ({
  newColorPalette,
  setNewColorPalette,
}: {
  newColorPalette: ColorPalette;
  setNewColorPalette: (newColorPalette: ColorPalette) => void;
}) => {
  const [newColor, setNewColor] = useState<Color | undefined>();

  const handleRemoveColor = useCallback(
    (index: number) => {
      const tmpColors = [...newColorPalette.colors];
      void tmpColors.splice(index, 1);
      setNewColorPalette({
        ...newColorPalette,
        colors: tmpColors,
      });
    },
    [newColorPalette, setNewColorPalette]
  );

  const handleSelectNewColor = useCallback(
    (color: string) => {
      setNewColorPalette({
        ...newColorPalette,
        colors: [...newColorPalette.colors, color],
      });
      setNewColor(undefined);
    },
    [newColorPalette, setNewColorPalette]
  );

  return (
    <HStack wrap={"wrap"} gap={1} spacing={0}>
      {newColorPalette.colors.map((color, index) => (
        <Box
          key={color + index}
          w={"28px"}
          h={"28px"}
          borderRadius={"md"}
          cursor={"pointer"}
          bgColor={color}
          onClick={() => handleRemoveColor(index)}
        />
      ))}
      <Popover placement="auto">
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                variant={"secondary"}
                leftIcon={<Icon id="Ui/Plus" />}
                h={"28px"}
                w={"28px"}
                minW={"unset"}
                p={0}
              />
            </PopoverTrigger>
            <PopoverContent borderWidth={0}>
              <ColorPicker
                withEditableInput={true}
                color={newColor}
                onChange={(color: any) => setNewColor(color.hsl)}
                onSubmit={(color: string) => {
                  handleSelectNewColor(color);
                  onClose();
                }}
              />
            </PopoverContent>
          </>
        )}
      </Popover>
    </HStack>
  );
};

export interface PixelateParamsProps extends PixelateSettings {
  image: string | undefined;
}

const PixelateParams = ({
  image,
  removeNoise,
  setRemoveNoise,
  removeBackground,
  setRemoveBackground,
  pixelGridSize,
  setPixelGridSize,
  shouldUseNewColorPalette,
  setShouldUseNewColorPalette,
  newColorPalette,
  setNewColorPalette,
  selectedColorPalette,
  setSelectedColorPalette,
  colorPaletteSize,
  setColorPaletteSize,
}: PixelateParamsProps) => {
  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <PixelateSelectColorPalette
        shouldUseNewColorPalette={shouldUseNewColorPalette}
        setShouldUseNewColorPalette={setShouldUseNewColorPalette}
        newColorPalette={newColorPalette}
        setNewColorPalette={setNewColorPalette}
        selectedColorPalette={selectedColorPalette}
        setSelectedColorPalette={setSelectedColorPalette}
        imgSrc={image}
      />
      <SwitchField
        title={"Custom color palette"}
        description={"Change colors of your images to match a new palette"}
        isChecked={shouldUseNewColorPalette}
        setIsChecked={setShouldUseNewColorPalette}
      />
      {shouldUseNewColorPalette && (
        <NewColorsField
          newColorPalette={newColorPalette}
          setNewColorPalette={setNewColorPalette}
        />
      )}
      {!shouldUseNewColorPalette && !selectedColorPalette && (
        <SliderWithInputNumber
          title="Color palette size"
          value={colorPaletteSize}
          setValue={(newValue: number | undefined) => {
            setColorPaletteSize(newValue === 0 ? undefined : newValue);
          }}
          min={2}
          max={256}
          steps={[0, 2, 4, 8, 16, 32, 64, 128, 256]}
          inputProps={{
            w: "50px",
          }}
          placeholder="Auto"
        />
      )}
      <ButtonsField
        title={"Pixel grid size"}
        options={PIXEL_GRID_SIZES}
        selectedOption={pixelGridSize}
        setSelectedOption={setPixelGridSize}
        textToAppend={"px"}
      />
      <SwitchField
        title={"Remove noise"}
        isChecked={removeNoise}
        setIsChecked={setRemoveNoise}
      />
      <SwitchField
        title={"Remove background"}
        isChecked={removeBackground}
        setIsChecked={setRemoveBackground}
      />
    </VStack>
  );
};

export default PixelateParams;
