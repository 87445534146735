import Link from "next/link";

import { Flex, Heading, Text, VStack } from "@chakra-ui/react";

export interface AssetsGalleryEmptyStateProps {
  modelId?: string;
}

const AssetsGalleryEmptyState = ({ modelId }: AssetsGalleryEmptyStateProps) => (
  <Flex data-testid="asset-gallery-empty-state">
    <VStack mt={4}>
      <Heading size="md">You haven&apos;t created any images yet</Heading>
      <Link
        href={
          modelId
            ? {
                pathname: "/images/new",
                query: {
                  generatorId: modelId,
                },
              }
            : "/images/new"
        }
      >
        <Text color="blue.500">Click here to generate a first batch</Text>
      </Link>
    </VStack>
  </Flex>
);

export default AssetsGalleryEmptyState;
