import React from "react";
import Button from "domains/ui/components/Button";

import { HStack, Switch, Text, VStack } from "@chakra-ui/react";

export const SwitchField = ({
  title,
  description,
  isChecked,
  setIsChecked,
}: {
  title: string;
  description?: string;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
}) => {
  return (
    <HStack
      justify={"space-between"}
      w={"100%"}
      cursor="pointer"
      onClick={() => setIsChecked(!isChecked)}
    >
      <VStack align={"start"} spacing={2}>
        <Text size={"body.md"}>{title}</Text>
        {description && (
          <Text textColor={"textSecondary"} size={"body.sm"}>
            {description}
          </Text>
        )}
      </VStack>
      <Switch pointerEvents={"none"} isChecked={isChecked} />
    </HStack>
  );
};

export const ButtonsField = ({
  title,
  options,
  selectedOption,
  setSelectedOption,
  textToAppend,
}: {
  title: string;
  options: number[];
  selectedOption: number;
  setSelectedOption: (selectedOption: number) => void;
  textToAppend?: string;
}) => {
  return (
    <VStack align={"start"} w={"100%"} spacing={3}>
      <Text size={"body.md"}>{title}</Text>
      <HStack justify={"space-between"} w={"100%"} spacing={2}>
        {options.map((option) => (
          <Button
            key={option}
            onClick={() => setSelectedOption(option)}
            variant={"secondary"}
            size={"sm"}
            w={"100%"}
            isActive={selectedOption === option}
            textColor={"textSecondary"}
            _active={{
              textColor: "textPrimary",
              borderWidth: 2,
              borderColor: "primary.500",
            }}
          >
            {option}
            {textToAppend}
          </Button>
        ))}
      </HStack>
    </VStack>
  );
};
