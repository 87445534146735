import { useCallback, useMemo } from "react";
import { useAuth0 } from "domains/auth/components/AuthProvider";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { NSFW_TYPES, NsfwType } from "domains/user/constants/Nsfw";
import { OnboardingForm } from "domains/user/interfaces/OnboardingForm";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import { useGetMeQuery, usePutMeMutation } from "infra/api/generated/api";
import _ from "lodash";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export function useUser() {
  const { successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const [putMeTrigger] = usePutMeMutation();

  const { user: auth0User, ...auth0Props } = useAuth0();

  const { user } = useGetMeQuery(auth0User ? undefined : skipToken, {
    selectFromResult: ({ data }) => {
      return {
        user: data?.user,
      };
    },
  });

  const featureFlags = user?.features ?? [];
  const settings = useMemo(
    (): {
      [key: string]: any;
    } => user?.settings ?? {},
    [user?.settings]
  );

  const updateUserSettings = useCallback(
    async (newSettings: { [key: string]: any }) => {
      if (!user) {
        throw new Error();
      }
      await putMeTrigger({
        body: {
          settings: {
            ...settings,
            ...newSettings,
          },
        },
      }).unwrap();
    },
    [putMeTrigger, settings, user]
  );

  const nsfwFilteredTypes = useMemo<NsfwType[]>(() => {
    if (typeof settings["nsfw-filter"] === "string") {
      const savedTypes = settings["nsfw-filter"].split(",");
      return NSFW_TYPES.filter((value) => savedTypes.includes(value));
    } else {
      return ["explicit_nudity"];
    }
  }, [settings]);
  const setNsfwFilter = useCallback(
    async (values: NsfwType[]) => {
      try {
        await updateUserSettings({
          "nsfw-filter": values.join(","),
        });
      } catch (error) {
        handleApiError(
          error,
          "There was an error saving your safety filter preference"
        );
      }
    },
    [handleApiError, updateUserSettings]
  );

  const policy = featureFlags
    .find((flag) => flag.match(/^policy:.+$/))
    ?.replace(/^policy:/, "");
  const lastAcceptedPolicy =
    policy && (settings["last-policy-accepted"] as string);
  const isPolicyAcceptanceMissing = policy && policy !== lastAcceptedPolicy;
  const markPolicyAsRead = useCallback(async () => {
    try {
      await updateUserSettings({
        "last-policy-accepted": policy,
      });
      Track(AnalyticsEvents.User.PolicyAccepted, { policy });
    } catch (error) {
      handleApiError(error, "Error saving policy opt-in");
    }
  }, [handleApiError, policy, updateUserSettings]);

  const isOnboardingFormMissing =
    user?.settings && !(settings["onboarded"] as boolean);
  const submitOnboardingForm = useCallback(
    async (form: OnboardingForm) => {
      try {
        await putMeTrigger({
          body: {
            fullName: form.fullName,

            ...(() => {
              if (form.phoneNumber && form.countryCode) {
                return {
                  phoneNumber: form.phoneNumber,
                  countryCode: form.countryCode.toUpperCase(),
                };
              } else {
                return {};
              }
            })(),
          },
        }).unwrap();

        await updateUserSettings({
          onboarded: true,
          "company-name": form.companyName,
          role: form.role,
          "traffic-source": form.source,
          "platform-usage": form.usage,
          contact: form.contact,
          "last-policy-accepted": policy,
        });

        successToast({ title: "Profile completed" });

        Track(AnalyticsEvents.User.PolicyAccepted, { policy });
        Track(
          AnalyticsEvents.User.Onboarded,
          _.omit(form, ["fullName", "companyName", "phoneNumber"])
        );
      } catch (error) {
        handleApiError(error, "Error submitting form");
      }
    },
    [successToast, handleApiError, putMeTrigger, updateUserSettings, policy]
  );

  return {
    user: auth0User
      ? {
          ...auth0User,
          avatar: {
            label: auth0User.email,
          },
        }
      : undefined,
    userSettings: settings,
    updateUserSettings,
    featureFlags,
    ...auth0Props,

    nsfwFilteredTypes,
    setNsfwFilter,

    policy,
    lastAcceptedPolicy,
    markPolicyAsRead,
    isPolicyAcceptanceMissing,

    submitOnboardingForm,
    isOnboardingFormMissing,
  };
}
