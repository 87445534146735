import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

import { sizes as textSizes } from "./text";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    fontWeight: 600,
    fontSize: 12,
  },
});

const variants = {
  subtle: definePartsStyle(({ colorScheme }) => ({
    container: {
      color:
        {
          gray: "textPrimary",
          primary: "primary.500",
        }[colorScheme] || `${colorScheme}.500`,
      bg:
        {
          gray: "backgroundSecondary.500",
          primary: "primarySoft",
        }[colorScheme] || `${colorScheme}.900`,
    },
  })),
  secondary: definePartsStyle(({ colorScheme }) => ({
    container: {
      ...textSizes["body.md"],
      color:
        {
          gray: "textPrimary",
          primary: "primary.500",
        }[colorScheme] || `${colorScheme}.500`,
      bg:
        {
          gray: "backgroundSecondary.500",
          primary: "primarySoft",
        }[colorScheme] || `${colorScheme}.900`,
    },
  })),
};

export const Tag = defineMultiStyleConfig({
  baseStyle,
  variants,
});
