import { useMemo } from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetAssetsByAssetIdApiResponse,
  useGetAssetsByAssetIdQuery,
} from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export default function useAssetParent(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined
) {
  const { selectedTeam } = useTeamContext();

  const { data } = useGetAssetsByAssetIdQuery(
    asset?.metadata.parentId
      ? {
          assetId: asset.metadata.parentId,
          teamId: selectedTeam.id,
        }
      : skipToken,
    {
      selectFromResult: ({ data, isError }) => {
        if (isError) {
          return {
            data: undefined,
          };
        }
        return {
          data,
        };
      },
    }
  );

  return useMemo(() => {
    if (!data || !asset || data.asset.id !== asset.metadata.parentId) {
      return undefined;
    }
    return data.asset;
  }, [asset, data]);
}
