import React from "react";
import Link from "next/link";
import PlanStarterImageGenerationsRateLimit from "domains/teams/components/PlanQuotaLimitModal/PlanStarterImageGenerationsRateLimit";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { PlanQuotaLimit } from "domains/teams/interfaces/Plan";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import {
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

export type CustomLabelProps = {
  timeout?: number;
};

type ModalData = {
  [key: string]: {
    title: string;
    label: (
      isAdmin: boolean,
      plan?: string
    ) => string | React.FC<CustomLabelProps>;
    buttonType: (isAdmin: boolean) => "upgrade" | "quota" | "close";
  };
};

const MODAL_DATA: ModalData = {
  planActiveGenerators: {
    title: "Active Generator Limit Reached",
    label: (isAdmin: boolean) =>
      "You've hit the cap for active generators allowed by your current plan. " +
      `${
        isAdmin
          ? "To add more generators, you can either upgrade to a different plan or delete one of your existing generators."
          : "To add more generators, you can either ask an admin to upgrade to a different plan or delete one of your existing generators."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planGeneratorTrainings: {
    title: "Training Limit Reached",
    label: (isAdmin: boolean) =>
      "You've reached your monthly limit for training generators with your current plan. " +
      `${
        isAdmin
          ? "To continue training, consider upgrading your plan or wait for your monthly quota to reset."
          : "To continue training, ask an admin to upgrade the workspace plan or wait for your monthly quota to reset."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planImageGenerations: {
    title: "Image Generation Limit Reached",
    label: (isAdmin: boolean, plan?: string) => {
      if (plan === "free") {
        return (
          "You've reached your limit for generating images with your current plan. " +
          `${
            isAdmin
              ? "To keep creating images, consider upgrading your plan."
              : "To keep creating images, ask an admin to upgrade the workspace plan"
          }`
        );
      } else {
        return (
          "You've reached your monthly limit for generating images with your current plan. " +
          `${
            isAdmin
              ? "To keep creating images, consider upgrading your plan or wait for your monthly quota to reset."
              : "To keep creating images, ask an admin to upgrade the workspace plan or wait for your monthly quota to reset."
          }`
        );
      }
    },
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planStarterImageGenerationsRateLimit: {
    title: "Hold on!",
    label: (_isAdmin: boolean) => PlanStarterImageGenerationsRateLimit,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planImageGenerationsRateLimit: {
    title: "Rate Limit Exceeded",
    label: (_isAdmin: boolean) =>
      "You've exceeded the rate limit for generating images. Wait for a moment and try again. Please reach out to increase your rate limit.",
    buttonType: (_isAdmin: boolean) => "close",
  },
  planMagicPromptLimit: {
    title: "Prompt Spark is currently rate-limited",
    label: (_isAdmin: boolean) =>
      "Please wait a few moments before attempting to generate more prompts",
    buttonType: (_isAdmin: boolean) => "close",
  },
  planBackgroundRemovals: {
    title: "Background Removal Limit Reached",
    label: (isAdmin: boolean) =>
      "You have reached the maximum number of background removals allowed by your plan. " +
      `${
        isAdmin
          ? "Consider upgrading your plan or wait for your monthly quota to reset."
          : "Ask an admin to upgrade the workspace plan or wait for your monthly quota to reset."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planUpscales: {
    title: "Upscale Limit Reached",
    label: (isAdmin: boolean) =>
      "You have reached the maximum number of upscales allowed by your plan. " +
      `${
        isAdmin
          ? "Consider upgrading your plan or wait for your monthly quota to reset."
          : "Ask an admin to upgrade the workspace plan or wait for your monthly quota to reset."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planTeamMembers: {
    title: "Team Member Limit Reached",
    label: (isAdmin: boolean) =>
      "You've reached the limit for team members allowed by your current plan. " +
      `${
        isAdmin
          ? "To invite more members, you can either upgrade to a different plan or delete one of your existing members."
          : "To invite more members, ask an admin to upgrade to a different plan or delete existing members."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planPixelates: {
    title: "Pixelate Limit Reached",
    label: (isAdmin: boolean) =>
      "You have reached the maximum number of pixelation allowed by your plan. " +
      `${
        isAdmin
          ? "Consider upgrading your plan or wait for your monthly quota to reset."
          : "Ask an admin to upgrade the workspace plan or wait for your monthly quota to reset."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planCollections: {
    title: "You're at Full Capacity!",
    label: (isAdmin: boolean) =>
      "It looks like you've maxed out the number of collections allowed under your current plan. To create more collections, " +
      `${
        isAdmin
          ? `please consider upgrading your plan.`
          : `have an admin upgrade your plan, or delete some existing collections.`
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planCanvasLayers: {
    title: "Max Layers Reached!",
    label: (isAdmin: boolean) =>
      "You've reached the maximum number of layers for a single canvas project on your current plan. Any additional changes won't be saved. To add more layers to your masterpiece, " +
      `${
        isAdmin
          ? `please consider upgrading your plan.`
          : `have an admin upgrade your plan, or clear out some previously created layers`
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planCanvasProjects: {
    title: "All Filled Up on Projects!",
    label: (isAdmin: boolean) =>
      "You've maxed out the number of projects under your current plan. To embark on more creative journeys, " +
      `${
        isAdmin
          ? `please consider upgrading your plan.`
          : `have an admin upgrade your plan, or free up some space by deleting existing canvas projects.`
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
};

interface PlanQuotaLimitModalProps {
  hasTrial?: boolean;
  isOpen: boolean;
  id?: PlanQuotaLimit;
  customLabelProps?: CustomLabelProps;
  onClose: () => void;
}

export default function PlanQuotaLimitModal({
  hasTrial,
  isOpen,
  id,
  customLabelProps,
  onClose,
}: PlanQuotaLimitModalProps) {
  const { selectedTeam } = useTeamContext();
  const isAdmin = selectedTeam.userRole === "admin";

  const modalData = id ? MODAL_DATA[id] : undefined;
  const isReady = !!modalData;

  const title = modalData?.title;
  const label = modalData?.label(isAdmin, selectedTeam.plan);
  const buttonType = modalData?.buttonType(isAdmin);

  return (
    <>
      <Modal
        isOpen={isOpen && isReady}
        onClose={onClose}
        size="xl"
        variant="modern"
      >
        <ModalOverlay zIndex={"toast"} />
        <ModalContent
          containerProps={{
            zIndex: "tooltip",
          }}
        >
          <ModalCloseButton data-testid="plan-limitation-close-modal-button" />
          <ModalBody>
            <VStack
              align="center"
              px={6}
              py={10}
              textAlign="center"
              spacing={0}
            >
              <Image
                mb={6}
                alt="limit icon"
                filter="drop-shadow(0px 0px 20px rgb(0 136 204)) drop-shadow(0px 0px 20px rgb(0 136 204 / 0.2))"
                src="/ui/team-billing-limit.svg"
              />
              <Heading
                pb={3}
                color="textPrimary"
                data-testid="plan-limitation-header"
                size="lg"
              >
                {title}
              </Heading>
              {typeof label === "function" ? (
                React.createElement(label, customLabelProps)
              ) : (
                <Text
                  pb={8}
                  color="textSecondary"
                  data-testid="plan-limitation-text"
                >
                  {label &&
                    label.split("\n").reduce((memo, str, idx) => {
                      if (idx > 0) memo.push(<br key={`${idx}-br`} />);
                      memo.push(str);
                      return memo;
                    }, [] as React.ReactNode[])}
                </Text>
              )}

              {buttonType === "upgrade" && (
                <Link href={"/team?tab=plans" as "/team"}>
                  <Button
                    leftIcon={<Icon id="Ui/Upgrade" />}
                    size="lg"
                    w="100%"
                    onClick={onClose}
                    data-testid="plan-limitation-upgrade-button"
                  >
                    {hasTrial
                      ? "Start Your Free Trial"
                      : "Upgrade Workspace Plan"}
                  </Button>
                </Link>
              )}

              {buttonType === "quota" && (
                <Link href="/team">
                  <Button
                    leftIcon={<Icon id="Ui/ArrowUp" height="16px" />}
                    size="lg"
                    w="100%"
                    onClick={onClose}
                    data-testid="plan-limitation-increase-quota-button"
                  >
                    Increase Quota
                  </Button>
                </Link>
              )}
              {buttonType === "close" && (
                <Button size="lg" onClick={onClose}>
                  OK
                </Button>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
