export enum ControlNetNonComposablePreset {
  CHARACTER = "character",
  LANDSCAPE = "landscape",
  CITY = "city",
  INTERIOR = "interior",
}

export enum ControlNetComposablePreset {
  CANNY = "canny",
  DEPTH = "depth",
  POSE = "pose",
  LINES = "lines",
  SEG = "seg",
  NORMAL_MAP = "normal-map",
  SCRIBBLE = "scribble",
  LINEART = "lineart",
}

export const ControlNetPreset = {
  ...ControlNetNonComposablePreset,
  ...ControlNetComposablePreset,
};
export type ControlNetPreset =
  | ControlNetNonComposablePreset
  | ControlNetComposablePreset;

export const allowedControlNetNonComposablePresetsForSdxl: ControlNetNonComposablePreset[] =
  [
    ControlNetNonComposablePreset.CHARACTER,
    ControlNetNonComposablePreset.LANDSCAPE,
  ];

export const allowedControlNetComposablePresetsForSdxl: ControlNetComposablePreset[] =
  [
    ControlNetComposablePreset.CANNY,
    ControlNetComposablePreset.DEPTH,
    ControlNetComposablePreset.POSE,
    ControlNetComposablePreset.SEG,
  ];

export const allowedControlNetPresetsForSdxl = [
  ...allowedControlNetNonComposablePresetsForSdxl,
  ...allowedControlNetComposablePresetsForSdxl,
];

export const controlNetNonComposablePresets = [
  {
    label: "Character",
    value: ControlNetPreset.CHARACTER,
  },
  {
    label: "Landscape",
    value: ControlNetPreset.LANDSCAPE,
  },
  {
    label: "City",
    value: ControlNetPreset.CITY,
  },
  {
    label: "Interior",
    value: ControlNetPreset.INTERIOR,
  },
];

export const controlNetComposablePresets = [
  {
    label: "Structure",
    value: ControlNetPreset.CANNY,
  },
  {
    label: "Pose",
    value: ControlNetPreset.POSE,
  },
  {
    label: "Depth",
    value: ControlNetPreset.DEPTH,
  },
  {
    label: "Edges",
    value: ControlNetPreset.LINES,
  },
  {
    label: "Segmentation",
    value: ControlNetPreset.SEG,
  },
  {
    label: "Scribble",
    value: ControlNetPreset.SCRIBBLE,
  },
  {
    label: "Normal Map",
    value: ControlNetPreset.NORMAL_MAP,
  },
  {
    label: "Line Art",
    value: ControlNetPreset.LINEART,
  },
];

const controlNetAllPresets = [
  ...controlNetNonComposablePresets,
  ...controlNetComposablePresets,
];

export const getControlNetPresetFromString = (value: string) => {
  return controlNetAllPresets.find(
    (option) => option.value === value.split(":")[0]
  );
};

export const getControlNetInfluenceFromString = (value: string) => {
  const influence = parseFloat(value.split(":")[1]);
  return influence && !isNaN(influence) ? influence : 1;
};
