import { apiSlice, Endpoints } from "infra/store/apiSlice";

export const usersTags = {
  user: "user",
} as const;

export const usersEndpoints: Endpoints = {
  putMe: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      const update = dispatch(
        apiSlice.util.updateQueryData("getMe", undefined, (draft) => {
          if (!draft) {
            return;
          }

          draft.user = {
            ...draft.user,
            settings: {
              ...draft.user.settings,
              ...arg.body.settings,
            },
          };
        })
      );

      queryFulfilled.catch(() => update.undo());
    },
    invalidatesTags: [usersTags.user],
  },
  getMe: {
    providesTags: [usersTags.user],
  },
};
