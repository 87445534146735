import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  selectAnatomy.keys
);

export const Select = defineMultiStyleConfig({
  variants: {
    default: {
      field: {
        _focusVisible: {
          boxShadow: "none !important",
        },
        borderWidth: "1px",
        borderColor: "border.500",
        borderRadius: "md",
        backgroundColor: "backgroundSecondary.500",
        option: {
          backgroundColor: "backgroundSecondary.500",
        },
      },
    },
  },

  defaultProps: {
    variant: "default",
  },
});
