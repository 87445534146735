const MIN_INPUT = 0;
const MAX_INPUT = 1;
const MIN_OUTPUT = 0.1;
const MAX_OUTPUT = 1;

export const normalizeInfluence = (value: number): number => {
  return parseFloat(
    (
      ((value - MIN_INPUT) / (MAX_INPUT - MIN_INPUT)) *
        (MAX_OUTPUT - MIN_OUTPUT) +
      MIN_OUTPUT
    ).toFixed(2)
  );
};

export const denormalizeInfluence = (value: number): number => {
  return parseFloat(
    (
      ((value - MIN_OUTPUT) / (MAX_OUTPUT - MIN_OUTPUT)) *
        (MAX_INPUT - MIN_INPUT) +
      MIN_INPUT
    ).toFixed(2)
  );
};
