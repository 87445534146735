import React, { useCallback, useMemo, useRef, useState } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { isTagValid } from "domains/tags/utils/validateTag";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import Tag from "domains/ui/components/Tag";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  usePutAssetsTagsByAssetIdMutation,
  usePutModelsTagsByModelIdMutation,
} from "infra/api/generated/api";
import { intersection } from "lodash";

import { Center, Flex, Spinner } from "@chakra-ui/react";

interface ManageTagsProps {
  items:
    | GetModelsByModelIdApiResponse["model"][]
    | GetAssetsByAssetIdApiResponse["asset"][];
}

const ManageTags = ({ items }: ManageTagsProps) => {
  const { errorToast, successToast } = useScenarioToast();
  const [putModelTagsTrigger] = usePutModelsTagsByModelIdMutation();
  const [putAssetTagsTrigger] = usePutAssetsTagsByAssetIdMutation();
  const { selectedTeam } = useTeamContext();
  const [newTag, setNewTag] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const putTagsTrigger = useMemo(() => {
    if (
      items[0] &&
      "metadata" in items[0] &&
      items[0].metadata.kind === "image"
    ) {
      return putAssetTagsTrigger;
    }
    return putModelTagsTrigger;
  }, [items, putAssetTagsTrigger, putModelTagsTrigger]);

  const [isAddLoading, setIsAddLoading] = useState(false);
  const add = useCallback(async () => {
    const isNewTagValid = isTagValid(newTag);
    if (!isNewTagValid.isValid) {
      errorToast({
        title: isNewTagValid.message,
      });
      return;
    }
    setIsAddLoading(true);
    for (const item of items) {
      if (!item.tags.includes(newTag)) {
        void putTagsTrigger({
          teamId: selectedTeam.id,
          assetId: item.id,
          modelId: item.id,
          body: {
            add: [newTag],
          },
        });
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    }
    successToast({
      title: `Tag "${newTag}" added`,
    });
    setNewTag("");
    setIsAddLoading(false);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, [
    errorToast,
    items,
    putTagsTrigger,
    selectedTeam.id,
    newTag,
    successToast,
  ]);

  const [tagsRemoveLoading, setTagsRemoveLoading] = useState<string[]>([]);
  const remove = useCallback(
    async (tag: string) => {
      setTagsRemoveLoading((prev) => [...prev, tag]);
      for (const item of items) {
        if (item.tags.includes(tag)) {
          void putTagsTrigger({
            teamId: selectedTeam.id,
            assetId: item.id,
            modelId: item.id,
            body: {
              delete: [tag],
            },
          });
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
      }
      setTagsRemoveLoading((prev) => prev.filter((t) => t !== tag));
      successToast({
        title: `Tag "${tag}" removed`,
      });
    },
    [items, putTagsTrigger, selectedTeam.id, successToast]
  );

  const tags = useMemo(() => {
    return [
      ...intersection(...items.map((item) => item.tags)),
      ...tagsRemoveLoading,
    ].sort();
  }, [items, tagsRemoveLoading]);

  if (!items.length) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex direction={"column"} gap={3}>
      <ScenarioInput
        ref={inputRef}
        value={newTag}
        setValue={setNewTag}
        placeholder={"Add a tag"}
        onEnter={add}
        rightComponent={
          <Button
            onClick={add}
            disabled={newTag.length === 0}
            isLoading={isAddLoading}
            size={"xs"}
          >
            Add
          </Button>
        }
        disabled={isAddLoading}
      />
      {tags.length > 0 && (
        <Flex align="center" wrap={"wrap"} gap={1}>
          {tags.map((tag) => (
            <Tag
              key={tag}
              label={tag}
              onRemoveClick={() => {
                void remove(tag);
              }}
              isLoading={tagsRemoveLoading.includes(tag)}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default ManageTags;
