import { Dispatch, SetStateAction } from "react";
import { OnboardingForm } from "domains/user/interfaces/OnboardingForm";

import { Input, Text, VStack } from "@chakra-ui/react";

type SectionPersonalProps = {
  form: OnboardingForm;
  setForm: Dispatch<SetStateAction<OnboardingForm>>;
};

export default function SectionPersonal({
  form,
  setForm,
}: SectionPersonalProps) {
  return (
    <VStack align="stretch" w="100%" spacing={4}>
      <VStack align="start" spacing={2}>
        <Text size="body.md">Full Name</Text>
        <Input
          color="textPrimary"
          bgColor="background.500"
          onChange={(e) =>
            setForm((form) => ({ ...form, fullName: e.target.value }))
          }
          value={form.fullName}
        />
      </VStack>

      <VStack align="start" spacing={2}>
        <Text size="body.md">{"Company Name (Optional)"}</Text>
        <Input
          color="textPrimary"
          bgColor="background.500"
          onChange={(e) =>
            setForm((form) => ({
              ...form,
              companyName: e.target.value,
            }))
          }
          value={form.companyName}
        />
      </VStack>
    </VStack>
  );
}
