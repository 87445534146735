import { getImageFileName } from "domains/assets/utils/getImageFileName";
import loadBase64ImageFromUrl from "domains/image/methods/loadBase64ImageFromUrl";
import { fetchInflight } from "infra/api/fetchInflight";
import JSZip from "jszip";

import { FileImageType } from "../interfaces";

async function downloadImageFile(file: FileImageType) {
  const imageURL = await loadBase64ImageFromUrl(file.meta.url);
  if (!imageURL) {
    throw new Error("Failed to load image");
  }

  const link = document.createElement("a");

  link.href = imageURL;
  link.download = getImageFileName({
    asset: file.meta,
  });
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const downloadAllImageFiles = async (files: FileImageType[]) => {
  if (files.length === 1) {
    await downloadImageFile(files[0]);
    return;
  }

  const zip = new JSZip();

  for (const file of files) {
    const imageUrl = file.meta.url;
    const blob = await fetchInflight(imageUrl).then((r) => r.blob());

    zip.file(
      getImageFileName({
        asset: file.meta,
      }),
      blob
    );
  }

  const zipBlob = await zip.generateAsync({ type: "blob" });
  const url = URL.createObjectURL(zipBlob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = "scenario-images.zip";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
