import { useState } from "react";
import { ASSET_SORT_OPTIONS } from "domains/assets/constants/FileManager";
import { gridSorts } from "domains/file-manager/constants/GridSort";

export default function useAssetSort() {
  const [sort, setSort] = useState<(typeof ASSET_SORT_OPTIONS)[number]>(
    ASSET_SORT_OPTIONS[0]
  );
  return {
    allAssetsSortArgs: {
      sortBy: gridSorts[sort].value[0],
      sortDirection: gridSorts[sort].value[1],
    },
    assetFileManagerSortOptions: {
      sort,
      sortOptions: ASSET_SORT_OPTIONS,
      onSortChange: setSort,
    },
  };
}
