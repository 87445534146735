import useCountdown from "domains/teams/hooks/useCountdown";

import { Text, VStack } from "@chakra-ui/react";

import { CustomLabelProps } from "..";

export default function PlanStarterImageGenerationsRateLimit({
  timeout,
}: CustomLabelProps) {
  const countdown = useCountdown(timeout ?? 0);

  const hours = Math.floor(countdown / 3600);
  const minutes = Math.floor((countdown % 3600) / 60);
  const seconds = countdown % 60;

  let str = "";
  if (hours > 0) {
    str += `${hours} hour${hours > 1 ? "s" : ""}, `;
  }
  if (minutes > 0 || str.length) {
    str += `${minutes} minute${minutes > 1 ? "s" : ""} and `;
  }
  str += `${seconds} second${seconds > 1 ? "s" : ""}`;

  return (
    <VStack align="center" pb={8} color="textSecondary" spacing={4}>
      <Text color="inherit">
        {countdown > 0 ? (
          <>
            Our free Starter plan has a wait time between inferences. Kindly
            pause for{" "}
            <Text display="inline" fontWeight={"bold"}>
              {str}
            </Text>{" "}
            before your next generation.
          </>
        ) : (
          <>
            Your wait time is over. You can close this modal and continue
            generating!
          </>
        )}
      </Text>
      <Text color="inherit">
        Consider upgrading your plan for faster generations, more flexibility
        with canvas projects & collections, enhanced support, and collaborative
        workspaces.
      </Text>
    </VStack>
  );
}
