import { MouseEvent, useMemo } from "react";
import Link, { LinkProps } from "next/link";
import { extraTheme } from "domains/theme";
import Button from "domains/ui/components/Button";
import Icon, { IconId, IconProps } from "domains/ui/components/Icon";
import { camelCase, upperFirst } from "lodash";

import {
  Box,
  ButtonProps,
  Fade,
  Flex,
  IconButton,
  Text,
  TextProps,
} from "@chakra-ui/react";

interface SideMenuLinkProps {
  text: string;
  icon?: IconId;
  iconActive?: IconId;
  iconHeight?: IconProps["height"];
  link?: LinkProps["href"];
  plusLink?: LinkProps["href"];
  active?: boolean;
  variant?: "primary" | "ghost";
  onClick?: () => void;
  onPlusClick?: () => void;
  short?: boolean;
}

export default function SideMenuLink({
  text,
  icon,
  iconActive,
  iconHeight,
  link,
  plusLink,
  active,
  variant,
  onClick,
  onPlusClick,
  short,
}: SideMenuLinkProps) {
  const hasActiveIcon = !!(active && iconActive);

  const handlePlusClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (onPlusClick) onPlusClick();
    e.stopPropagation();
  };

  const styleProps = useMemo((): { root: ButtonProps; text: TextProps } => {
    switch (variant) {
      case "primary":
        return {
          root: {
            colorScheme: "primary",
            variant: "solid",
            bg: active ? "primary.500" : undefined,
            _hover: {
              bg: active ? "primary.500" : "primary.400",
            },
          },
          text: {
            color: "textPrimary",
          },
        };
      case "ghost":
      default:
        return {
          root: {
            colorScheme: "white",
            variant: "ghost",
            bg: active ? "whiteAlpha.300" : undefined,
            _hover: {
              bg: active ? "whiteAlpha.300" : "whiteAlpha.200",
            },
          },
          text: {
            color: active ? "textPrimary" : "textSecondary",
          },
        };
    }
  }, [variant, active]);

  return (
    <Button
      data-group
      as={link ? Link : "button"}
      // NOTE: This is a hack to make the link work with nextjs-routes,
      // will be removed once we make a good separation between internal,
      // external and button links.
      {...{ href: link }}
      {...styleProps.root}
      aria-label={text}
      data-testid={`SideMenuLink-${upperFirst(camelCase(text))}`}
      textTransform="none"
      size="md"
      // TODO: When the short props is true icons can be slightly misaligned, but centering it will cause a flicker in the animation. Find a way to have the icon in center in short variant.
      // justifyContent={short ? "center" : "flex-start"}
      justifyContent="flex-start"
      transition={extraTheme.transitions.fast}
      fontWeight="normal"
      borderRadius="lg"
      px={2}
      gap={3}
      w="full"
      onClick={onClick}
      leftIcon={
        icon ? (
          <Flex justify="center" w="23px">
            <Icon
              h={iconHeight}
              color="textPrimary"
              id={hasActiveIcon ? iconActive : icon}
            />
          </Flex>
        ) : undefined
      }
      rightIcon={
        plusLink ? (
          <Fade in={!short} unmountOnExit>
            <Box display="none" _groupHover={{ display: "block" }}>
              <Link href={plusLink} onClick={handlePlusClick}>
                <IconButton
                  w="24px"
                  minW="24px"
                  h="24px"
                  borderRadius="full"
                  aria-label="create"
                  bgColor="primary.500"
                  icon={
                    <Icon h="14px" color="textPrimary" id="Ui/Plus" mb="2px" />
                  }
                  variant="unstyled"
                />
              </Link>
            </Box>
          </Fade>
        ) : undefined
      }
    >
      <Fade in={!short || !icon} style={{ flex: 1 }} unmountOnExit>
        <Text
          {...styleProps.text}
          align="left"
          maxW={short ? 6 : "full"}
          fontSize="sm"
          _groupHover={{ color: "textPrimary" }}
          transition={extraTheme.transitions.fast}
          isTruncated
        >
          {text}
        </Text>
      </Fade>
    </Button>
  );
}
