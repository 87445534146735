export interface ColorPalette {
  id: string;
  name: string;
  colors: string[];
}

export default [
  {
    id: "public-1",
    name: "1",
    colors: ["#272744", "#8B6D9C", "#C69FA5", "#F2D3AB", "#FBF5EF"],
  },
  {
    id: "public-2",
    name: "2",
    colors: [
      "#F2F0E5",
      "#B8B5B9",
      "#868188",
      "#646365",
      "#45444F",
      "#3A3858",
      "#212123",
      "#352B42",
      "#43436A",
      "#4B80CA",
      "#68C2D3",
      "#A2DCC7",
      "#EDE19E",
      "#D3A068",
      "#B45252",
      "#6A536E",
      "#4B4158",
      "#80493A",
      "#A77B5B",
      "#E5CEB4",
      "#C2D368",
      "#8AB060",
      "#567B79",
      "#4E584A",
      "#7B7243",
      "#B2B47E",
      "#EDC8C4",
      "#CF8ACB",
      "#5F556A",
    ],
  },
  {
    id: "public-3",
    name: "3",
    colors: ["#0F0F1B", "#565A75", "#C6B7BE", "#FAFBF6"],
  },
  {
    id: "public-4",
    name: "4",
    colors: [
      "#000000",
      "#6F6776",
      "#9A9A97",
      "#C5CCB8",
      "#8B5580",
      "#C38890",
      "#A593A5",
      "#666092",
      "#9A4F50",
      "#C28D75",
      "#7CA1C0",
      "#416AA3",
      "#8D6268",
      "#BE955C",
      "#68ACA9",
      "#387080",
      "#6E6962",
      "#93A167",
      "#6EAA78",
      "#557064",
      "#9D9F7F",
      "#5D6872",
      "#433455",
    ],
  },
  {
    id: "public-5",
    name: "5",
    colors: [
      "#8C8FAE",
      "#584563",
      "#3E2137",
      "#9A6348",
      "#D79B7D",
      "#F5EDBA",
      "#C0C741",
      "#647D34",
      "#E4943A",
      "#9D303B",
      "#D26471",
      "#70377F",
      "#7EC4C1",
      "#34859D",
      "#17434B",
      "#1F0E1C",
    ],
  },
  {
    id: "public-6",
    name: "6",
    colors: [
      "#130208",
      "#1F0510",
      "#31051E",
      "#460E2B",
      "#7C183C",
      "#D53C6A",
      "#FF8274",
    ],
  },
  {
    id: "public-7",
    name: "7",
    colors: [
      "#1F240A",
      "#39571C",
      "#A58C27",
      "#EFAC28",
      "#EFD8A1",
      "#AB5C1C",
      "#183F39",
      "#EF692F",
      "#EFB775",
      "#A56243",
      "#773421",
      "#724113",
      "#2A1D0D",
      "#392A1C",
      "#684C3C",
      "#927E6A",
      "#276468",
      "#EF3A0C",
      "#45230D",
      "#3C9F9C",
      "#9B1A0A",
      "#36170C",
      "#550F0A",
      "#300F0A",
    ],
  },
  {
    id: "public-8",
    name: "8",
    colors: [
      "#1A1C2C",
      "#5D275D",
      "#B13E53",
      "#EF7D57",
      "#FFCD75",
      "#A7F070",
      "#38B764",
      "#257179",
      "#29366F",
      "#3B5DC9",
      "#41A6F6",
      "#73EFF7",
      "#F4F4F4",
      "#94B0C2",
      "#566C86",
      "#333C57",
    ],
  },
  {
    id: "public-9",
    name: "9",
    colors: [
      "#636663",
      "#87857C",
      "#BCAD9F",
      "#F2B888",
      "#EB9661",
      "#B55945",
      "#734C44",
      "#3D3333",
      "#593E47",
      "#7A5859",
      "#A57855",
      "#DE9F47",
      "#FDD179",
      "#FEE1B8",
      "#D4C692",
      "#A6B04F",
      "#819447",
      "#44702D",
      "#2F4D2F",
      "#546756",
      "#89A477",
      "#A4C5AF",
      "#CAE6D9",
      "#F1F6F0",
      "#D5D6DB",
      "#BBC3D0",
      "#96A9C1",
      "#6C81A1",
      "#405273",
      "#303843",
      "#14233A",
    ],
  },
  {
    id: "public-10",
    name: "10",
    colors: [
      "#060608",
      "#141013",
      "#3B1725",
      "#73172D",
      "#B4202A",
      "#DF3E23",
      "#FA6A0A",
      "#F9A31B",
      "#FFD541",
      "#FFFC40",
      "#D6F264",
      "#9CDB43",
      "#59C135",
      "#14A02E",
      "#1A7A3E",
      "#24523B",
      "#122020",
      "#143464",
      "#285CC4",
      "#249FDE",
      "#20D6C7",
      "#A6FCDB",
      "#FFFFFF",
      "#FEF3C0",
      "#FAD6B8",
      "#F5A097",
      "#E86A73",
      "#BC4A9B",
      "#793A80",
      "#403353",
      "#242234",
      "#221C1A",
      "#322B28",
      "#71413B",
      "#BB7547",
      "#DBA463",
      "#F4D29C",
      "#DAE0EA",
      "#B3B9D1",
      "#8B93AF",
      "#6D758D",
      "#4A5462",
      "#333941",
      "#422433",
      "#5B3138",
      "#8E5252",
      "#BA756A",
      "#E9B5A3",
      "#E3E6FF",
      "#B9BFFB",
      "#849BE4",
      "#588DBE",
      "#477D85",
      "#23674E",
      "#328464",
      "#5DAF8D",
      "#92DCBA",
      "#CDF7E2",
      "#E4D2AA",
      "#C7B08B",
      "#A08662",
      "#796755",
      "#5A4E44",
      "#423934",
    ],
  },
  {
    id: "public-11",
    name: "11",
    colors: ["#211E20", "#555568", "#A0A08B", "#E9EFEC"],
  },
  {
    id: "public-12",
    name: "12",
    colors: ["#051F39", "#4A2480", "#C53A9D", "#FF8E80"],
  },
  {
    id: "public-13",
    name: "13",
    colors: [
      "#131313",
      "#1B1B1B",
      "#272727",
      "#3D3D3D",
      "#5D5D5D",
      "#858585",
      "#B4B4B4",
      "#FFFFFF",
      "#C7CFDD",
      "#92A1B9",
      "#657392",
      "#424C6E",
      "#2A2F4E",
      "#1A1932",
      "#0E071B",
      "#1C121C",
      "#391F21",
      "#5D2C28",
      "#8A4836",
      "#BF6F4A",
      "#E69C69",
      "#F6CA9F",
      "#F9E6CF",
      "#EDAB50",
      "#E07438",
      "#C64524",
      "#8E251D",
      "#FF5000",
      "#ED7614",
      "#FFA214",
      "#FFC825",
      "#FFEB57",
      "#D3FC7E",
      "#99E65F",
      "#5AC54F",
      "#33984B",
      "#1E6F50",
      "#134C4C",
      "#0C2E44",
      "#00396D",
      "#0069AA",
      "#0098DC",
      "#00CDF9",
      "#0CF1FF",
      "#94FDFF",
      "#FDD2ED",
      "#F389F5",
      "#DB3FFD",
      "#7A09FA",
      "#3003D9",
      "#0C0293",
      "#03193F",
      "#3B1443",
      "#622461",
      "#93388F",
      "#CA52C9",
      "#C85086",
      "#F68187",
      "#F5555D",
      "#EA323C",
      "#C42430",
      "#891E2B",
      "#571C27",
    ],
  },
  {
    id: "public-14",
    name: "14",
    colors: [
      "#5BA675",
      "#6BC96C",
      "#ABDD64",
      "#FCEF8D",
      "#FFB879",
      "#EA6262",
      "#CC425E",
      "#A32858",
      "#751756",
      "#390947",
      "#611851",
      "#873555",
      "#A6555F",
      "#C97373",
      "#F2AE99",
      "#FFC3F2",
      "#EE8FCB",
      "#D46EB3",
      "#873E84",
      "#1F102A",
      "#4A3052",
      "#7B5480",
      "#A6859F",
      "#D9BDC8",
      "#FFFFFF",
      "#AEE2FF",
      "#8DB7FF",
      "#6D80FA",
      "#8465EC",
      "#834DC4",
      "#7D2DA0",
      "#4E187C",
    ],
  },
  {
    id: "public-15",
    name: "15",
    colors: [
      "#28282E",
      "#6C5671",
      "#D9C8BF",
      "#F98284",
      "#B0A9E4",
      "#ACCCE4",
      "#B3E3DA",
      "#FEAAE4",
      "#87A889",
      "#B0EB93",
      "#E9F59D",
      "#FFE6C6",
      "#DEA38B",
      "#FFC384",
      "#FFF7A0",
      "#FFF7E4",
    ],
  },
  {
    id: "public-16",
    name: "16",
    colors: [
      "#CB8175",
      "#E2A97E",
      "#F0CF8E",
      "#F6EDCD",
      "#A8C8A6",
      "#6D8D8A",
      "#655057",
    ],
  },
  {
    id: "public-17",
    name: "17",
    colors: [
      "#000000",
      "#12173D",
      "#293268",
      "#464B8C",
      "#6B74B2",
      "#909EDD",
      "#C1D9F2",
      "#FFFFFF",
      "#A293C4",
      "#7B6AA5",
      "#53427F",
      "#3C2C68",
      "#431E66",
      "#5D2F8C",
      "#854CBF",
      "#B483EF",
      "#8CFF9B",
      "#42BC7F",
      "#22896E",
      "#14665B",
      "#0F4A4C",
      "#0A2A33",
      "#1D1A59",
      "#322D89",
      "#354AB2",
      "#3E83D1",
      "#50B9EB",
      "#8CDAFF",
      "#53A1AD",
      "#3B768F",
      "#21526B",
      "#163755",
      "#008782",
      "#00AAA5",
      "#27D3CB",
      "#78FAE6",
      "#CDC599",
      "#988F64",
      "#5C5D41",
      "#353F23",
      "#919B45",
      "#AFD370",
      "#FFE091",
      "#FFAA6E",
      "#FF695A",
      "#B23C40",
      "#FF6675",
      "#DD3745",
      "#A52639",
      "#721C2F",
      "#B22E69",
      "#E54286",
      "#FF6EAF",
      "#FFA5D5",
      "#FFD3AD",
      "#CC817A",
      "#895654",
      "#61393B",
      "#3F1F3C",
      "#723352",
      "#994C69",
      "#C37289",
      "#F29FAA",
      "#FFCCD0",
    ],
  },
  {
    id: "public-18",
    name: "18",
    colors: [
      "#172038",
      "#253A5E",
      "#3C5E8B",
      "#4F8FBA",
      "#73BED3",
      "#A4DDDB",
      "#19332D",
      "#25562E",
      "#468232",
      "#75A743",
      "#A8CA58",
      "#D0DA91",
      "#4D2B32",
      "#7A4841",
      "#AD7757",
      "#C09473",
      "#D7B594",
      "#E7D5B3",
      "#341C27",
      "#602C2C",
      "#884B2B",
      "#BE772C",
      "#DE9E41",
      "#E8C170",
      "#241527",
      "#411D31",
      "#752438",
      "#A53030",
      "#CF573C",
      "#DA863E",
      "#1E1D39",
      "#402751",
      "#7A367B",
      "#A23E8C",
      "#C65197",
      "#090A14",
      "#10141F",
      "#151D28",
      "#202E37",
      "#394A50",
      "#577277",
      "#819796",
      "#A8B5B2",
      "#EBEDE9",
    ],
  },
  {
    id: "public-19",
    name: "19",
    colors: [
      "#6EB8A8",
      "#2A584F",
      "#74A33F",
      "#FCFFC0",
      "#C6505A",
      "#2F142F",
      "#77A848",
      "#EE9C5D",
    ],
  },
  {
    id: "public-20",
    name: "20",
    colors: [
      "#000000",
      "#1D2B53",
      "#7E2553",
      "#008751",
      "#AB5236",
      "#5F574F",
      "#C2C3C7",
      "#FFF1E8",
      "#FF004D",
      "#FFA300",
      "#FFEC27",
      "#00E436",
      "#29ADFF",
      "#83769C",
      "#FF77A8",
      "#FFCCAA",
    ],
  },
] as ColorPalette[];
