import React, { useEffect, useReducer, useState } from "react";
import { getBrushCircle } from "domains/canvasv2/components/elements/DrawingElement/getBrushCircle";
import { srcToBase64 } from "domains/canvasv2/components/elements/DrawingElement/mergeImage";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import Icon from "domains/ui/components/Icon";
import Konva from "konva";
import { Image, Layer, Line, Rect, Stage } from "react-konva";
import useImage from "use-image";

import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image as ChakraImage,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";

export interface MiniCanvasProps {
  onChange: (image: string) => void;
  defaultImage?: string;
}

type MiniCanvasTool = "brush" | "eraser";

export type PenOptions = {
  stroke: string;
  width: number;
  tool: MiniCanvasTool;
};

export type PenLine = PenOptions & {
  points: number[];
};
// Define the state type
type State = {
  lines: PenLine[];
  undoStack: PenLine[][];
  redoStack: PenLine[][];
};

// Define the action types
type Action =
  | { type: "UPDATE_CURRENT_LINE"; payload: PenLine }
  | { type: "ADD_LINE"; payload: PenLine }
  | { type: "ADD_LINES_TO_UNDO_STACK" }
  | { type: "UNDO" }
  | { type: "REDO" }
  | { type: "CLEAR" };

// Now you can add types to your reducer function
const miniCanvasReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "CLEAR": {
      return {
        lines: [],
        undoStack: [],
        redoStack: [],
      };
    }
    case "UPDATE_CURRENT_LINE": {
      const updatedLines = [...state.lines];
      updatedLines[updatedLines.length - 1] = action.payload;
      return {
        ...state,
        lines: updatedLines,
      };
    }
    case "ADD_LINE": {
      return {
        ...state,
        lines: [...state.lines, action.payload],
      };
    }
    case "ADD_LINES_TO_UNDO_STACK": {
      return {
        ...state,
        undoStack: [...state.undoStack, [...state.lines]],
        redoStack: [],
      };
    }

    case "UNDO": {
      if (state.undoStack.length === 0) return state;
      const newUndoStack = [...state.undoStack];
      const previousLines = newUndoStack.pop() ?? [];
      return {
        ...state,
        lines: previousLines,
        undoStack: newUndoStack,
        redoStack: [...state.redoStack, state.lines],
      };
    }
    case "REDO": {
      if (state.redoStack.length === 0) return state;
      const newRedoStack = [...state.redoStack];
      const nextLines = newRedoStack.pop();
      return {
        ...state,
        lines: nextLines ?? [],
        undoStack: [...state.undoStack, state.lines],
        redoStack: newRedoStack,
      };
    }
    default:
      return state;
  }
};
type MiniCanvasTools = {
  tool: MiniCanvasTool;
  color: string;
  width: number;
};

const MiniCanvas = ({ onChange, defaultImage }: MiniCanvasProps) => {
  const [miniCanvasTools, setMiniCanvasTools] =
    usePersistedState<MiniCanvasTools>(PersistedStateKey.MINI_CANVAS, {
      defaultValue: { tool: "brush", color: "#aaaaaa", width: 5 },
    });
  const { tool, color, width } = miniCanvasTools;

  const isDrawing = React.useRef(false);
  const stageRef = React.useRef<Konva.Stage>(null);
  const [state, dispatch] = useReducer(miniCanvasReducer, {
    lines: [],
    undoStack: [],
    redoStack: [],
  });

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAR" });
    };
  }, []);

  const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    // Only proceed if drawing is active and there is a last line
    if (!isDrawing.current || state.lines.length === 0) {
      return;
    }
    const point = e.target.getStage()?.getPointerPosition();
    if (!point) return;
    const lastLine = state.lines[state.lines.length - 1];

    // Add point to the last line
    const newPoints = lastLine.points.concat([point.x, point.y]);
    const updatedLine = { ...lastLine, points: newPoints };
    dispatch({ type: "UPDATE_CURRENT_LINE", payload: updatedLine });
  };

  const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const pos = e.target.getStage()?.getPointerPosition();
    if (!pos) return;

    // Save the state before starting the new line
    if (isDrawing.current === false) {
      dispatch({ type: "ADD_LINES_TO_UNDO_STACK" });
    }

    isDrawing.current = true;
    const newLine = { tool, points: [pos.x, pos.y], stroke: color, width };
    dispatch({ type: "ADD_LINE", payload: newLine });
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const handleUndo = () => {
    dispatch({ type: "UNDO" });
  };

  const handleRedo = () => {
    dispatch({ type: "REDO" });
  };
  useEffect(() => {
    if (!stageRef.current) return;
    if (state.lines.length === 0) return;
    onChange(stageRef.current?.toDataURL());
  }, [state.lines, onChange]);

  const [imageB64, setImageB64] = useState<string>();
  useEffect(() => {
    if (!defaultImage) return;
    void srcToBase64(defaultImage).then((b64) => {
      setImageB64(b64);
    });
  }, [defaultImage]);

  const [image] = useImage(imageB64 ?? "", "anonymous");

  const [size, setSize] = useState({ width: 400, height: 400 });

  useEffect(() => {
    if (!stageRef.current || !image) return;

    // set the dimension of the canvas to the image dimension
    const calculateSize = (
      width: number,
      height: number,
      min: number,
      max: number
    ) => {
      const aspectRatio = width / height;
      if (width > max || height > max) {
        const size = Math.min(max, Math.max(width, height));
        return { width: size, height: size / aspectRatio };
      }
      if (width < min) {
        return { width: min, height: min / aspectRatio };
      }
      return { width, height };
    };

    const newSize = calculateSize(image.width, image.height, 400, 600);
    setSize(newSize);
  }, [image]);

  return (
    <Box p={2}>
      <Box flexDir="column">
        <Box w={`${size.width}px`} h={`${size.height}px`}>
          <Stage
            ref={stageRef}
            width={size.width}
            height={size.height}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
            onMouseLeave={handleMouseUp}
            style={{
              border: "1px solid #333333",
              cursor: `url(${getBrushCircle(width)}) ${width / 2} ${
                width / 2
              }, auto`,
            }}
          >
            <Layer>
              <Rect width={size.width} height={size.height} fill="#555555" />
            </Layer>
            <Layer>
              <Image image={image} width={size.width} height={size.height} />
              {state.lines.map((line, i) => (
                <Line
                  key={i}
                  points={line.points}
                  stroke={line.stroke}
                  strokeWidth={line.width}
                  tension={0.4}
                  lineCap="round"
                  globalCompositeOperation={
                    line.tool === "eraser" ? "destination-out" : "source-over"
                  }
                />
              ))}
            </Layer>
          </Stage>
        </Box>
        <Flex align="center" justify="space-between" p={2}>
          <HStack>
            <IconButton
              aria-label="undo"
              icon={<Icon id="Domains/Canvas/Undo" />}
              isDisabled={state.undoStack.length === 0}
              onClick={handleUndo}
              size="sm"
              variant="secondary"
            />
            <IconButton
              aria-label="redo"
              icon={<Icon id="Domains/Canvas/Redo" />}
              isDisabled={state.redoStack.length === 0}
              onClick={handleRedo}
              size="sm"
              variant="secondary"
            />
          </HStack>
          <HStack gap="2" bg="backgroundQuaternary.500" borderRadius="md">
            <IconButton
              aria-label="brush"
              icon={<Icon id="Ui/Pencil" />}
              onClick={() =>
                setMiniCanvasTools({
                  ...miniCanvasTools,
                  tool: "brush",
                })
              }
              size="sm"
              variant={tool === "brush" ? "primary" : "secondary"}
            />

            <ChakraImage src={getBrushCircle(5)} />
            <Slider
              w="90px"
              defaultValue={width}
              min={1}
              onChange={(value) => {
                setMiniCanvasTools({
                  ...miniCanvasTools,
                  width: value,
                });
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <ChakraImage src={getBrushCircle(20)} />

            <Divider orientation="vertical" />

            <chakra.input
              sx={{
                "&::-webkit-color-swatch": {
                  borderColor: "transparent",
                  borderRadius: "50%",
                },
                "&::-moz-color-swatch": {
                  borderColor: "transparent",
                  borderRadius: "50%",
                },
              }}
              type="color"
              w={6}
              h={6}
              backgroundColor={color}
              value={color}
              onChange={(e) =>
                setMiniCanvasTools({
                  ...miniCanvasTools,
                  color: e.target.value,
                })
              }
              borderRadius="50%"
              m={1}
            />
          </HStack>
          <IconButton
            aria-label="eraser"
            icon={<Icon id="Domains/Canvas/Erase" />}
            onClick={() =>
              setMiniCanvasTools({
                ...miniCanvasTools,
                tool: "eraser",
              })
            }
            size="sm"
            variant={tool === "eraser" ? "primary" : "secondary"}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export const WithPopoverMiniCanvas = ({
  defaultImage,
  children,
  onAccept,
}: {
  children: React.ReactNode;
  onAccept: (image: string) => void;
  defaultImage?: string;
}) => {
  const initRef = React.useRef<any>();

  const [image, setImage] = useState<string>();

  return (
    <Popover
      arrowPadding={32}
      arrowShadowColor="rgba(0,0,0,0.2)"
      arrowSize={16}
      closeOnBlur={false}
      flip={false}
      gutter={16}
      initialFocusRef={initRef}
      onClose={() => {
        setImage(undefined);
      }}
      placement="right"
      preventOverflow={true}
    >
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          {
            // By conditionally rendering we allow the canvas to clear it state when closed
            isOpen && (
              <Portal>
                <PopoverContent
                  w="fit-content"
                  minH="120px"
                  borderColor="border.500"
                  borderRadius="2xl"
                  shadow="0px 12px 28px 0px rgba(0, 0, 0, 0.20), 0px 2px 4px 0px rgba(0, 0, 0, 0.10)"
                  _focusVisible={{ outline: "none" }}
                  bgColor="backgroundQuaternary.500"
                >
                  <PopoverHeader>Edit reference image</PopoverHeader>
                  <PopoverCloseButton />
                  <PopoverBody>
                    <MiniCanvas
                      defaultImage={defaultImage}
                      onChange={setImage}
                    />
                  </PopoverBody>
                  <PopoverFooter>
                    <Flex justify="end" gap={2} p={4}>
                      <Button onClick={onClose} size={"sm"} variant="secondary">
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          if (image) onAccept(image);
                          onClose();
                        }}
                        size={"sm"}
                        variant="primary"
                      >
                        Apply
                      </Button>
                    </Flex>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            )
          }
        </>
      )}
    </Popover>
  );
};

export default MiniCanvas;
