import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import Button from "domains/ui/components/Button";
import { OnboardingForm } from "domains/user/interfaces/OnboardingForm";
import _ from "lodash";

import { Box, Checkbox, Heading, Input, VStack } from "@chakra-ui/react";

const USAGE_IDS = [
  "design",
  "production",
  "api",
  "ugc",
  "marketing",
  "other",
] as const;

export type UsageId = (typeof USAGE_IDS)[number];

type Usage = {
  label: string;
  custom?: boolean;
};

const USAGE_HASH: { [key in UsageId]: Usage } = {
  design: {
    label: "Conceptualization and Ideation",
  },
  production: {
    label: "Creation of Production-Ready Assets",
  },
  api: {
    label: "API Integration & Plugins",
  },
  ugc: {
    label: "Player-Generated Content",
  },
  marketing: {
    label: "Marketing and Promotional Material",
  },
  other: {
    label: "Other",
    custom: true,
  },
};

const USAGES = USAGE_IDS.map((id) => ({ ...USAGE_HASH[id], id }));

type SectionUsageProps = {
  form: OnboardingForm;
  setForm: Dispatch<SetStateAction<OnboardingForm>>;
};

export default function SectionUsage({ form, setForm }: SectionUsageProps) {
  const [options, setOptions] = useState<UsageId[]>([]);
  const [customInput, setCustomInput] = useState<string>("");

  const getFormUsage = (options: UsageId[], customInput: string) => {
    return options.map((option) =>
      USAGE_HASH[option].custom && customInput ? customInput : option
    );
  };

  const handleOptionClick = useCallback(
    (usageId: UsageId) => {
      const newOptions = options.includes(usageId)
        ? _.without(options, usageId)
        : [...options, usageId];
      const newUsage = getFormUsage(newOptions, customInput);
      setOptions(newOptions);
      setForm({ ...form, usage: newUsage });
    },
    [setOptions, setForm, options, form, customInput]
  );

  const handleCustomInputChange = useCallback(
    (value: string) => {
      setCustomInput(value);
      const newUsage = getFormUsage(options, value);
      setForm({ ...form, usage: newUsage });
    },
    [setCustomInput, setForm, form, options]
  );

  return (
    <VStack align="stretch" w="100%" spacing={4}>
      <Heading pb={1} color="textPrimary" size="sm">
        {"How do you intend to utilize Scenario?"}
      </Heading>

      <VStack align="stretch" spacing={1}>
        {USAGES.map((usage) => {
          const isChecked = options.includes(usage.id);
          return (
            <OptionButton
              key={usage.id}
              id={usage.id}
              label={usage.label}
              custom={usage.custom}
              checked={isChecked}
              customInput={customInput}
              onClick={handleOptionClick}
              onCustomInputChange={handleCustomInputChange}
            />
          );
        })}
      </VStack>
    </VStack>
  );
}

// ------------------------------------

type OptionButtonProps = {
  id: UsageId;
  label: Usage["label"];
  custom: Usage["custom"];
  checked?: boolean;
  customInput: string;
  onClick: (id: UsageId) => void;
  onCustomInputChange: (value: string) => void;
};

function OptionButton({
  id,
  label,
  custom: isCustom,
  checked: isChecked,
  customInput,
  onClick,
  onCustomInputChange,
}: OptionButtonProps) {
  const customInputRef = useRef<HTMLInputElement>(null);

  const isCustomMode = isCustom && isChecked;

  const handleOptionClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.target === customInputRef.current) return;
      onClick(id);
    },
    [onClick, id]
  );
  const handleCustomInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onCustomInputChange(e.target.value),
    [onCustomInputChange]
  );

  return (
    <Button
      as="div"
      cursor="pointer"
      variant="inputSelect"
      size="lg"
      fontSize={["14px", "16px"]}
      data-testid={id}
      leftIcon={<Checkbox as="div" isChecked={isChecked} />}
      onClick={handleOptionClick}
      colorScheme={isChecked ? "secondary" : undefined}
    >
      {isCustomMode ? (
        <Input
          ref={customInputRef}
          color="textPrimary"
          autoFocus
          bgColor="background.500"
          onChange={handleCustomInputChange}
          placeholder="Other usage"
          size="sm"
          value={customInput}
        />
      ) : (
        <Box as="span" overflow="hidden" textOverflow="ellipsis">
          {label}
        </Box>
      )}
    </Button>
  );
}
