import { Image } from "@chakra-ui/react";

interface TopIconProps {
  src: string;
}

export default function TopIcon({ src }: TopIconProps) {
  return (
    <Image
      alt="modal illustration"
      filter="drop-shadow(0px 0px 20px rgb(0 136 204)) drop-shadow(0px 0px 20px rgb(0 136 204 / 0.2))"
      src={src}
    />
  );
}
