import Link, { LinkProps } from "next/link";
import Button from "domains/ui/components/Button";

import { Heading, Image, Text, VStack } from "@chakra-ui/react";

interface ErrorBoundaryProps {
  variant?: "unknown" | "maintenance";
  redirectLink?: LinkProps["href"];
}

export default function ErrorBoundary({
  variant,
  redirectLink,
}: ErrorBoundaryProps) {
  const title = (() => {
    if (variant === "maintenance")
      return "Scenario is currently down for maintenance";
    else return "An error occurred";
  })();
  const subTitle = (() => {
    if (variant === "maintenance")
      return "We expect to be back soon. Thanks for your patience.";
    else
      return "Our team has been notified and is working towards a resolution. We apologize for the inconvenience.";
  })();

  const handleRetryClick = () => window.location.reload();

  return (
    <VStack maxW="480px" minH="100vh" mx="auto" py="72px" spacing={8}>
      <Image w="300px" alt="error illustration" src="/error-boundary.png" />

      <VStack spacing={3}>
        <Heading textAlign="center" size="lg">
          {title}
        </Heading>
        <Text textAlign="center" size="body.lg">
          {subTitle}
        </Text>
      </VStack>

      {redirectLink ? (
        <Link href={redirectLink}>
          <Button>Try Again</Button>
        </Link>
      ) : (
        <Button onClick={handleRetryClick}>Try Again</Button>
      )}
    </VStack>
  );
}
