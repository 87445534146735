import { assetsEndpoints, assetsTags } from "domains/assets/api/endpoints";
import {
  collectionsEndpoints,
  collectionsTags,
} from "domains/collections/api/endpoints";
import {
  generatorsEndpoints,
  generatorsTags,
} from "domains/generators/api/endpoints";
import { imagesEndpoints, imagesTags } from "domains/image/api/endpoints";
import { inferencesEndpoints } from "domains/inference/api/endpoints";
import { searchEndpoints, searchTags } from "domains/search/api/endpoints";
import {
  subscriptionsEndpoints,
  subscriptionsTags,
} from "domains/subscriptions/api/endpoints";
import { teamsEndpoints, teamsTags } from "domains/teams/api/endpoints";
import { usersEndpoints, usersTags } from "domains/user/api/endpoints";
import { scenarioApi } from "infra/api/generated/api";

export type Endpoints = Parameters<
  typeof scenarioApi.enhanceEndpoints
>[0]["endpoints"];

export type Tags = Record<string, string>;

const tagsRecordToArrays = (tags: Tags) => Object.values(tags) as string[];

export const apiSlice = scenarioApi.enhanceEndpoints({
  endpoints: {
    ...inferencesEndpoints,
    ...teamsEndpoints,
    ...generatorsEndpoints,
    ...imagesEndpoints,
    ...usersEndpoints,
    ...searchEndpoints,
    ...assetsEndpoints,
    ...collectionsEndpoints,
    ...subscriptionsEndpoints,
  },
  addTagTypes: [
    ...tagsRecordToArrays(teamsTags),
    ...tagsRecordToArrays(generatorsTags),
    ...tagsRecordToArrays(imagesTags),
    ...tagsRecordToArrays(usersTags),
    ...tagsRecordToArrays(searchTags),
    ...tagsRecordToArrays(assetsTags),
    ...tagsRecordToArrays(collectionsTags),
    ...tagsRecordToArrays(subscriptionsTags),
  ],
});

export type EndpointName = keyof typeof apiSlice.endpoints;

export type SelectedInvalidatedByTag = {
  endpointName: EndpointName;
  originalArgs: any;
};
