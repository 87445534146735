import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";

export function getImageFileName({
  model,
  inference,
  asset,
}: {
  model?: GetModelsByModelIdApiResponse["model"];
  inference?: GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"];
  asset: GetAssetsByAssetIdApiResponse["asset"];
}) {
  const prompt = asset.metadata.prompt ? `${asset.metadata.prompt}_` : "";
  let name = `${asset.id}_${prompt}${asset.metadata.type}`;
  if (asset.metadata.type.includes("inference") && inference) {
    const index = inference.images.findIndex((item) => item.id === asset.id);
    if (index && index !== -1) {
      name = `${name}_${index}`;
    }
    if (model) {
      name = `${
        model.status === "failed" ? "deleted-model" : model.name
      }_${name}`;
    }
  }

  name = `${name}_${Math.floor(new Date().getTime() / 1000)}`;

  return name.slice(0, 251) + ".png";
}
