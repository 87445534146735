import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import {
  FileHandler,
  FileImageType,
  FileType,
} from "domains/file-manager/interfaces";
import Icon from "domains/ui/components/Icon";
import { useHover } from "domains/ui/hooks/useHover";
import _ from "lodash";
import { isSafari } from "react-device-detect";

import {
  Box,
  BoxProps,
  Center,
  Checkbox,
  Fade,
  Flex,
  Grid,
  Image as ChakraImage,
  keyframes,
  Skeleton,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import { HandleSelect } from "../FileManager";

export type FilePreviewProps<F extends FileType> = {
  fileHandler: FileHandler<F>;

  file: F;

  onSelect?: HandleSelect;
  isSelected?: boolean;

  onDrag?: MouseEventHandler<HTMLDivElement>;

  canSelect?: boolean;

  selectModeEnabled?: boolean;

  isLoading?: boolean;

  gridView?: GridViewKey;
  showFileNames?: "always" | "hover" | "never";

  isRevealed?: boolean;
  onReveal?: (id: string) => void;

  cardWidth: number;
};

const FilePreview = React.memo(
  function FilePreview(props: FilePreviewProps<FileType>) {
    const {
      file,
      fileHandler,
      gridView,
      isLoading,
      selectModeEnabled,
      isSelected,
      onSelect,
      onDrag,
      canSelect,
      showFileNames,
      cardWidth,
    } = props;

    // const [imgHeight, setImgHeight] = useState<number[] | undefined>();
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    const isSuccess = !("status" in file) || file.status === "success";
    const imgUrl = isSuccess ? file.thumbnail : "";

    const { imgRef, isLoadingImage, placeholderImage } = useImageLoading({
      width: file.width,
      height: file.height,
      url: imgUrl,
    });

    const imgHeightRatio = Math.round((file.height / file.width) * 100);

    const handleFileClick: MouseEventHandler<HTMLDivElement> = (event) => {
      if (selectModeEnabled) {
        onSelect?.(file.id, { shiftPressed: event.shiftKey });
      } else if (event.shiftKey) {
        onSelect?.(file.id, { shiftPressed: false });
      } else {
        fileHandler.onOpen?.(file);
      }

      event.stopPropagation();
    };

    const handleCheckboxChange: MouseEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        onSelect?.(file.id, {
          shiftPressed: event.shiftKey,
        });

        event.stopPropagation();
      },
      [onSelect, file.id]
    );

    const handleFileDrag: MouseEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        onDrag?.(event);
        event.stopPropagation();
      },
      [onDrag]
    );

    const gridViewCssProps: BoxProps =
      gridView !== "masonry"
        ? {
            position: "absolute",
            top: 0,
            objectFit: gridView === "fill" ? "cover" : "contain",
          }
        : {
            objectFit: "fill",
          };

    return (
      <Box
        ref={hoverRef}
        pos="relative"
        zIndex={0}
        w="100%"
        h={0}
        pt={gridView === "masonry" ? `${imgHeightRatio}%` : "100%"}
        data-outside-click-excluded={true}
        draggable
        {...(isSuccess
          ? { onClick: handleFileClick, onDragEnd: handleFileDrag }
          : {})}
      >
        {/* Image display */}
        <Grid
          pos="absolute"
          top={0}
          w="100%"
          h="100%"
          borderRadius="md"
          cursor={isSuccess ? "pointer" : "default"}
          bgColor="backgroundSecondary.500"
        >
          <ChakraImage
            {...(!isSuccess
              ? {
                  display: "none",
                  position: "absolute",
                  pointerEvents: "none",
                }
              : {})}
            ref={imgRef}
            pos="relative"
            w="100%"
            h="100%"
            borderRadius="md"
            alt=""
            data-testid="asset-gallery-preview-image"
            fallbackSrc={placeholderImage}
            filter={isSelected ? "brightness(1.2)" : ""}
            loading={isSafari ? "eager" : "lazy"}
            src={imgUrl ? imgUrl : placeholderImage}
            {...gridViewCssProps}
          />
          {!isSuccess && (
            <VStack
              borderRadius="md"
              {...gridViewCssProps}
              pos="relative"
              align="center"
              justify="center"
              w="100%"
              h="100%"
              bgColor="backgroundSecondary.500"
              spacing={3}
            >
              <DisplayIsNotSuccess file={file} cardWidth={cardWidth} />
            </VStack>
          )}

          <Fade in={isSuccess && isLoadingImage} unmountOnExit>
            <Skeleton
              pos="absolute"
              zIndex={1}
              top={0}
              left={0}
              w="100%"
              h="100%"
            />
          </Fade>

          <Fade
            // Alway show on hover to make the checkbox visible on white images when its selectable
            in={isSuccess && canSelect && isHovered}
            style={{
              marginTop: gridView !== "masonry" ? `100%` : "",
            }}
            unmountOnExit
          >
            <Flex
              pos="absolute"
              zIndex={3}
              top={0}
              align="end"
              direction="column"
              overflow="hidden"
              w="100%"
              h="100%"
              borderRadius="md"
              pointerEvents={"none"}
            >
              <Flex
                className="bg-gradient-hover"
                direction={"column"}
                w="100%"
                h="100%"
              >
                {showFileNames === "hover" && isHovered && (
                  <Text
                    pos="absolute"
                    bottom={2}
                    left={2}
                    // overflow="hidden"
                    noOfLines={2}
                    size="body.sm"
                  >
                    {file.name}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Fade>

          {isSuccess && canSelect && (isHovered || selectModeEnabled) && (
            <Flex
              pos="absolute"
              zIndex={4}
              top={0}
              direction={"column"}
              display={["none", "none", "flex"]}
              p={2}
              data-testid="asset-gallery-preview-image-checkbox"
              // this is to prevent the file from being open when clicking on the checkbox, stopping the propagation on the on onChange doesn't work
              onClick={handleCheckboxChange}
            >
              {!isLoading && (
                <Checkbox
                  pointerEvents="none"
                  colorScheme="primary"
                  isChecked={isSelected}
                  // Do not use the onChange as it doesn't transmit the shift key
                  size="xl"
                  variant="lightCircular"
                />
              )}
            </Flex>
          )}

          {selectModeEnabled && isSelected && (
            <Box
              pos="absolute"
              zIndex={5}
              top={0}
              left={0}
              w="100%"
              h="100%"
              borderRadius="md"
              outline="3px solid"
              outlineColor="primary.500"
              outlineOffset="-3px"
            />
          )}
        </Grid>

        {showFileNames === "always" && (
          <Flex
            align="center"
            justify="center"
            w="100%"
            h="40px"
            mb="-40px"
            p={2}
          >
            <Text
              textColor={"textPrimary"}
              textAlign={"center"}
              isTruncated
              size={"body.md"}
            >
              {file.name}
            </Text>
          </Flex>
        )}
      </Box>
    );
  },
  (
    prevProps: FilePreviewProps<FileType>,
    props: FilePreviewProps<FileType>
  ) => {
    return _.isEqual(prevProps, props);
  }
);

export default FilePreview;

const animation = keyframes`
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

const DisplayIsNotSuccess = ({
  file,
  cardWidth,
}: {
  file: FileImageType;
  cardWidth: number;
}) => {
  const isVerySmall = cardWidth < 100;

  if (file.status === "placeholder") {
    return (
      <Text
        color="secondary.700"
        textAlign={"center"}
        size={isVerySmall ? "body.sm" : "body.md"}
      >
        {file.width} x {file.height}
      </Text>
    );
  }

  if (file.status === "queued") {
    return (
      <>
        <Center
          pos="relative"
          w="60px"
          maxW={`${cardWidth / 2}px`}
          h="60px"
          maxH={`${cardWidth / 2}px`}
          bg="linear-gradient(315deg, #1A1A1A, #2F2F2F, #434343, #2F2F2F, #1A1A1A, #2F2F2F, #434343, #2F2F2F, #1A1A1A)"
          bgSize="200% 200%"
          borderRadius="full"
          animation={`${animation} 5s linear infinite`}
          data-testid="asset-gallery-grid-cell-queued-status"
        >
          <Icon
            id={"Ui/Hourglass"}
            color={"textPrimary"}
            maxW="50%"
            maxH="50%"
          />
        </Center>
        {!isVerySmall && (
          <Text color="textPrimary" size="body.md">
            Queued
          </Text>
        )}
      </>
    );
  }

  if (file.status === "processing") {
    return (
      <>
        <Spinner
          w="60px"
          maxW={`${cardWidth / 2}px`}
          h="60px"
          maxH={`${cardWidth / 2}px`}
          color="textPrimary"
          data-testid="asset-gallery-grid-cell-progress-status"
          emptyColor="rgba(255, 255, 255, 0.2)"
          speed="0.75s"
          thickness={isVerySmall ? "3px" : "5px"}
        />
        {!isVerySmall && (
          <Text color="Generating" size="body.md">
            Generating
          </Text>
        )}
      </>
    );
  }

  return (
    <>
      <Icon
        id={"Ui/AlertOctagon"}
        color={"textPrimary"}
        maxW="50%"
        maxH="50%"
      />
      {!isVerySmall && (
        <Text color="textPrimary" textAlign="center" size="body.md">
          Generation failed
        </Text>
      )}
    </>
  );
};

// ------------------------------------

const getPlaceholderImage = _.memoize(
  (width: number, height: number) => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    context!.fillStyle = "#333";
    context!.fillRect(0, 0, width, height);
    return canvas.toDataURL();
  },
  (width, height) => `${width}x${height}`
);

function isCached(src: string) {
  const image = new Image();
  image.src = src;
  return image.complete;
}

function useImageLoading({
  width,
  height,
  url,
}: {
  width: number;
  height: number;
  url: string;
}) {
  const imgRef = useRef<HTMLImageElement>(null);

  const [isLoadingImage, setIsLoadingImage] = useState(!isCached(url));

  const placeholderImage = useMemo(() => {
    return getPlaceholderImage(width || 512, height || 512);
  }, [width, height]);

  useEffect(() => {
    if (!imgRef.current) return;

    imgRef.current.onload = () => setIsLoadingImage(false);
  }, [imgRef, url]);

  return {
    width,
    height,
    url,
    imgRef,
    isLoadingImage,
    placeholderImage,
  };
}
