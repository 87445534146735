import {
  ComponentStyleConfig,
  defineStyle,
  defineStyleConfig,
} from "@chakra-ui/react";

export const Button: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    gap: 2,
    span: {
      marginInlineEnd: 0,
      marginInlineStart: 0,
    },
    textTransform: "none",
    _focusVisible: {
      boxShadow: "var(--chakra-shadows-outline)",
    },
    _disabled: {
      cursor: "default",
    },
  },
  defaultProps: {
    colorScheme: "primary",
    variant: "solid",
  },

  variants: {
    primary: ({ colorScheme }) => {
      if (!colorScheme) {
        colorScheme = "primary";
      }

      return {
        colorScheme: `${colorScheme}.500`,
        bg: `${colorScheme}.500`,
        color: colorScheme === "white" ? "black" : "white",
        _hover: {
          bg: `${colorScheme}.400`,
        },
        _active: {
          bg: `${colorScheme}.300`,
        },
      };
    },

    secondary: {
      bg: "whiteAlpha.50",
      border: "1px solid",
      borderColor: "border.500",
      color: "textPrimary",
      _hover: {
        bg: "whiteAlpha.200",
      },
      _active: {
        bg: "whiteAlpha.300",
      },
    },

    solid: ({ colorScheme }) => {
      if (colorScheme === "secondary")
        return {
          bg: "secondary.500",
          border: "1px solid",
          borderColor: "gray.500",
          color: "white",
        };

      return {
        bg: `${colorScheme}.500`,
        color: "white",
      };
    },

    outline: ({ colorScheme }) => {
      return {
        color: `${colorScheme}.500`,
        borderColor: "gray.600",
        _hover: {
          borderColor: `${colorScheme}.500`,
        },
      };
    },

    dropdown: ({ colorScheme }) => {
      return {
        bg: `${colorScheme}.500`,
        color: `white`,
        border: "1px solid",
        borderColor: "border.500",
        _hover: {
          bg: `${colorScheme}.700`,
        },
      };
    },

    ghost: ({ colorScheme }) => {
      if (colorScheme === "secondary")
        return {
          bg: "transparent",
          color: "textPrimary",
        };
      return {
        color: `${colorScheme}.500`,
      };
    },

    underline: ({ colorScheme }) => {
      return {
        color: `${colorScheme}.500`,
        px: 0,
        py: 1,
        h: "auto",
        textDecoration: "underline",
        _hover: {
          color: `${colorScheme}.400`,
        },
        _active: {
          color: `${colorScheme}.300`,
        },
      };
    },

    delete: {
      bg: "transparent",
      border: "1px solid",
      borderColor: "border.500",
      color: "danger.500",
      _hover: {
        bg: "border.500",
      },
      fontSize: 14,
      fontWeight: 500,
    },

    menuItem: {
      w: "100%",
      bg: "transparent",
      color: "textSecondary",
      fontSize: 14,
      fontWeight: 400,
      justifyContent: "start",
      _hover: {
        bg: "whiteAlpha.300",
      },
      p: 2,
    },

    inputSelect: ({ colorScheme }) => {
      const isChecked = colorScheme === "secondary";
      return {
        justifyContent: "start",
        color: isChecked ? "textPrimary" : "textSecondary",
        px: 4,
        borderRadius: "xl",
        bgColor: isChecked ? "primarySoft" : "backgroundTertiary.500",
        borderColor: isChecked ? "primary.500" : "border.500",
        borderWidth: isChecked ? 2 : 1,
        _hover: {
          bgColor: isChecked ? "primary.800" : "backgroundTertiary.800",
          borderColor: isChecked ? "primary.500" : "none",
          color: "textPrimary",
        },
      };
    },
  },
  sizes: {
    lg: defineStyle({
      h: "12",
      minW: "12",
      fontSize: "md",
      px: "6",
      fontWeight: "500",
    }),
    mdInput: defineStyle({
      h: "10",
      px: "3",
      py: "1.5",
      fontSize: "14",
      borderRadius: "lg",
    }),
    md: defineStyle({
      h: "9",
      minW: "9",
      fontSize: "md",
      px: "4",
      fontWeight: "500",
    }),
    smInput: defineStyle({
      h: 8,
      px: 3,
      fontSize: "14",
      borderRadius: "md",
    }),
    sm: defineStyle({
      h: "9",
      minW: "9",
      fontSize: "14",
      fontWeight: "500",
      px: "4",
    }),
    xs: defineStyle({
      h: "7",
      minW: "7",
      fontSize: "11",
      fontWeight: "500",
      px: 2,
    }),
  },
});
