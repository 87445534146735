/**
 * Description of the component
 *
 * @link <url to figma if exist>
 * @link <url to storybook if exist>
 */

import React, { forwardRef } from "react";
import Button from "domains/ui/components/Button";
import DecisionModal from "domains/ui/components/DecisionModal";

import { ButtonProps, useDisclosure } from "@chakra-ui/react";

export interface ButtonWithModalProps extends ButtonProps {
  children: React.ReactNode;
  onConfirm: () => void | Promise<void>;
  onOpen?: () => void;
  onClose?: () => void;
  modalBody: React.ReactNode | string;
  modalHeader?: React.ReactNode | string;
  modalConfirmMessage?: React.ReactNode | string;
  modalShowCancelButton?: boolean;
  modalCancelMessage?: React.ReactNode | string;
  isModalConfirmButtonDisabled?: boolean;
  isModalConfirmButtonLoading?: boolean;
  tooltip?: string;
}

const ButtonWithModal = forwardRef(
  (
    {
      children,
      onConfirm,
      onOpen,
      onClose,
      modalBody,
      modalHeader,
      modalConfirmMessage,
      modalShowCancelButton,
      modalCancelMessage,
      isModalConfirmButtonDisabled,
      isModalConfirmButtonLoading,
      tooltip,
      ...props
    }: ButtonWithModalProps,
    ref
  ) => {
    const {
      isOpen: isModalOpen,
      onOpen: onModalOpen,
      onClose: onModalClose,
    } = useDisclosure();

    const handleOnDialogOpen = () => {
      if (onOpen) onOpen();
      onModalOpen();
    };

    const handleOnDialogClose = () => {
      if (onClose) onClose();
      onModalClose();
    };

    return (
      <>
        <Button
          ref={ref}
          tooltip={tooltip}
          {...props}
          onClick={handleOnDialogOpen}
        >
          {children}
        </Button>
        <DecisionModal
          colorScheme={props.colorScheme}
          body={modalBody}
          onConfirm={async () => {
            await onConfirm();
            onModalClose();
          }}
          isOpen={isModalOpen}
          onClose={handleOnDialogClose}
          headerMessage={modalHeader}
          confirmMessage={modalConfirmMessage}
          showCancelButton={modalShowCancelButton}
          cancelMessage={modalCancelMessage}
          isConfirmButtonDisabled={isModalConfirmButtonDisabled}
          isConfirmButtonLoading={isModalConfirmButtonLoading}
        />
      </>
    );
  }
);
export default ButtonWithModal;
