import ReactDOMServer from "react-dom/server";
import { LuMousePointer2 } from "react-icons/lu";

export function getBrushCircle(brushSize = 20) {
  const MIN_BRUSH_SIZE = 10; // For visibility
  const normalizedBrushSize = Math.max(MIN_BRUSH_SIZE, brushSize);

  // Create a padding around the circle
  const padding = 2;
  const viewBoxSize = normalizedBrushSize + 2 * padding;

  const circle = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${normalizedBrushSize}" height="${normalizedBrushSize}" viewBox="0 0 ${viewBoxSize} ${viewBoxSize}">
      <circle cx="${viewBoxSize / 2}" cy="${viewBoxSize / 2}" r="${
    normalizedBrushSize / 2
  }"
		  fill="rgba(255,255,255,0.05)"
		  stroke="black" stroke-width="1"/>
      <circle cx="${viewBoxSize / 2}" cy="${viewBoxSize / 2}" r="${
    (normalizedBrushSize - 1) / 2
  }"
		  fill="rgba(255,255,255,0.05)"
		  stroke="white" stroke-width="1"/>
    </svg>
  `;

  return `data:image/svg+xml;base64,${window.btoa(circle)}`;
}

export const iconToCursor = (icon: JSX.Element) => {
  const cursor = svgToBlackAndWhite(ReactDOMServer.renderToString(icon));

  const mousePointer = svgToBlackAndWhite(
    ReactDOMServer.renderToString(<LuMousePointer2 />)
  );

  // Put the pointer and the icon under it
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 32 32">
		${mousePointer}
		<foreignObject x="10" y="5" width="32" height="32">
			${cursor}
		</foreignObject>
	</svg>`;

  return `data:image/svg+xml;base64,${window.btoa(svg)}`;
};

function svgToBlackAndWhite(cursor: string) {
  const black = cursor.replace(/fill=".*?"/g, 'fill="black"');
  let white = cursor.replace(/fill=".*?"/g, 'fill="white"');

  // make the white one slightly bigger than the black so it appear around
  white = white.replace(/r=".*?"/g, 'r="15"');

  return `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"> ${black}${white}</svg>`;
}
