import React from "react";
import Button from "domains/ui/components/Button";
import { CustomPicker } from "react-color";
import {
  EditableInput,
  Hue,
  Saturation,
} from "react-color/lib/components/common";

import { Box, VStack } from "@chakra-ui/react";

function CustomHuePointer() {
  return (
    <Box
      pos={"absolute"}
      left={"-6px"}
      w={"12px"}
      h={"12px"}
      border={"2px solid #ffffff"}
      borderRadius={"full"}
    />
  );
}

function CustomSaturationPointer() {
  return (
    <Box
      pos={"absolute"}
      top={"-6px"}
      left={"-6px"}
      w={"12px"}
      h={"12px"}
      border={"2px solid #ffffff"}
      borderRadius={"full"}
    />
  );
}

const ColorPicker = CustomPicker((props: any) => {
  return (
    <VStack
      pos={"absolute"}
      zIndex={"popover"}
      w={"228px"}
      p={2}
      borderWidth={1}
      borderRadius={"lg"}
      bgColor={"secondary.900"}
      spacing={2}
    >
      <Box
        pos={"relative"}
        overflow={"hidden"}
        w={"100%"}
        h={"200px"}
        borderRadius={"md"}
      >
        <Saturation {...props} pointer={CustomSaturationPointer} />
      </Box>
      <Box
        pos={"relative"}
        overflow={"hidden"}
        w={"100%"}
        h={"12px"}
        borderRadius={"md"}
      >
        <Hue {...props} pointer={CustomHuePointer} direction={"horizontal"} />
      </Box>
      <EditableInput
        style={{
          input: {
            width: "100%",
            borderWidth: 1,
            borderRadius: "8px",
            borderColor: "#333333",
            backgroundColor: "#121212",
            color: "#ffffff",
            padding: "10px",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: 400,
            outline: "none",
          },
          wrap: {
            width: "100%",
          },
        }}
        value={props.hex}
        onChange={(data, event) => {
          props.onChange(data);
          event.stopPropagation();
        }}
      />
      <Button
        onClick={() => {
          props.onSubmit(props.hex);
        }}
        w={"100%"}
      >
        Select
      </Button>
    </VStack>
  );
});

export default ColorPicker;
