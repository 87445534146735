import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
} from "@chakra-ui/react";

export interface AvatarProps extends ChakraAvatarProps {
  label?: string;
  color?: "random" | "primary";
  src?: string;
}

export default function Avatar({ label, color, src, ...props }: AvatarProps) {
  return (
    <ChakraAvatar
      bgColor={color === "primary" ? "primary.500" : undefined}
      name={label}
      src={src}
      variant="squared"
      {...props}
    />
  );
}
