import React, { useCallback } from "react";
import { RemoveBackgroundSettings } from "domains/assets/hooks/useAssetRemoveBackground";
import ColorPicker from "domains/ui/components/ColorPicker";
import Icon from "domains/ui/components/Icon";
import ScenarioInput from "domains/ui/components/ScenarioInput";

import {
  Box,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";

const RemoveBackgroundParams = ({
  backgroundColor,
  setBackgroundColor,
}: RemoveBackgroundSettings) => {
  const handleSelectColor = useCallback(
    (color: string, onClose: () => void) => {
      setBackgroundColor(color);
      onClose();
    },
    [setBackgroundColor]
  );

  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <Popover placement="auto">
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <ScenarioInput
                cursor={"pointer"}
                setValue={(_) => {}}
                leftComponent={
                  <Box
                    className={backgroundColor ? undefined : "checkerboard"}
                    overflow="hidden"
                    w="5"
                    h="5"
                    borderRadius="full"
                    bgColor={
                      typeof backgroundColor === "string"
                        ? backgroundColor
                        : `rgba(0, 0, 0, 0)`
                    }
                  />
                }
                rightComponent={
                  <IconButton
                    aria-label="Remove background color"
                    colorScheme="secondary"
                    icon={
                      <Icon
                        id="Ui/Trash"
                        color={
                          backgroundColor ? "textSecondary" : "transparent"
                        }
                        h="16px"
                      />
                    }
                    onClick={(e) => {
                      setBackgroundColor("");
                      e.stopPropagation();
                    }}
                    size="xs"
                    variant="ghost"
                    {...(backgroundColor
                      ? {}
                      : {
                          _hover: {},
                        })}
                    pointerEvents={backgroundColor ? undefined : "none"}
                  />
                }
                value={backgroundColor as string}
                placeholder={"Transparent"}
              />
            </PopoverTrigger>
            <PopoverContent borderWidth={0}>
              <ColorPicker
                withAlpha={true}
                color={backgroundColor}
                onChange={(color: any) => setBackgroundColor(color.hex)}
                onSubmit={(color: any) => handleSelectColor(color, onClose)}
              />
            </PopoverContent>
          </>
        )}
      </Popover>
    </VStack>
  );
};

export default RemoveBackgroundParams;
