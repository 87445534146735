import React, { useMemo, useState } from "react";
import { useRouter } from "next/router";
import AssetDownloadModal from "domains/assets/components/AssetsDownloadModal";
import AssetsGalleryEmptyState from "domains/assets/components/AssetsGalleryEmptyState";
import AssetCollection from "domains/collections/components/AssetCollection";
import { downloadAllImageFiles } from "domains/file-manager/utils/downloadImageFiles";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useSessionContext } from "domains/session/contexts/SessionProvider";
import ManageTags from "domains/tags/components/ManageTags";
import SelectionBarTags from "domains/tags/components/SelectionBarTags";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import Button from "domains/ui/components/Button";
import ButtonWithModal from "domains/ui/components/ButtonWithModal";
import Icon from "domains/ui/components/Icon";
import { NsfwIndicator } from "domains/ui/components/NsfwIndicator";
import { useUser } from "domains/user/hooks/useUser";
import { useHandleApiError } from "infra/api/error";
import {
  useDeleteAssetMutation,
  useGetAssetsByAssetIdQuery,
  usePutImagesEraseBackgroundMutation,
} from "infra/api/generated/api";
import _ from "lodash";

import { skipToken } from "@reduxjs/toolkit/dist/query";

import DefaultFilePreview, {
  FilePreviewProps,
} from "../components/FilePreview";
import { FileHandler, FileImageType } from "../interfaces";

interface FileImageHandlerProps {
  emptyState?: JSX.Element;
}

export const useFileImageHandler = ({
  emptyState,
}: FileImageHandlerProps): FileHandler<FileImageType> => {
  const { successToast } = useScenarioToast();
  const { selectedTeam } = useTeamContext();
  const { showLimitModal } = usePlanContext();
  const {
    deleteImageFiles: deleteImageFilesFromSession,
    undeleteImageFiles: undeleteImageFilesFromSession,
  } = useSessionContext();
  const [assetDownloadFiles, setAssetDownloadFiles] = useState<FileImageType[]>(
    []
  );
  const [triggerDeleteImage, { isLoading: isDeleteImageLoading }] =
    useDeleteAssetMutation();

  const [triggerRemoveBackground, { isLoading: isRemoveBackgroundLoading }] =
    usePutImagesEraseBackgroundMutation();

  const isLoading = isDeleteImageLoading || isRemoveBackgroundLoading;

  const router = useRouter();

  const handleApiError = useHandleApiError();

  const handleDelete = async (files: FileImageType[]) => {
    try {
      deleteImageFilesFromSession(files);
      const ids = files.map((file) => file.id);
      // split by array of 100 ids
      const chunks = [];
      for (let i = 0; i < ids.length; i += 100) {
        chunks.push(ids.slice(i, i + 100));
      }
      for (const chunk of chunks) {
        await triggerDeleteImage({
          body: {
            assetIds: chunk,
          },
          teamId: selectedTeam.id,
        });
      }

      successToast({
        title: "Images deleted",
      });
    } catch (error) {
      undeleteImageFilesFromSession(files);
      handleApiError(error, "There was an error deleting the images");
    }
  };

  const handleRemoveBackground = async (files: FileImageType[]) => {
    try {
      const filesWithBackgroundRemoved = await Promise.all(
        files.map(async (file): Promise<FileImageType> => {
          const data = await triggerRemoveBackground({
            teamId: selectedTeam.id,
            body: {
              assetId: file.id,
              format: "png",
              returnImage: false,
            },
          }).unwrap();

          // Overwrite the files url with the new one before downloading
          return {
            ...file,
            meta: {
              ...file.meta,
              url: data.asset.url,
              metadata: {
                ...file.meta.metadata,
                type: "background-removal",
              },
            },
          };
        })
      );

      await downloadAllImageFiles(filesWithBackgroundRemoved);
      successToast({
        title: "Backgrounds removed",
      });
    } catch (error) {
      handleApiError(error, "There was an error removing the backgrounds", {
        quota: () => {
          showLimitModal("planBackgroundRemovals");
        },
      });
    }
  };

  return {
    // TODO: enable when we are sure generator list and asset gallery are integrated into file manager fully
    EmptyState: emptyState ?? <AssetsGalleryEmptyState />,
    FilePreview: FileImagePreview,
    actions: [
      {
        kind: ["selectionBar"],
        label: "Delete",
        Component: ({ onAction }) => (
          <ButtonWithModal
            variant="secondary"
            colorScheme={"danger"}
            leftIcon={<Icon id="Ui/Trash" />}
            onConfirm={onAction}
            modalHeader={"Delete Images"}
            modalBody={"Are you sure you want to delete these images?"}
            isLoading={isDeleteImageLoading}
            isModalConfirmButtonLoading={isDeleteImageLoading}
            isDisabled={isLoading}
            data-testid="file-manager-delete-button"
          >
            Delete
          </ButtonWithModal>
        ),
        shortcut: (e: KeyboardEvent) =>
          e.key === "Delete" || e.key === "Backspace",
        onAction: handleDelete,
      },
      {
        kind: ["selectionBar"],
        label: "Download",
        Component: ({ onAction }) => {
          return (
            <>
              <Button
                variant="secondary"
                colorScheme={"danger"}
                leftIcon={<Icon id="Ui/Download" />}
                onClick={onAction}
                isLoading={isDeleteImageLoading}
                isDisabled={isLoading}
                data-testid="file-manager-download-button"
              >
                Download
              </Button>
              <AssetDownloadModal
                onClose={() => setAssetDownloadFiles([])}
                files={assetDownloadFiles}
              />
            </>
          );
        },
        onAction: setAssetDownloadFiles,
      },
      {
        kind: ["selectionBar"],
        label: "Remove background",
        Component: ({ onAction }) => (
          <ButtonWithModal
            variant="secondary"
            colorScheme={"primary"}
            leftIcon={<Icon id="Ui/Revert" />}
            onConfirm={onAction}
            modalHeader={"Remove background"}
            modalBody={
              "Are you sure you want to remove background for these images?"
            }
            isLoading={isRemoveBackgroundLoading}
            isDisabled={isLoading}
            data-testid="file-manager-remove-bg-button"
          >
            Remove Background
          </ButtonWithModal>
        ),
        onAction: handleRemoveBackground,
      },
      {
        kind: ["selectionBar"],
        label: "Collections",
        onAction: (_) => {},
        Component: ({ files }) => (
          <AssetCollection
            assets={files.map((item) => item.meta)}
            menuPlacement="top"
          />
        ),
      },
      {
        kind: ["selectionBar"],
        label: "Tags",
        onAction: (_) => {},
        Component: ({ files }) => (
          <SelectionBarTags length={files.length}>
            <ManageTags items={files.map((item) => item.meta)} />
          </SelectionBarTags>
        ),
      },
    ],
    onOpen: (file: FileImageType) => {
      router.query.openAssetId = file.id;

      void router.push(
        {
          query: router.query,
        },
        undefined,
        { scroll: false }
      );
    },
  };
};

export const FileImagePreview = (props: FilePreviewProps<FileImageType>) => {
  const { selectedTeam } = useTeamContext();
  const { nsfwFilteredTypes } = useUser();
  const reveal = props.isRevealed;

  const thumbnail = useMemo(() => {
    if (reveal) {
      return props.file.thumbnail.replace("&blur=100", "");
    }
    return props.file.thumbnail;
  }, [props.file.thumbnail, reveal]);

  const shouldRefetch =
    props.file.status === "processing" &&
    !props.file.id.includes("placeholder");

  useGetAssetsByAssetIdQuery(
    shouldRefetch
      ? {
          assetId: props.file.id,
          teamId: selectedTeam.id,
        }
      : skipToken,
    {
      pollingInterval: 5000,
    }
  );

  // FIXME: fix type, like in FileView, this is a problem with the fileHandler
  return (
    <>
      <DefaultFilePreview
        {...(props as any)}
        file={{
          ...props.file,
          thumbnail: thumbnail,
        }}
      />
      {_.intersection(nsfwFilteredTypes, props.file.meta.nsfw).length > 0 &&
        !reveal && (
          <NsfwIndicator
            onClick={(e) => {
              e.preventDefault();
              props.onReveal?.(props.file.id);
            }}
          />
        )}
    </>
  );
};
