import { useCallback, useState } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  PutImagesEraseBackgroundApiResponse,
  useLazyGetAssetsByAssetIdQuery,
  usePutImagesEraseBackgroundMutation,
} from "infra/api/generated/api";
import { Color } from "react-color";

export interface RemoveBackgroundSettings {
  backgroundColor: Color | undefined;
  setBackgroundColor: (color: Color | undefined) => void;
}

export default function useAssetRemoveBackground(): {
  removeBackgroundSettings: RemoveBackgroundSettings;
  handleRemoveBackground: (props: {
    image: string;
    assetId: string | undefined;
    trackingExtraParams: Record<string, unknown>;
  }) => Promise<PutImagesEraseBackgroundApiResponse["asset"] | undefined>;
  isRemoveBackgroundLoading: boolean;
} {
  const [isRemoveBackgroundLoading, setIsRemoveBackgroundLoading] =
    useState<boolean>(false);
  const { selectedTeam } = useTeamContext();
  const [getAssetByAssetId] = useLazyGetAssetsByAssetIdQuery();
  const [triggerImageRemoveBackground] = usePutImagesEraseBackgroundMutation();
  const { infoToast, successToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();

  const [backgroundColor, setBackgroundColor] = useState<Color | undefined>();

  const handleRemoveBackground = useCallback(
    async ({
      image,
      assetId,
      trackingExtraParams,
    }: {
      image: string;
      assetId: string | undefined;
      trackingExtraParams: Record<string, unknown>;
    }) => {
      setIsRemoveBackgroundLoading(true);
      infoToast({
        title: "Removing background...",
      });

      try {
        let removedBackgroundAsset:
          | PutImagesEraseBackgroundApiResponse
          | undefined = await triggerImageRemoveBackground({
          teamId: selectedTeam.id,
          body: {
            image: assetId ? undefined : image,
            assetId: assetId,
            returnImage: false,
            format: "png",
            ...(backgroundColor
              ? {
                  backgroundColor: backgroundColor as string,
                }
              : {}),
          },
        }).unwrap();

        while (
          removedBackgroundAsset &&
          removedBackgroundAsset.asset.status === "pending"
        ) {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          try {
            removedBackgroundAsset = await getAssetByAssetId({
              teamId: selectedTeam.id,
              assetId: removedBackgroundAsset.asset.id,
            }).unwrap();
          } catch (_) {
            removedBackgroundAsset = undefined;
          }
        }

        if (
          !removedBackgroundAsset ||
          removedBackgroundAsset.asset.status !== "success"
        ) {
          errorToast({
            title: "Error removing background",
          });
          return;
        }

        Track(AnalyticsEvents.ImageLibrary.RemovedBackground, {
          image: assetId ? undefined : image,
          assetId,
          backgroundColor: backgroundColor ?? "transparent",
          ...trackingExtraParams,
        });

        successToast({
          title: "Background removed",
        });

        return removedBackgroundAsset.asset;
      } catch (error: unknown) {
        handleApiError(error, "Error removing background", {
          quota: () => {
            showLimitModal("planBackgroundRemovals");
          },
        });
      } finally {
        setIsRemoveBackgroundLoading(false);
      }
    },
    [
      backgroundColor,
      errorToast,
      getAssetByAssetId,
      handleApiError,
      infoToast,
      selectedTeam.id,
      showLimitModal,
      successToast,
      triggerImageRemoveBackground,
    ]
  );

  return {
    removeBackgroundSettings: {
      backgroundColor,
      setBackgroundColor,
    },
    handleRemoveBackground,
    isRemoveBackgroundLoading,
  };
}
