import { DragDropManager } from "dnd-core";
import { HTML5Backend } from "react-dnd-html5-backend";
import { HTML5BackendImpl } from "react-dnd-html5-backend/src/HTML5BackendImpl";

export function DragDropBackend(
  manager: DragDropManager,
  globalContext?: any,
  configuration?: any
) {
  const instance = HTML5Backend(
    manager,
    globalContext,
    configuration
  ) as HTML5BackendImpl;
  const superHandleTopDragStart = instance.handleTopDragStart;
  instance.handleTopDragStart = (e: DragEvent) => {
    e.preventDefault = () => {};
    superHandleTopDragStart(e);
  };
  return instance;
}
