import InternalLink, { LinkProps as InternalLinkProps } from "next/link";

// eslint-disable-next-line no-restricted-imports
import {
  Link,
  LinkProps,
  MenuItem as ChakraMenuItem,
  MenuItemProps as ChakraMenuItemProps,
} from "@chakra-ui/react";

export interface MenuItemProps extends ChakraMenuItemProps {
  externalLink?: LinkProps["href"];
  internalLink?: InternalLinkProps["href"];
}

export function MenuItem({
  externalLink,
  internalLink,
  ...props
}: MenuItemProps) {
  if (externalLink) {
    return (
      <ChakraMenuItem
        as={Link}
        href={externalLink}
        isExternal
        variant="unstyled"
        {...props}
      />
    );
  } else if (internalLink) {
    return <ChakraMenuItem as={InternalLink} href={internalLink} {...props} />;
  } else {
    return <ChakraMenuItem {...props} />;
  }
}
