import React, { useCallback } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import Icon from "domains/ui/components/Icon";

import {
  Grid,
  GridItem,
  HStack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

type PanelInfoGridItem = {
  label: string | React.ReactElement;
  value: string | number | React.ReactElement;
  fullRow?: boolean;
  copyable?: boolean;
};

export interface PanelInfoGridLayoutProps {
  items: PanelInfoGridItem[];
}

const PanelInfoGridLayout = ({ items }: PanelInfoGridLayoutProps) => {
  return (
    <Grid gap={6} rowGap={6} templateColumns="repeat(2, 1fr)">
      {items.map((item, index) => (
        <GridItem
          key={`panelInfoGrid-${index}`}
          colSpan={item.fullRow ? 2 : undefined}
        >
          <PanelInfoGridItem
            label={item.label}
            value={item.value}
            copyable={item.copyable}
          />
        </GridItem>
      ))}
    </Grid>
  );
};

export default PanelInfoGridLayout;

// ------------------------------------

type PanelInfoGridItemProps = Omit<PanelInfoGridItem, "fullRow">;

function PanelInfoGridItem({
  label,
  value,
  copyable: isCopyable,
}: PanelInfoGridItemProps) {
  const { infoToast } = useScenarioToast();

  const handleClick = useCallback(async () => {
    if (!(typeof value === "string" || typeof value === "number")) return;
    await navigator.clipboard.writeText(String(value));
    infoToast({
      title: "Copied to clipboard",
    });
  }, [infoToast, value]);

  return (
    <Tooltip
      isDisabled={!isCopyable}
      label="Copy to clipboard"
      openDelay={500}
      placement="left-start"
    >
      <VStack
        align="stretch"
        {...(isCopyable
          ? {
              mx: -3,
              my: -2,
              px: 3,
              py: 2,
              borderRadius: "md",
              _hover: { bgColor: "background.500", cursor: "pointer" },
              onClick: handleClick,
            }
          : {})}
        data-group
        spacing={0}
      >
        <HStack pb={2}>
          {typeof label === "function" ? (
            label
          ) : (
            <Text color="white.500" size="body.bold.md">
              {label}
            </Text>
          )}
          {isCopyable && (
            <Icon
              id="Ui/Copy"
              height="12px"
              visibility="hidden"
              _groupHover={{ visibility: "visible" }}
            />
          )}
        </HStack>

        {typeof value === "function" ? (
          value
        ) : (
          <Text color="textSecondary" size="body.md">
            {value}
          </Text>
        )}
      </VStack>
    </Tooltip>
  );
}
