import _ from "lodash";

import { LocalStorageService } from "./localStorageService";

export const CURRENT_VERSION = 5;

type MigrationFunction = (localStorageService: LocalStorageService) => void;

interface Migration {
  version: number;
  migrate: MigrationFunction;
}

const migrationLogic: Migration[] = [
  {
    version: 2,
    migrate: (localStorageService) => {
      localStorageService.set(
        "persistentDisplayOptions",
        localStorageService.get("persistantDisplayOptions")
      );
      localStorageService.remove("persistantDisplayOptions");
    },
  },
  {
    version: 3,
    migrate: (localStorageService) => {
      const oldDisplayOptions = localStorageService.get<{
        numberOfColumns: number;
        gridView: string;
      }>("persistentDisplayOptions");
      if (oldDisplayOptions) {
        localStorageService.set(
          "numberOfColumns",
          oldDisplayOptions.numberOfColumns
        );
        localStorageService.set("gridView", oldDisplayOptions.gridView);
        localStorageService.remove("persistentDisplayOptions");
      }
    },
  },
  {
    version: 4,
    migrate: (localStorageService) => {
      const oldGridView = localStorageService.get("gridView");
      let gridView = "";
      if (_.includes(["column"], oldGridView)) gridView = "masonry";
      if (_.includes(["fullGrid", "fillInGrid", "fill_in_grid"], oldGridView))
        gridView = "fill";
      if (_.includes(["centeredGrid", "fitInGrid", "fit_in_grid"], oldGridView))
        gridView = "fit";

      if (gridView) localStorageService.set("gridView", gridView);
      else localStorageService.remove("gridView");
    },
  },
  {
    version: 5,
    migrate: (localStorageService) => {
      const oldSelectedTeam = localStorageService.get("selectedTeam");
      const newSelectedTeam = oldSelectedTeam
        ? {
            ...oldSelectedTeam,
            plan: "free",
          }
        : undefined;

      if (newSelectedTeam)
        localStorageService.set("selectedTeam", newSelectedTeam);
    },
  },
];

export default migrationLogic;
