import React, { useCallback } from "react";
import { useRouter } from "next/router";
import { ACTIONS } from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel";
import useAssetPixelate from "domains/assets/hooks/useAssetPixelate";
import Button from "domains/ui/components/Button";
import PixelateParams from "domains/ui/components/PixelateParams";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

import { VStack } from "@chakra-ui/react";

export interface AssetZoomPixelateProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  updateCurrentAction: (action: ACTIONS | undefined) => void;
}

const AssetZoomPixelate = ({
  asset,
  updateCurrentAction,
}: AssetZoomPixelateProps) => {
  const router = useRouter();
  const { pixelateSettings, handlePixelate, isPixelateLoading } =
    useAssetPixelate();

  const onPixelate = useCallback(async () => {
    const newAsset = await handlePixelate({
      image: asset.url,
      assetId: asset.id,
      trackingExtraParams: {
        origin: "image edit",
      },
    });
    if (newAsset) {
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: newAsset.id,
        },
      });
      updateCurrentAction(undefined);
    }
  }, [asset, handlePixelate, router, updateCurrentAction]);

  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <PixelateParams image={asset.url} {...pixelateSettings} />
      <Button onClick={onPixelate} isLoading={isPixelateLoading} w={"100%"}>
        Pixelate
      </Button>
    </VStack>
  );
};

export default AssetZoomPixelate;
