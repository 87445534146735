import { GridSortValue } from "domains/file-manager/constants/GridSort";

export const ASSET_SORT_OPTIONS: GridSortValue[] = [
  "createdDesc",
  "createdAsc",
];
/* export const GENERATOR_SORT_OPTIONS: GridSortValue[] = [
  "createdDesc",
  "createdAsc",
]; */
