import React, { useCallback } from "react";
import { useRouter } from "next/router";
import { ACTIONS } from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel";
import useAssetRemoveBackground from "domains/assets/hooks/useAssetRemoveBackground";
import Button from "domains/ui/components/Button";
import RemoveBackgroundParams from "domains/ui/components/RemoveBackgroundParams";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";

import { VStack } from "@chakra-ui/react";

export interface AssetZoomRemoveBackgroundProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  updateCurrentAction: (action: ACTIONS | undefined) => void;
}

const AssetZoomRemoveBackground = ({
  asset,
  updateCurrentAction,
}: AssetZoomRemoveBackgroundProps) => {
  const router = useRouter();

  const {
    removeBackgroundSettings,
    handleRemoveBackground,
    isRemoveBackgroundLoading,
  } = useAssetRemoveBackground();

  const onRemoveBackground = useCallback(async () => {
    const newAsset = await handleRemoveBackground({
      image: asset.url,
      assetId: asset.id,
      trackingExtraParams: {
        origin: "image edit",
      },
    });
    if (newAsset) {
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: newAsset.id,
        },
      });
      updateCurrentAction(undefined);
    }
  }, [asset, handleRemoveBackground, router, updateCurrentAction]);

  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <RemoveBackgroundParams {...removeBackgroundSettings} />
      <Button
        onClick={onRemoveBackground}
        isLoading={isRemoveBackgroundLoading}
        w={"100%"}
      >
        Remove Background
      </Button>
    </VStack>
  );
};

export default AssetZoomRemoveBackground;
