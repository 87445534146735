import { forwardRef } from "react";
import _ from "lodash";

import {
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from "@chakra-ui/react";

import { IconSet } from "./constants/IconSet";

export type IconId = keyof typeof IconSet;
export interface IconProps extends Omit<ChakraIconProps, "children"> {
  id: IconId;
}

/**
 *
 * @param {{id: IconId}} props
 */
const Icon = forwardRef<SVGSVGElement, IconProps>(({ id, ...props }, ref) => {
  const icon = IconSet[id];
  const height =
    parseInt(_.nth(_.get(icon, "viewBox", "").split(" "), 3) || "0") ||
    undefined;

  return (
    <ChakraIcon
      ref={ref}
      w={height && "auto"}
      h={height && `${height}px`}
      viewBox={icon.viewBox}
      {...props}
    >
      {icon.content}
    </ChakraIcon>
  );
});

export default Icon;
