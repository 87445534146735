import React, { useCallback } from "react";
import CollectionMenuList from "domains/collections/components/CollectionMenuList";
import useAllCollections from "domains/collections/hooks/useAllCollections";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  useDeleteAssetsByCollectionIdMutation,
  usePutAssetsByCollectionIdMutation,
} from "infra/api/generated/api";
import _ from "lodash";

import { PlacementWithLogical } from "@chakra-ui/popper/dist/popper.placement";

export interface AssetCollectionProps {
  assets: GetAssetsByAssetIdApiResponse["asset"][];
  isDisabled?: boolean;
  menuPlacement: PlacementWithLogical;
  canvas?: boolean;
}

const AssetCollection = ({
  assets,
  isDisabled,
  menuPlacement,
  canvas,
}: AssetCollectionProps) => {
  const handleApiError = useHandleApiError();
  const { successToast } = useScenarioToast();
  const { selectedTeam } = useTeamContext();
  const { collections, isLoading: isLoadingCollections } = useAllCollections();

  const isInCollection = useCallback(
    (collectionId: string) => {
      if (!assets.length) {
        return false;
      }
      return !assets.some(
        (asset) => !asset.collectionIds.includes(collectionId)
      );
    },
    [assets]
  );

  const [addAssetToCollectionTrigger] = usePutAssetsByCollectionIdMutation();
  const [removeAssetFromCollectionTrigger] =
    useDeleteAssetsByCollectionIdMutation();
  const toggleAssetsFromCollection = useCallback(
    async (collectionId: string) => {
      const nameOfAsset = canvas ? "project" : "image";
      const add = !isInCollection(collectionId);
      const assetIds = assets
        .filter((asset) => add !== asset.collectionIds.includes(collectionId))
        .map((asset) => asset.id);
      const trigger = add
        ? addAssetToCollectionTrigger
        : removeAssetFromCollectionTrigger;
      try {
        await trigger({
          teamId: selectedTeam.id,
          collectionId,
          body: {
            assetIds,
          },
        }).unwrap();
        successToast({
          title: `${_.capitalize(nameOfAsset)}${assets.length > 1 ? "s" : ""} ${
            add ? "added to" : "removed from"
          } collection`,
        });
      } catch (error) {
        handleApiError(
          error,
          `Error ${add ? "adding" : "removing"} ${nameOfAsset}${
            assets.length > 1 ? "s" : ""
          } ${add ? "to" : "from"} collection`
        );
      }
    },
    [
      canvas,
      isInCollection,
      assets,
      addAssetToCollectionTrigger,
      removeAssetFromCollectionTrigger,
      selectedTeam.id,
      handleApiError,
      successToast,
    ]
  );

  return (
    <CollectionMenuList
      collections={collections}
      isLoadingCollections={isLoadingCollections}
      toggleFromCollection={toggleAssetsFromCollection}
      isInCollection={isInCollection}
      placement={menuPlacement}
      isDisabled={isDisabled}
    />
  );
};

export default AssetCollection;
