import {
  gridSorts,
  GridSortValue,
} from "domains/file-manager/constants/GridSort";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";

export interface GridSortDropdownProps<T extends GridSortValue[]> {
  options: T;
  value: T[number];
  onChange: (value: T[number]) => void;
}

export default function GridSortDropdown<T extends GridSortValue[]>({
  options,
  value,
  onChange,
}: GridSortDropdownProps<T>) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        p={2}
        borderRadius="md"
        _hover={{ bgColor: "whiteAlpha.300 " }}
        colorScheme="white"
        data-testid="file-manager-change-order-button"
        rightIcon={<Icon id="Ui/ChevronDownSm" />}
        size="sm"
        variant="ghost"
      >
        {gridSorts[value].label}
      </MenuButton>

      <MenuList>
        <Text
          w="100%"
          mt={3}
          mb={1}
          ml={2}
          color="textTertiary"
          textAlign={"left"}
          size="body.sm"
        >
          Sort By
        </Text>
        {options.map((key) => {
          const isSelected = value === key;
          const dynamicTestId = `file-manager-change-order-${key}`;
          return (
            <MenuItem key={key}>
              <Button
                w="150px"
                variant="menuItem"
                bg={isSelected ? "whiteAlpha.300" : "transparent"}
                color={isSelected ? "textPrimary" : "textSecondary"}
                onClick={() => onChange(key)}
                data-testid={dynamicTestId}
              >
                <Box flex="1" textAlign="left">
                  {gridSorts[key].label}
                </Box>
                {isSelected && <Icon id="Ui/Check" />}
              </Button>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
