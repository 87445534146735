import { Team } from "domains/teams/interfaces/Team";
import { GetModelsByModelIdApiResponse } from "infra/api/generated/api";

export function getModelKeyword(
  model: GetModelsByModelIdApiResponse["model"]
): string | undefined {
  // The model has been trained with a class, we use the class prompt
  if (model.class) {
    return model.class.conceptPrompt;
  }

  // The model has been trained custom, and a concept prompt has been provided
  if (model.parameters?.conceptPrompt) {
    return model.parameters.conceptPrompt;
  }

  return undefined;
}

export const DEFAULT_MODEL_THUMBNAIL =
  "/ui/generator_thumbnail_placeholder.png";

export function getModelThumbnail(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): string {
  if (model?.thumbnail) {
    return model.thumbnail.url;
  }

  if (model?.trainingImages?.length) {
    return model.trainingImages[0].downloadUrl;
  }

  return DEFAULT_MODEL_THUMBNAIL;
}

export function isOwner(
  model: GetModelsByModelIdApiResponse["model"] | undefined,
  team: Team
): boolean {
  if (!model) {
    return false;
  }
  return model.ownerId === team.id.replace("%7C", "|");
}

export function isModelSdxl(
  model: GetModelsByModelIdApiResponse["model"] | undefined
): boolean {
  if (!model || !model.type) {
    return false;
  }
  return model.type.startsWith("sd-xl");
}
