import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { useHover } from "domains/ui/hooks/useHover";

import { Box, Menu, MenuButton, MenuList, Tooltip } from "@chakra-ui/react";

interface SelectionBarTagsProps {
  length: number;
  children: React.ReactNode;
}

const SelectionBarTags = ({ length, children }: SelectionBarTagsProps) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();

  return (
    <Menu placement={"top"}>
      <Tooltip
        isOpen={isHover && length > 50}
        label="You can only tag up to 50 items at a time."
        placement="top"
      >
        <Box ref={hoverRef}>
          <MenuButton
            as={Button}
            isDisabled={length > 50}
            leftIcon={<Icon id={"Ui/Plus"} color={"textPrimary"} w={"10px"} />}
            size="sm"
            variant="primary"
          >
            Tags
          </MenuButton>
        </Box>
      </Tooltip>
      <MenuList zIndex={"banner"} w="320px" p={4}>
        {children}
      </MenuList>
    </Menu>
  );
};

export default SelectionBarTags;
