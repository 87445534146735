import { isBrowser } from "framer-motion";

import migrationLogic, { CURRENT_VERSION } from "./migrationLogic";

let localCache: { [key: string]: any } = {};

export type LocalStorageService = typeof localStorageService;

export const localStorageService = {
  get: <T>(key: string): T | undefined => {
    if (!isBrowser) return undefined;

    if (key in localCache) {
      return localCache[key];
    }

    const item = localStorage.getItem(key);
    try {
      const parsedValue = item ? JSON.parse(item) : undefined;
      localCache[key] = parsedValue;
      return parsedValue;
    } catch (e) {
      console.error(`Error parsing local storage value for key '${key}':`, e);
      return undefined;
    }
  },

  set: <T>(key: string, value: T): void => {
    if (!isBrowser) return undefined;
    try {
      if (value === undefined) {
        delete localCache[key];
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
        localCache[key] = value;
      }
    } catch (e) {
      console.error(`Error setting local storage value for key '${key}':`, e);
    }
  },

  remove: (key: string): void => {
    delete localCache[key];
    localStorage.removeItem(key);
  },

  clear: (): void => {
    localCache = {};
    localStorage.clear();
  },

  migrate: (targetVersion?: number): void => {
    if (!isBrowser) return;

    const currentUserVersion = localStorageService.get<number>("version") || 0;
    const target = targetVersion
      ? Math.min(targetVersion, CURRENT_VERSION)
      : CURRENT_VERSION;

    if (currentUserVersion >= target) return;

    const migrationsToApply = migrationLogic.filter(
      (migration) =>
        migration.version > currentUserVersion && migration.version <= target
    );

    for (const migration of migrationsToApply) {
      migration.migrate(localStorageService);
    }

    localStorageService.set("version", target);
  },
};

localStorageService.migrate();
