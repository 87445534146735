import { Avatar } from "./avatar";
import { Button } from "./button";
import { Card } from "./card";
import { Checkbox } from "./checkbox";
import { Editable } from "./editable";
import { Heading } from "./heading";
import { Input } from "./input";
import { Link } from "./link";
import { Menu } from "./menu";
import { Modal } from "./modal";
import { NumberInput } from "./number-input";
import { PinInput } from "./pin-input";
import { Select } from "./select";
import { Slider } from "./slider-track";
import { Switch } from "./switch";
import { Table } from "./table";
import { Tabs } from "./tabs";
import { Tag } from "./tag";
import { Text } from "./text";
import { Textarea } from "./textarea";
import { Tooltip } from "./tooltip";

export const components = {
  Avatar,
  Button,
  Text,
  Card,
  Checkbox,
  Heading,
  Slider,
  Input,
  Link,
  Tabs,
  Tag,
  Textarea,
  Table,
  Menu,
  Modal,
  Switch,
  Editable,
  Tooltip,
  Select,
  PinInput,
  NumberInput,
};
